import { Button, Icon, InputGroup, ScrollView, SmallCloseIcon, View } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Animated, ImageBackground, Platform, SafeAreaView, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { WalletSVG, BackSVG, CloseBtn } from '../../../../constants/imgs';
import { SetAlertMessage, SetScreenLoading } from '../../../../reducers/settings';
import ActionBtn from '../../../shared/ActionBtn';
import WalletHistoryItem from '../../../shared/WalletHistoryItem';
import Image from '../../../shared/customImg'
import Text from '../../../shared/TextLabel';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { firestore } from "../../../../constants/firebase/config";
import { strings } from '../../../../translations/index';
import Input from '../../../shared/CustomInput';
import { applyVoucher } from '../../../../helpers/helpers';
const Wallet = () => {
    const user = useSelector(state => state.user);
    const { locale } = useSelector(state => state.locale);
    const navigation = useNavigation();

    const { brand, brandLoyalty } = useSelector(state => state.settings)
    const [state, setState] = useState([])
    const pan = useRef(new Animated.ValueXY()).current;
    const [code, setCode] = useState('')
    const dispatch = useDispatch()
    const [redeemCode, toggleRedeem] = useState(false)
    useEffect(() => {
        firestore().collection('partners').doc(brand?.id).collection('wallets').doc(user?.uid).collection('walletLog').get().then(res => {
            let data = res.docs.map(r => {
                return { ...r.data(), id: r.id }
            })
            setState(data)
        })
    }, [])
    return (
        <View flex={1}>
            <View style={{
                // height: pan.y.interpolate({
                //     inputRange: [0, 100],
                //     outputRange: ['48%', '18%'],
                //     extrapolate: 'clamp',
                // }),
            }}>
                <ImageBackground imageStyle={{
                    width: '100%',
                    borderBottomLeftRadius: 20,
                    borderBottomRightRadius: 20
                }}
                    style={{ width: '100%' }}
                    source={require('../../../../assets/homeHeaderBG.png')}>
                    <SafeAreaView style={{ paddingTop: Platform.OS === 'android' ? 52 : Platform.OS === 'web' ? 20 : 0 }} >
                        <View flexDirection={'row'} paddingX={4} justifyContent='center' alignItems={'center'}>
                            <TouchableOpacity
                                onPress={() => navigation.goBack()}
                                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                                style={{ position: 'absolute', left: 0, marginHorizontal: 16 }}>
                                <Image
                                    rotate={locale === 'ar'}
                                    svg
                                    uri={BackSVG || ''}
                                    width={20}
                                    height={16}
                                />
                            </TouchableOpacity>

                            <Text fontFamily='bold' style={{ textAlign: 'center', color: '#fff', fontSize: 20 }}>{strings?.['Wallet']}</Text>
                        </View>
                        <View paddingX={4} paddingBottom={4} flexDirection='row' alignItems={'center'} justifyContent={'space-between'}>

                            <View padding={2} >
                                <Text fontFamily="bold" style={{ color: '#fff', fontSize: 24 }}>
                                    {parseInt(user?.wallet?.points || 0)} {strings.AED}
                                </Text>

                                <Text style={{ color: '#fff', fontSize: 12 }}>
                                    {strings?.['Total Credit']}
                                </Text>


                            </View>
                            <View padding={2} >
                                <Text style={{ color: '#fff', fontSize: 12 }}>
                                    {strings?.['Expiry date:']}
                                </Text>

                                <Text fontFamily="bold" style={{ color: '#fff', fontSize: 12 }}>
                                    {moment(user?.wallet?.exp).format('yyyy/MM/DD')}
                                </Text>


                            </View>
                        </View>
                        <View style={{
                            // height: pan.y.interpolate({
                            //     inputRange: [0, 50, 60],
                            //     outputRange: [250, 250, 0],
                            //     extrapolate: 'clamp',
                            // }),
                            // opacity: pan.y.interpolate({
                            //     inputRange: [0, 100],
                            //     outputRange: [1, 0],
                            //     extrapolate: 'clamp',
                            // }),
                            // top: pan.y.interpolate({
                            //     inputRange: [0, 100],
                            //     outputRange: [-10, -280],
                            //     extrapolate: 'clamp',
                            // }),
                            marginHorizontal: 16,
                            backgroundColor: '#fff',
                            borderRadius: 12

                        }}   >
                            <View justifyContent={'center'} alignItems={'center'} padding={4}>
                                <Image
                                    svg
                                    uri={WalletSVG || ''}
                                    width={80}
                                    height={80}
                                />
                                <Text fontFamily='bold' style={{ textAlign: 'center', color: '#334155', fontSize: 16, marginTop: 16 }}>{strings.formatString(strings?.referFriend, ((Number(brandLoyalty?.signupReward) + Number(brandLoyalty?.orderReward)) || '-'))}  </Text>
                                <Text style={{ textAlign: 'center', color: '#334155', fontSize: 12, marginTop: 4 }}> {strings.formatString(strings?.yourFriendWillGet, (brandLoyalty?.gift || 0))}</Text>
                                {
                                    redeemCode ?
                                        <View borderRadius={10} borderWidth={1} borderColor='#20A2DF' width={'100%'} margin={3}>
                                            <Input
                                                onBlur={async () => {
                                                    dispatch(SetScreenLoading(true))
                                                    let { status, msg } = await dispatch(applyVoucher({ code: code, user: user }))
                                                    if (status === 'error') {
                                                        dispatch(SetAlertMessage({
                                                            title: 'Alert',
                                                            msg: msg,
                                                            cancelText: 'dismiss',
                                                            alert: true,
                                                        }))
                                                    }
                                                    toggleRedeem(false)
                                                    dispatch(SetScreenLoading(false))
                                                }}
                                                placeholder='Enter code here'
                                                onChange={(e) => setCode(e.nativeEvent.text)}
                                                autoFocus={true}
                                                InputRightElement={
                                                    <TouchableOpacity
                                                        style={{ paddingHorizontal: 16 }}
                                                        onPress={() => {
                                                            toggleRedeem(false)
                                                        }}
                                                    >
                                                        <Image
                                                            svg
                                                            fill='red'
                                                            uri={CloseBtn}
                                                            width={15}
                                                            height={15}
                                                        />
                                                    </TouchableOpacity>

                                                }
                                            />

                                        </View>

                                        :
                                        <View flexDirection={'row'} style={{ marginTop: 26 }}>


                                            <View width={'50%'} paddingX={1}>
                                                <ActionBtn fontSize={12} title={strings?.['Refer Now']} relative onPress={() => {
                                                    navigation.navigate('Referral', { fromWallet: true })
                                                }} />
                                            </View>
                                            <View width={'50%'} paddingX={1}>
                                                <ActionBtn fontSize={12} backgroundColor='#ffffff' color='#32A2DC' title={strings?.['Redeem Code']} relative onPress={() => {
                                                    toggleRedeem(true)
                                                }} />
                                            </View>

                                        </View>
                                }


                            </View>

                        </View>
                    </SafeAreaView>

                </ImageBackground>
            </View>

            {
                state.length ?
                    <View margin={4} flex={1}>
                        <Text fontFamily='bold' style={{ color: '#334155', fontSize: 16, marginBottom: 12 }}>{strings?.['History']}</Text>
                        <ScrollView
                            scrollEventThrottle={1}

                            contentInsetAdjustmentBehavior="never"
                            showsVerticalScrollIndicator={false}>
                            {state?.sort((a, b) => b.created - a.created)?.map(data => {
                                return <WalletHistoryItem data={data} />
                            })
                            }
                        </ScrollView>

                    </View>
                    :
                    <View flex={1} justifyContent='center' alignItems={'center'}>
                        <Text fontFamily='semiBold' fontSize={18}>{strings?.['No Records Found']}</Text>
                    </View>
            }


        </View>
    );
};

export default Wallet;