import { useNavigation } from '@react-navigation/native';
import { FlatList, ScrollView, View } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, Animated, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import ServicesCard from '../../../shared/ServicesCard';
import OrderSummarySheet from '../../../shared/OrderSummarySheet';
import ServicesHeader from './ServicesHeader';
import Text from '../../../shared/TextLabel';
import { Localize } from '../../../../helpers/helpers';
import ActionBtn from '../../../shared/ActionBtn';
import { UpdateOrderItems } from '../../../../reducers/order';
import { SetAlertMessage } from '../../../../reducers/settings';
import { strings } from '../../../../translations/index';
import { analytics } from '../../../../constants/firebase/config';
import { appsFlyerLogEvent } from '../../../../helpers/index';

const IMAGE_SCALE_MAX = 3;
const LABEL_HEADER_MARGIN = 10;

const VariationPage = ({ route }) => {
    const user = useSelector(state => state.user);
    const { services, webView, variations } = useSelector(state => state.settings);
    const { medicines } = useSelector(state => state.helpers);

    const CurrentUserRef = useRef();
    CurrentUserRef.current = user

    const { items } = useSelector(state => state.order);
    const { id: variationID, type } = route?.params || {};
    const navigation = useNavigation()
    const [data, setData] = useState({})
    const pan = useRef(new Animated.ValueXY()).current;
    const dispatch = useDispatch()

    useEffect(() => {
        switch (type) {
            case 'medicine':
                if (medicines?.prds) {
                    let activeMedicine = medicines?.prds?.find(r => String(r.sku) === String(variationID));
                    if (activeMedicine) {
                        let similar = medicines?.prds?.filter(r => r.sku !== activeMedicine?.sku && r.category_id === activeMedicine?.category_id);
                        setData({ ...activeMedicine, service_id: 'pharmacy', actionBtnTitle: 'Order', similar, pricing: { total: activeMedicine?.price } })
                    } else {
                        // navigation.goBack()
                    }
                }
                break;
            default:
                if (variations) {
                    let activeVariation = variations?.find(r => r.id === variationID);
                    if (activeVariation) {
                        let service = services?.find(r => r.id === activeVariation?.service_id);
                        let activeVariations = variations?.filter(r => r.service_id === activeVariation?.service_id)
                        if (service?.menuType === 'searchable') {
                            activeVariations = activeVariations?.filter(r => r?.variation_categories?.some(rec => activeVariation?.variation_categories?.includes(rec)))
                        }
                        setData({ ...activeVariation, actionBtnTitle: Localize(service?.actionBtnTitle), similar: activeVariations })
                    } else {
                        navigation.goBack()
                    }

                }

        }

    }, [variationID, medicines?.prds, variations])
    useEffect(() => {
        if (Platform.OS === 'web') {
            window.scrollTo(0, 0)
        }
    }, [])
    useEffect(() => {
        (async () => {
            await analytics().logEvent('view_item', {
                id: data?.id,
                item: data?.name?.en,
                description: data?.details?.en,
                type: 'variation',
                platform: Platform.OS
            })
        })()
    }, [data]);

    return (
        <View flex={1} height={webView ? '100vh' : 'auto'} minHeight={Platform.OS === 'web' ? '100vh' : '100%'} justifyContent='space-between' >
            <ScrollView
                alwaysBounceVertical={false}
                onScroll={Animated.event(
                    [{ nativeEvent: { contentOffset: { y: pan.y } } }],
                    {
                        useNativeDriver: false,
                    },
                )}
                scrollEventThrottle={1}
                contentInsetAdjustmentBehavior="never"
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                    border: 'none',
                    paddingBottom: 200,
                    minHeight: Platform.OS === 'web' ? '100vh' : 'auto'
                }}
            >
                <ServicesHeader
                    data={data}
                    variation={true}
                    basic
                    animStyle={{
                        transform: [
                            {
                                translateY: pan.y.interpolate({
                                    inputRange: [-1000, 0],
                                    outputRange: [-500, 0],
                                    extrapolate: 'clamp',
                                }),
                            },
                            {
                                scale: pan.y.interpolate({
                                    inputRange: [-400, 0],
                                    outputRange: [IMAGE_SCALE_MAX, 1],
                                    extrapolate: 'clamp',
                                }),
                            },
                        ],
                    }}
                    revertStyle={{
                        opacity: pan.y.interpolate({
                            inputRange: [-20, 0],
                            outputRange: [0, 1],
                            extrapolate: 'clamp',
                        }),
                    }}
                />
                <Animated.View style={{
                    // padding: 16,
                    borderTopRightRadius: 16,
                    borderTopLeftRadius: 16,
                    flex: 1,
                    top: -12,
                    backgroundColor: '#F5F7F8',
                    transform: [
                        {
                            translateY: pan.y.interpolate({
                                inputRange: [-1000, 0],
                                outputRange: [LABEL_HEADER_MARGIN * IMAGE_SCALE_MAX, 0],
                                extrapolate: 'clamp',
                            }),
                        },
                    ],
                }}>

                    <View backgroundColor={'#fff'} borderRadius={8} padding={4} margin={4}>
                        <View flexDirection={'row'} justifyContent='space-between' alignItems={'flex-start'}>
                            <View maxWidth={'80%'}>
                                <Text fontFamily='bold' style={{ fontSize: 16 }}>
                                    {Localize(data?.name)}
                                </Text>
                                <Text fontFamily='semiBold' style={{ fontSize: 14, color: '#20A2DF' }}>
                                    {((data?.pricing?.total * 1) || 0)?.toFixed(2)} {strings?.['AED']}  <Text fontFamily='medium' style={{ fontSize: 12, color: '#BFBFBF', textDecorationLine: 'line-through' }}>{data?.pricing?.actualPrice && data?.pricing?.actualPrice !== data?.pricing?.total ? data?.pricing?.actualPrice + strings?.['AED'] : ''}</Text>
                                </Text>
                            </View>
                            {/* {
                                data?.pricing?.actualPrice && data?.pricing?.actualPrice !== data?.pricing?.total ?
                                    <Badge fontSize={12} fontFamily='semiBold' title={`${((1 - (data?.pricing?.total / data?.pricing?.actualPrice)) * 100)?.toFixed(1)}%`} color='#32A2DC' />
                                    :
                                    <View />
                            } */}

                        </View>
                        {
                            data?.details && Localize(data?.details) !== ' ' ?
                                <View paddingTop={5}>
                                    <Text fontFamily='bold' style={{ fontSize: 14 }}>
                                        {strings?.['Description']}
                                    </Text>
                                    <Text
                                        richText={true}
                                        style={{
                                            fontSize: 12,
                                            paddingTop: 4,
                                            flex: Platform.OS !== 'web' ? 1 : 'auto'
                                        }}
                                    >
                                        {Localize(data?.details)}
                                    </Text>
                                </View>
                                :
                                <View />
                        }

                    </View>

                    {
                        data?.similar?.length > 1 ?

                            <View >
                                <View paddingY={4} marginX={4}>
                                    <View flexDirection={'row'} justifyContent='space-between'>
                                        <Text fontFamily='bold'>
                                            {strings?.[data?.service_id === 'pharmacy' ? 'Similar Products' : 'Similar services']}
                                        </Text>
                                        {
                                            data?.similar?.length > 5 ?
                                                <TouchableOpacity onPress={() => {
                                                    let service_id = data?.service_id
                                                    let selectedService = services.find(r => r.id === service_id)
                                                    switch (selectedService?.menuType) {
                                                        case 'searchable':
                                                            navigation.navigate('Pharmacy', { serviceID: service_id, title: Localize(selectedService?.name) })

                                                            break;
                                                        default:
                                                            navigation.navigate('Services', { serviceID: service_id })
                                                            break;
                                                    }
                                                }}>
                                                    <Text style={{ color: '#20A2DF', fontSize: 12 }}>
                                                        {strings?.['View all']}
                                                    </Text>
                                                </TouchableOpacity>
                                                :
                                                <View />
                                        }
                                    </View>
                                </View>
                                <FlatList
                                    showsHorizontalScrollIndicator={false}
                                    contentContainerStyle={{
                                        paddingBottom: 30,
                                        paddingHorizontal: 16
                                    }}
                                    horizontal
                                    ItemSeparatorComponent={() => {
                                        return <View padding={2} />
                                    }}
                                    data={data?.similar?.filter(r => r.id !== data?.id).slice(0, 5) || []}
                                    renderItem={({ item }) => <ServicesCard data={{ ...item, service_id: data?.service_id, actionBtnTitle: data?.actionBtnTitle, similar: data?.similar }} />}
                                    keyExtractor={item => item.id}
                                />
                            </View>
                            :
                            <View />
                    }

                </Animated.View>
            </ScrollView>
            {
                items?.[data?.id] ?
                    <OrderSummarySheet
                        selectCount={data}
                        disabled={Object.values(items)?.length < 1}
                        onPress={() => {
                            navigation.navigate('Booking')
                            return
                        }} />
                    :
                    <View style={{ position: Platform.OS === 'web' ? 'sticky' : 'absolute', bottom: 10, width: '100%' }}>
                        <ActionBtn title={strings?.['Select']} onPress={async () => {
                            let orderList = JSON.parse(JSON.stringify(items))
                            let serviceID = Object.values(orderList)?.[0]?.service_id

                            if (serviceID && serviceID !== data.service_id) {
                                dispatch(SetAlertMessage({
                                    title: "Caution",
                                    confirmText: 'Confirm',
                                    msg: "By adding this item to cart ,Other Selected services will be removed from cart",
                                    onCancel: () => { dispatch(SetAlertMessage(false)) },
                                    onConfirm: async () => {
                                        let updatedList = Object.keys(orderList).reduce((val, currentKey) => {
                                            let currentVal = orderList?.[currentKey]
                                            if (currentVal.service_id === data.service_id) {
                                                val[currentKey] = currentVal
                                            }
                                            return val
                                        }, {});
                                        dispatch(UpdateOrderItems({ ...updatedList, [data?.id]: { count: data?.min_count || 1, price: data?.pricing, name: data?.name, service_id: data.service_id } }));
                                        dispatch(SetAlertMessage(false));
                                        appsFlyerLogEvent('add_to_cart', {
                                            af_id: data?.id,
                                            af_item: data?.name?.en,
                                            af_description: data?.details?.en,
                                            af_platform: Platform.OS
                                        })
                                        await analytics().logEvent('add_to_cart', {
                                            id: data?.id,
                                            item: data?.name?.en,
                                            description: data?.details?.en,
                                            platform: Platform.OS
                                        })

                                    }
                                }))
                            } else {
                                dispatch(UpdateOrderItems({ ...orderList, [data?.id]: { count: data?.min_count || 1, price: data?.pricing, name: data?.name, service_id: data.service_id } }))
                                appsFlyerLogEvent('add_to_cart', {
                                    af_id: data?.id,
                                    af_item: data?.name?.en,
                                    af_description: data?.details?.en,
                                    af_platform: Platform.OS
                                })
                                await analytics().logEvent('add_to_cart', {
                                    id: data?.id,
                                    item: data?.name?.en,
                                    description: data?.details?.en,
                                    platform: Platform.OS
                                })

                            }

                        }} />
                    </View>
            }
        </View>

    );
};

export default VariationPage;