import React from 'react';
import Text from './TextLabel';
import { Localize, shadow } from '../../helpers/helpers';
import { View, Image, Pressable } from 'native-base';
import { useSelector } from 'react-redux';
import { TouchableOpacity } from 'react-native';
import { strings } from '../../translations/index';

const HomeCard = ({ data, book }) => {
    let picture = data?.picture?.[0];
    const { locale } = useSelector(state => state.locale)

    let actionBtnTitle = data?.actionBtnTitle ? Localize(data?.actionBtnTitle) : strings?.['Book'];
    return (
        <TouchableOpacity
            onPress={book}
            // shadow={shadow({ color: '#00000012', radius: 10, offsetWidth: 0, offsetHeight: 0 })}
            style={{
                width: '100%',
                flexDirection: 'row',
                backgroundColor: '#fff',
                borderRadius: 8,
                justifyContent: 'space-between',
                minHeight: 148
            }} >
            <View w={'60%'} padding={4} justifyContent='space-between'>
                <View style={{ paddingBottom: 18 }}>
                    <Text
                        fontFamily='semiBold'
                        fontSize={14}
                        style={{ fontWeight: 'semiBold' }}>
                        {Localize(data?.name)}
                    </Text>
                    <Text numberOfLines={2} paddingTop={1} ellipsizeMode='tail'
                        fontSize={12}
                    >
                        {Localize(data?.details)}
                    </Text>
                </View>
                <View
                    disabled={data?.comingSoon}
                    style={{
                        width: '70%',
                        height: 33,
                        backgroundColor: data?.comingSoon ? '#cacaca20' : '#32A1E720',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 6
                    }}>
                    <Text style={{ color: data?.comingSoon ? '#cacaca' : '#32A1E7', fontSize: 12, width: '100%', textAlign: 'center' }}>
                        {actionBtnTitle}
                    </Text>
                </View>
            </View>
            <Image alt={Localize(data?.name)} resizeMode='cover' source={{ uri: picture }} style={{ borderRadius: 8, width: '40%', transform: [{ scaleX: locale === 'ar' ? -1 : 1 }] }} />
        </TouchableOpacity>
    );
};

export default HomeCard;