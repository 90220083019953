import { Button, Image, Modal, View } from 'native-base';
// import { Modal } from 'react-native';

import React, { useEffect, useState } from 'react';
import QRcode from '../../../shared/QRcode/index';
import Text from '../../../shared/TextLabel';
import { useSelector } from 'react-redux';
import { StatusMap } from '../../../../helpers/helpers';
import CompletedPNG from '../../../../assets/brosucsess.png'
import { firestore } from '../../../../constants/firebase/config';
import { strings } from '../../../../translations/index';
// import Modal from '../../../shared/Modal/Modal';
import { Dimensions, Platform } from 'react-native';

const QRcodeModal = ({ dismiss, qrCode }) => {
    // const [update, setUpdate] = useState(window.innerHeight)
    const [order, setOrder] = useState(false)
    useEffect(() => {
        // window.document.body.style.position = 'fixed'
        window.onclick = function (e) {
            console.log(e.target.id);
            if (e.target.id === 'background') {
                dismiss()
            }
        }
        firestore().collection('orders').doc(qrCode).onSnapshot(snap => {
            let data = snap.data()
            setOrder({ ...data, id: snap.id })
        })
        // const resizer = function (e) {
        //     setUpdate(window.innerHeight)
        // }
        // window.addEventListener('resize', resizer)
        return () => {
            // window.removeEventListener('resize', resizer)
            window.document.body.style.position = 'unset'
        }
    }, [])
    return (
        <View >
            <Modal isOpen={true} animationPreset='slide' position={Platform.OS === 'web' ? 'bottom' : 'static'} w={Dimensions.get('window').width > 900 ? '30%' : '100%'} marginLeft={Dimensions.get('window').width > 900 ? '35%' : 0} style={{ bottom: 0 }} size={'full'} >
                {/* <Modal.Content borderTopRadius={20} borderBottomRadius={0} marginBottom={0} marginTop='auto' position={Platform.OS === 'web' ? 'bottom' : 'static'} marginX={0} w={'100%'} padding={4}> */}

                    {/* <Modal style={{width:'100%',marginLeft:'0'}}  > */}
                    <View nativeID='background'w={'100%'} flex={1} backgroundColor={'#00000040'} >
                    <View position={'absolute'} alignItems='center' bottom={0} left={0} right={0} backgroundColor={'#ffffff'} borderTopRadius={32} padding={4}>

                        <View height={2} borderRadius={16} backgroundColor='#F1F5F9' width={50} marginBottom={8} />
                        {
                            order?.paid ?
                                <>
                                    <Image
                                        source={CompletedPNG}
                                        resizeMode='contain'
                                        style={{
                                            width: 250,
                                            height: 250
                                        }}
                                        alt='completed'
                                    />
                                    <View padding={4} alignItems='center'>
                                        <Text fontFamily='semiBold' fontSize={18} paddingTop={2}>
                                            {`${strings["Payment Status"]}:`} <Text fontSize={18} style={{ color: '#20A2DF' }}>{strings?.['Completed']}</Text>
                                        </Text>
                                    </View>
                                    <View paddingTop={70} paddingBottom={10} width='100%' alignItems={'center'}>
                                        <Button onPress={dismiss} backgroundColor={'#2F8AF4'} height={50} width={'90%'} borderRadius={12}>
                                            <Text fontSize={16} fontFamily='medium' style={{ color: '#fff' }} >{strings?.['OK']}</Text>
                                        </Button>
                                    </View>
                                </>

                                :
                                <>
                                    <QRcode value={`https://${window.location.host}/Payment/order/${qrCode}`} />
                                    <View padding={4} alignItems='center'>
                                        <Text fontFamily='medium' fontSize={16}>
                                            {strings?.["Ask customer to scan for Payment"]}
                                        </Text>
                                        <Text fontFamily='semiBold' fontSize={18} paddingTop={2}>
                                            {`${strings["Payment Status"]}:`} <Text fontSize={18} style={{ color: '#EF4444' }}>{strings?.['Pending']}</Text>
                                        </Text>
                                    </View>
                                    <View paddingTop={70} paddingBottom={10} width='100%' alignItems={'center'}>
                                        <Button onPress={dismiss} backgroundColor={'#2F8AF4'} height={50} width={'90%'} borderRadius={12}>
                                            <Text fontSize={16} fontFamily='medium' style={{ color: '#fff' }} >{strings?.dismiss}</Text>
                                        </Button>
                                    </View>
                                </>
                        }

                    </View>
                </View>
            </Modal>
        </View>
    );
};

export default QRcodeModal;