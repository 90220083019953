import { View } from 'native-base';
import React from 'react';
import { WalletExpenseSVG, WalletGiftSVG, WalletRefundSVG, WalletSVG } from '../../constants/imgs';
import Text from './TextLabel';
import CustomImg from './customImg'
import { StyleSheet, TouchableOpacity } from 'react-native';
import { strings } from '../../translations/index';
import moment from 'moment';

const WalletHistoryItem = ({ data }) => {

    const LoadImage = (type) => {
        let image = WalletSVG
        switch (type) {
            case 'earning':
                image = WalletGiftSVG
                break;
            case 'expense':
                image = WalletExpenseSVG
                break;
            case 'refund':
                image = WalletRefundSVG
                break;
            default:
                break;
        }
        return image
    }

    return (
        <TouchableOpacity onPress={async () => {
        }}
            key={data?.key || data?.id} style={styles.container}>
            <View style={styles.serviceItemImage}>
                <CustomImg
                    svg
                    uri={LoadImage(data?.type)}
                    width={48}
                    height={48}
                />

            </View>
            <View flex={1}>
                <View >
                    <Text fontFamily="semiBold" style={{
                        fontSize: 12,
                        lineHeight: 21,
                        color: '#0F172A',
                        textAlign: 'left'
                    }}>
                        {strings?.[data.description] || `${data?.type === 'refund' ? strings?.['Refund'] : ''} ${data.description}`}

                        {/* {(data?.type === 'earning' ?
                        data.referral ?
                            strings?.['Referral Points'] :
                            strings.cashbackBonus
                        : data?.type === 'refund' ?
                            'Refund'
                            : strings.expenses
                    )} */}
                    </Text>
                    <Text fontFamily="regular" style={{
                        fontSize: 12,
                        lineHeight: 18,
                        color: '#334155',
                        textAlign: 'left'
                    }}>
                        {data?.type === 'earning' ?

                            strings.formatString(strings?.ValidTill, (moment(data.exp).format('DD/MM/YYYY'))) :
                            moment(data.created).format('DD/MM/YYYY')
                        }
                    </Text>
                </View>
            </View>
            <Text fontFamily="bold" style={{
                fontSize: 12,
                lineHeight: 28,
                color: ["earning", 'refund'].includes(data?.type) ? '#20A2DF' : '#F21F1F',
            }}>{strings.AED + (["earning", 'refund'].includes(data?.type) ? ' ' : ' -') + Number(data.amount).toFixed(2)}</Text>
        </TouchableOpacity>
    );
};


const styles = StyleSheet.create({

    container: {
        backgroundColor: '#FFF',
        marginVertical: 10,
        paddingHorizontal: 16,
        paddingVertical: 14,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 7
    },
    serviceItemImage: {
        width: 50,
        height: 50,
        resizeMode: 'cover',
        borderRadius: 100,
        marginEnd: 10

    },
});

export default WalletHistoryItem;