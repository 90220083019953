import React from 'react';
import { Platform, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Text from '../shared/TextLabel'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Button } from 'native-base';

const ActionBtn = ({ title, onPress, style, disabled, relative, loading, height = 50, backgroundColor = '#20A2DF', color = '#fff', fontSize = 14, borderColor }) => {
    const insets = useSafeAreaInsets();
    const Btn = ({ disabled }) => {
        return (
            <Button
                isLoading={loading}
                disabled={disabled}
                isLoadingText=' '
                onPress={() => { disabled ? {} : onPress() }}
                style={{
                    ...style,
                    height: height,
                    paddingHorizontal: 16,
                    // paddingVertical: 16,

                    backgroundColor: disabled ? '#cacaca' : backgroundColor,
                    bottom: Platform.OS === 'web' || insets.bottom === 0 ? (relative ? 0 : 16) : undefined,
                    width: '100%',
                    borderColor: borderColor || (disabled ? '#cacaca' : backgroundColor) || color,
                    borderWidth: 1,
                    borderRadius: 6,
                    borderRadius: 10,
                }}>
                <Text fontFamily='medium' style={{ color: color, textAlign: 'center', fontSize: fontSize, width: '100%' }}>{title}</Text>
            </Button>
        )

    }
    if (relative) {
        return (
            <View style={
                Platform.OS === 'web' ?
                    {
                        bottom: 10,
                        position: 'relative',
                        // height: height,
                        // paddingHorizontal: 16,
                        width: '100%',
                        opacity: disabled ? 0.5 : 1
                    } :
                    {
                        bottom: 10,
                        // paddingHorizontZal: 16,
                        width: '100%',
                        opacity: disabled ? 0.5 : 1
                    }}>
                <Btn />

            </View>
        )
    }

    return (
        <SafeAreaView style={
            Platform.OS === 'android' || Platform.OS === 'web' ?
                {
                    bottom: 20,
                    position: Platform.OS === 'web' ? 'sticky' : 'absolute',
                    // height: height,
                    paddingHorizontal: 16,
                    width: '100%',
                    // width: Dimensions.get('window').width > 900 && desktop ? (price ? "100%" : '30%') : '100%',
                    opacity: 1,

                } :
                {
                    bottom: 0,
                    position: Platform.OS === 'web' ? 'sticky' : 'relative',
                    paddingHorizontal: 16,
                    width: '100%',
                    opacity: 1,
                }}>
            <Btn disabled={disabled} />
        </SafeAreaView>

    );
};

export default ActionBtn;