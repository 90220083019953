import LocalizedStrings from 'react-localization'
import english from './en.js';
import arabic from './ae.js';
import russian from './ru.js'
import chinees from './zh-CN.js'


export const strings = new LocalizedStrings({
  en: english,
  ar: arabic,
  rus: russian,
  "zh-CN": chinees
});
