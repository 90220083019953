import { ScrollView, View } from 'native-base';
import React from 'react';
import Modal from '../Modal/Modal';
import Text from '../TextLabel';
import CustomImage from '../customImg';
import { EmptyDot, FilledDot, LocateSVG, LocationOutLined } from '../../../constants/imgs';
import { batch, useDispatch, useSelector } from 'react-redux';
import { CheckActiveCluster, FormatAddress, updateOrderForLocationChange } from '../../../helpers/helpers';
import { TouchableOpacity } from 'react-native';
import { SetRequiredLocation } from '../../../reducers/settings';
import { useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';
import { useState } from 'react';
import { strings } from '../../../translations/index';

const Index = () => {
    const { toggleLocation, servingAreas, webView, brand } = useSelector(state => state.settings)
    const { locations, defaultLocation } = useSelector(state => state.user);
    const { items } = useSelector(state => state.order)
    const navigation = useNavigation()
    const [disabled, setDisabled] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        if (toggleLocation && (!locations || (locations?.length === 0))) {
            dispatch(SetRequiredLocation(false))
            if (brand?.customLocations) {
                navigation.navigate('AddNewLocation', { action: toggleLocation?.action })
            } else {
                navigation.navigate('Address Details', { selectedRegon: defaultLocation, popOne: true })
            }
        }
        (async () => {

            if (toggleLocation?.dimmable && !brand?.customLocations) {
                // if (toggleLocation) {
                let disabled
                switch (webView) {
                    case true:
                        let activeCluster = await CheckActiveCluster(defaultLocation, locations);
                        disabled = locations.map(r => {
                            if (!activeCluster.includes(r.cluster)) {
                                return r.id
                            }
                        })
                        return setDisabled(disabled)
                    default:
                        disabled = locations.map(r => {
                            if (r.cluster !== defaultLocation?.cluster) {
                                return r.id
                            }
                        })
                        return setDisabled(disabled)
                }
            }
            setDisabled([])
        })()

    }, [locations, toggleLocation])

    if (!toggleLocation) {
        return <View />
    }
    return (
        <Modal fixed={true} dismiss={() => dispatch(SetRequiredLocation(false))} size={'full'}>
            <View position={'absolute'} bottom={0} width={'100%'} >

                <ScrollView
                    borderRadius={8}
                    // bottom={10}
                    // marginX={4}
                    contentContainerStyle={{
                        paddingBottom: 50
                    }}
                    background={'#fff'}
                    // paddingX={5}
                    paddingTop={6}
                >
                    <View paddingX={5} flexDirection={'row'} justifyContent='space-between' alignItems={'center'} paddingBottom={2}>
                        <Text fontFamily='semiBold' style={{ fontSize: 16 }}>
                            {strings?.["Select Location"]}
                        </Text>
                    </View>
                    {
                        locations?.map((r, i) => <TouchableOpacity
                            disabled={disabled?.includes(r.id)}
                            style={{
                                opacity: disabled?.includes(r.id) ? 0.5 : 1,
                                borderBottomWidth: 1,
                                borderBottomColor: '#E8E8EB',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: 16,
                            }}
                            onPress={() => {
                                batch(() => {
                                    // dispatch(SetOrderLocation(r))
                                    // dispatch(SetUserDefaultLocation({ ...r, created: moment().valueOf() }))

                                    dispatch(SetRequiredLocation(false))
                                    // if (items) {
                                    dispatch(updateOrderForLocationChange({ items, defaultLocation: r, cluster: servingAreas?.find(rec => rec.id === r?.cluster) }));
                                    // }
                                })
                                if (toggleLocation?.action) {
                                    toggleLocation.action(r?.cluster)
                                }
                                // setState({ state, seleced: r })
                            }}
                            key={r.id}>
                            <View w={'80%'} flexDirection='row' alignItems={'center'}>
                                <View>
                                    <CustomImage
                                        svg={true}
                                        width={24}
                                        fill={'#20A2DF'}
                                        height={24}
                                        uri={LocationOutLined}
                                    />
                                </View>

                                <View paddingX={2} flex={1}>
                                    <Text fontFamily='semiBold' style={{ fontSize: 12 }}>
                                        {r.label}
                                    </Text>
                                    <Text numberOfLines={2} style={{ fontSize: 10 }} >
                                        {FormatAddress(r)}
                                    </Text>
                                </View>

                            </View>
                            <CustomImage
                                svg={true}
                                width={15}
                                fill={'#20A2DF'}
                                height={15}
                                uri={defaultLocation?.id === r.id ? FilledDot : EmptyDot}
                            />
                        </TouchableOpacity>)
                    }
                    <TouchableOpacity
                        onPress={() => {
                            dispatch(SetRequiredLocation(false))
                            if (brand?.customLocations) {
                                navigation.navigate('AddNewLocation', { action: toggleLocation?.action })
                            } else {
                                navigation.navigate('Address Details', { selectedRegon: defaultLocation, popOne: true })
                            }
                        }}
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 16
                        }}>
                        <View flexDirection='row' alignItems={'center'}>
                            <CustomImage
                                svg={true}
                                width={24}
                                fill={'#20A2DF'}
                                height={24}
                                uri={LocateSVG}
                            />
                            <View paddingX={2}>
                                <Text fontFamily='semiBold' style={{ fontSize: 12 }}>
                                    {strings?.["Pick location on map"]}
                                </Text>
                                <Text numberOfLines={2} style={{ fontSize: 10 }} >
                                    {strings?.["Add a location from the map"]}
                                </Text>
                            </View>

                        </View>
                    </TouchableOpacity>
                </ScrollView>
            </View>


        </Modal>
    );
};

export default Index;