import { createAction } from 'redux-action';

export const SET_TOAST = 'SET_TOAST';

export const SetToast = createAction(SET_TOAST, (lang) => {
    return lang
});



const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case SET_TOAST:
            return { ...state, displayToast: action.payload };
        default:
            return state;
    }
};

