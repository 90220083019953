import { useNavigation } from '@react-navigation/native';
import { Pressable, useToast, View } from 'native-base';
import React, { useState } from 'react';
import { ActivityIndicator, ImageBackground, Platform, SafeAreaView, Share } from 'react-native';
import { useSelector } from 'react-redux';
import { AssignedSVG, BackSVG, ConfirmedSVG, CopySVG, ProgressSVG, ShareSVG } from '../../../../constants/imgs';
import { Localize } from '../../../../helpers/helpers';
import Image from '../../../shared/customImg'
import Text from '../../../shared/TextLabel';
import { strings } from '../../../../translations/index';

const PendingPayment = ({ data }) => {
    const navigation = useNavigation()
    return (
        <ImageBackground
            imageStyle={{ borderRadius: 12 }}
            style={{ backgroundColor: '#F5832F20', borderRadius: 12 }}
            source={{ uri: "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23F5832FFF' stroke-width='4' stroke-dasharray='18' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e" }}>
            <View padding={4}
                borderRadius={12}
                flexDirection='row'
                alignItems={'center'}
                justifyContent='space-between' >
                <View w={'60%'}>
                    <Text fontFamily='bold'>{strings?.["Pending Payment"]}</Text>
                    <Text style={{ paddingTop: 4 }} fontSize={12}>{strings?.["You need to complete the payment in order to process your request"]}</Text>
                </View>
                <Pressable onPress={async () => {
                    navigation.navigate('Payment', { order_id: data.id });
                    // if (data?.checkout_session?.url) {
                    //     window.location.href = data?.checkout_session?.url
                    //     // navigator.clipboard.writeText(data?.checkout_session?.shortLink || data?.checkout_session?.url);
                    //     // Toast.show({
                    //     //     title: 'Link copied to clipboard',
                    //     //     placement: 'top'
                    //     // })
                    // }
                }}>
                    {/* <Image
                        svg
                        width={35}
                        height={35}
                        fill='#fff'
                        style={{ width: 40, height: 40 }}
                        uri={CopySVG}
                    /> */}
                    <Text fontFamily='bold' style={{ color: '#F5832F' }}>{strings?.["Pay Now"]}</Text>
                </Pressable>

            </View>
        </ImageBackground>
    )
}
const StatusCard = ({ data }) => {

    const statusArray = [
        {
            status: ['pending', 'accepted', 'ontheway', 'active', 'completed', 'rated'],
            icon: ConfirmedSVG,
            title: 'Confirmed'
        },
        {
            status: ['accepted', 'accepted', 'ontheway', 'active', 'completed', 'rated'],
            icon: AssignedSVG,
            title: 'Assigned'
        },
        {
            status: ['ontheway', 'active', 'completed', 'rated'],
            icon: ProgressSVG,
            title: 'In Progress'
        },
        {
            status: ['completed', 'rated'],
            icon: ConfirmedSVG,
            title: 'Completed'
        }
    ]
    return (
        <View padding={3}>
            <Text fontFamily='bold' style={{ fontSize: 16 }}>{strings?.["Order Status"]}</Text>
            <View flexDirection={'row'} justifyContent='space-between' alignItems={'center'} paddingY={2}>
                {statusArray.map(r => {
                    let active = r.status.includes(data?.status)
                    return (<View key={r.title} width={'23%'} style={{ opacity: active ? 1 : 0.5 }}>
                        <View style={{ height: 6, backgroundColor: active ? '#2F8AF4' : '#cacaca' }} borderRadius={8} />
                        <View alignItems={'center'} paddingY={2} paddingTop={4}>
                            <Image
                                svg
                                fill='#000000'
                                uri={r.icon}
                                width={35}
                                height={35}
                            />
                        </View>
                        <Text style={{ fontSize: 10, textAlign: 'center' }}>
                            {strings?.[r.title]}
                        </Text>
                    </View>)
                })
                }
            </View>
        </View>
    )
}
const Header = ({ title = "", data }) => {
    const navigation = useNavigation();
    const Toast = useToast();
    const { locale } = useSelector(state => state.locale)
    const { webView } = useSelector(state => state.settings)

    const [loading, setLoading] = useState(false)
    return (
        <View style={{ backgroundColor: '#2F8AF4', paddingHorizontal: 20, paddingTop: 20, aspectRatio: 1.6 }}>
            <SafeAreaView>
                {
                    webView ? <View />
                        :
                        <View flexDirection={'row'} justifyContent='space-between'>
                            <Pressable paddingY={1}
                                background="#EBF2F9"
                                justifyContent={'center'}
                                alignItems='center'
                                w={8}
                                h={8}
                                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                                borderRadius={8}
                                onPress={() => {
                                    if (navigation.canGoBack()) {
                                        return navigation.goBack()
                                    }
                                    navigation.reset({
                                        index: 0,
                                        routes: [{ name: 'Home' }],
                                    });

                                }}
                            >
                                <Image
                                    rotate={locale === 'ar'}

                                    svg
                                    fill='#2F8AF4'
                                    uri={BackSVG}
                                    width={15}
                                    height={15}
                                />
                            </Pressable>

                            <View  >
                                <Text fontSize={20} style={{ color: '#fff', letterSpacing: 0.8 }} fontFamily='bold' >
                                    {strings?.[title]}
                                </Text>
                            </View>
                            <Pressable paddingY={1}
                                background="#EBF2F9"
                                justifyContent={'center'}
                                alignItems='center'
                                w={8}
                                h={8}
                                borderRadius={8}
                                onPress={async () => {
                                    try {
                                        setLoading(true)
                                        if (Platform.OS === 'web') {
                                            await navigator.share({ title: 'Metadoc Order status', text: `Customer Name: ${data?.contact?.name}\nAddress: ${Localize(data?.location?.address)}\nService: ${Localize(data?.service?.name)}`, url: `https://${window.location.host}/order_details/order/${data?.id}` })
                                        } else {
                                            await Share.share({
                                                url: `https://app.metadocapp.com/order_details/order/${data?.id}`,
                                                title: 'Metadoc Order status',
                                                // message: `Customer Name: ${data?.contact?.name}\nAddress: ${Localize(data?.location?.address)}\nService: ${Localize(data?.service?.name)}`
                                            })
                                        }
                                        setLoading(false)
                                    } catch (error) {
                                        setLoading(false)
                                        // if (data?.checkout_session?.url) {
                                        //     navigator.clipboard.writeText(data?.checkout_session?.shortLink || data?.checkout_session?.url);
                                        Toast.show({
                                            title: error.message,
                                            placement: 'top'
                                        })
                                        // }
                                        console.log("errorerrorerror", error);
                                    }
                                }}
                            >
                                {
                                    loading ? <ActivityIndicator />
                                        :
                                        <Image
                                            svg
                                            uri={ShareSVG}
                                            fill='#2F8AF4'
                                            width={20}
                                            height={20}
                                        />
                                }

                            </Pressable>

                        </View>
                }

                <View borderRadius={12} background='#fff' bottom={-40}>
                    {
                        !data?.paid && data?.status === 'new' ?
                            <PendingPayment data={data} />
                            :
                            <StatusCard data={data} />
                    }


                </View>
            </SafeAreaView>
        </View>
    );
};

export default Header;