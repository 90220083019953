import React from 'react';
import OtpInput from 'react-otp-input';

const index = ({ onChange, state }) => {
    return (
        <OtpInput
            containerStyle={{
                width: '100%',
                justifyContent: 'space-between',

            }}
            value={state.pin}
            onChange={onChange}
            numInputs={6}
            focusStyle={{
                border: 'none',
                outline: 'none'
            }}
            handleOnPaste={onChange}
            isInputNum={true}
            inputStyle={{
                borderRadius: 8,
                width: '100%',
                height: '100%',
                maxHeight: 40,
                margin: 4,
                background: '#E9F0F7',
                fontSize: 28,
                shadow: 'none',
                border: 'none'
            }}

            separator={<span> </span>}
        />

    );
};

export default index;