import { Image, View } from 'native-base';
import React from 'react';
import Badge from '../../../shared/Badge';
import Text from '../../../shared/TextLabel';
import moment from 'moment';
import { Localize, StatusMap } from '../../../../helpers/helpers';
import { useSelector } from 'react-redux';

import { strings } from '../../../../translations/index'

const BookingCard = ({ data }) => {
    const { activeProviders, brand } = useSelector(state => state.settings)

    let currebtStatus = StatusMap[data?.status];
    let assigndProvider = activeProviders.find(r => r?.id === data?.provider?.id)
    return (
        <View padding={4} backgroundColor='#fff' borderRadius={8} >
            <View flexDirection={'row'} justifyContent='space-between'  >
                <View>
                    <Text fontFamily='bold' style={{ fontWeight: 'bold', fontSize: 12 }} >
                        {Localize(data?.service?.name)}
                    </Text>
                    <Text style={{ fontSize: 10 }} numberOfLines={1} >
                        {
                            `${strings["Placed On"]}: ${moment(data?.created).format('ddd DD MMM, yyyy')} at ${moment(data?.created).format('hh:mm A')}`

                            // `${moment(data?.bookingDate?.date).format('ddd DD MMM, yyyy')} at ${moment(data?.bookingDate?.date).format('hh:mm A')}`
                        }
                    </Text>
                </View>
                <View maxW={'25%'}>
                    <Badge title={strings?.[currebtStatus?.status]} color={currebtStatus?.color} />
                </View>
            </View>

            <View marginY={4} borderBottomStyle={'dashed'} borderBottomWidth={1} borderColor='#E2E9F0' />

            <View flexDirection={'row'} justifyContent='space-between' alignItems='center'  >
                <View flexDirection={'row'} alignItems='center'>
                    <Image
                        alt='provider'
                        style={{ width: 48, height: 48, borderRadius: 8, borderWidth: 1, borderColor: '#E2E9F0' }}
                        source={{ uri: assigndProvider?.logo?.[0] || '' }} />

                    <View padding={2}>
                        <Text fontFamily='bold' style={{ fontWeight: 'bold', fontSize: 12 }} >
                            {Localize(assigndProvider?.name) || Localize(data?.provider?.name)}
                        </Text>
                        {/* <Text style={{ fontSize: 12, color: '#20A2DF' }} >
                            4.8
                        </Text> */}
                    </View>
                </View>
                {
                    brand?.customLocations ?
                        <View alignItems={'flex-end'}>
                            <Text style={{ fontSize: 12 }} >
                                {strings.Flat} #
                            </Text>
                            <Text fontFamily='bold' style={{ fontWeight: 'bold', fontSize: 12 }} >
                                {data?.location?.flat}
                            </Text>
                        </View>
                        :
                        <View alignItems={'flex-end'}>
                            <Text style={{ fontSize: 12 }} >
                                {strings.Room} #
                            </Text>
                            <Text fontFamily='bold' style={{ fontWeight: 'bold', fontSize: 12 }} >
                                {data?.contact?.room}
                            </Text>
                        </View>
                }

            </View>
        </View>
    );
};

export default BookingCard;