import React, { useMemo, useState, useEffect } from "react";
import {
  useStripe,
  PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import { Button } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { Localize } from "../../helpers/helpers";
const useOptions = paymentRequest => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "dark",
          height: "48px",
          type: 'book'
        }
      }
    }),
    [paymentRequest]
  );

  return options;
};

const usePaymentRequest = ({ options, onPaymentMethod }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest(options);
      setPaymentRequest(pr);
    }
  }, [stripe, options, paymentRequest]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then(res => {
        if (res && subscribed) {
          setCanMakePayment(true);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", onPaymentMethod);
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod", onPaymentMethod);
      }
    };
  }, [paymentRequest, onPaymentMethod]);

  return canMakePayment ? paymentRequest : null;
};

const PaymentRequestForm = ({ data, receipt, walletPayNow }) => {
  const stripe = useStripe();
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (!ready) {
        setReady(true)
      }

    }, 3000);
  }, [])

  const paymentRequest = usePaymentRequest({
    options: {
      country: "AE",
      currency: "aed",
      total: {
        label: data ? Localize(data?.service?.name) : Localize(receipt?.service),
        amount: data ? Number((data.receipt.total * 100).toFixed(2)) : Number((receipt?.total * 100).toFixed(2)),
      }
    },
    onPaymentMethod: async ({ complete, paymentMethod }) => {
      if (walletPayNow) {
        await walletPayNow(paymentMethod.id)
        return complete('success');
      }

      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        data?.paymentIntent,
        { payment_method: paymentMethod.id },
        { handleActions: false }
      );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        alert(confirmError?.message)
        complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(data?.paymentIntent);
          if (error) {
            alert(error?.message)

            // The payment failed -- ask your customer for a new payment method.
          } else {
            // navigation.goBack()
            // The payment has succeeded.
          }
        } else {
          // navigation.goBack()
          // The payment has succeeded.
        }
      }
    }
  });
  const options = useOptions(paymentRequest);

  if (!paymentRequest) {
    return <Button isLoading={!ready} isLoadingText=' ' disabled={true} minH={50} borderRadius={8} backgroundColor='gray.600'>Not Activated</Button>;
  }

  return (

    <PaymentRequestButtonElement
      className="PaymentRequestButton"
      options={options}
      onReady={() => {
        setReady(true)
        // console.log("PaymentRequestButton [ready]");
      }}
    // onClick={event => {
    //   console.log("PaymentRequestButton [click]", event);
    // }}
    // onBlur={() => {
    //   console.log("PaymentRequestButton [blur]");
    // }}
    // onFocus={() => {
    //   console.log("PaymentRequestButton [focus]");
    // }}
    />


  );
};

export default PaymentRequestForm;
