import { useNavigation } from '@react-navigation/native';
import { Pressable, ScrollView, Toast, View } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Trash } from '../../../../constants/imgs';
import { FormatAddress, UpdateUser } from '../../../../helpers/helpers';
import { SetAlertMessage } from '../../../../reducers/settings';
import ActionBtn from '../../../shared/ActionBtn';
import Image from '../../../shared/customImg'
import EmptyState from '../../../shared/EmptyState';
import Text from '../../../shared/TextLabel';
import { strings } from '../../../../translations/index';

const Locations = () => {
    const nav = useNavigation()
    const user = useSelector(state => state.user);
    const dispatch = useDispatch()
    return (
        <View>
            {
                !user?.locations?.length ? <View height={'100%'}>
                    <EmptyState title={strings?.['No Saved Locations']} msg={strings?.["Place an order to add your location"]} />
                </View>
                    :
                    <View height={'100%'}>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 16, paddingVertical: 16, paddingBottom: 100 }}>
                            {
                                user?.locations?.map(r => <Pressable
                                    onPress={() => {
                                        dispatch(SetAlertMessage({
                                            title: "Warning",
                                            msg: strings['Are you sure ,This locations will be deleted permanently'],
                                            confirmText: 'Delete',
                                            onConfirm: async () => {
                                                let locations = user.locations.filter(rec => rec.id !== r.id)
                                                const { error } = await dispatch(UpdateUser({ locations }))
                                                if (error) {
                                                    Toast.show({
                                                        placement: 'top',
                                                        title: 'Error',
                                                        status: 'error',
                                                        description: error.message
                                                    })

                                                }
                                                dispatch(SetAlertMessage(false))
                                            }
                                        }))
                                    }}
                                    flexDirection={'row'}
                                    borderRadius={8}
                                    marginBottom={4}
                                    backgroundColor='#fff'
                                    padding={4}
                                    alignItems='center'
                                    justifyContent={'space-between'}
                                    key={r.id}>
                                    <View w={'80%'}>
                                        <Text fontFamily='semiBold'>
                                            {r.label}
                                        </Text>
                                        <Text fontFamily='medium' paddingTop={1}>
                                            {FormatAddress(r)}
                                        </Text>
                                    </View>
                                    <Image
                                        svg
                                        fill='red'
                                        uri={Trash}
                                        width={20}
                                        height={20}
                                    />
                                </Pressable>)
                            }

                        </ScrollView>
                    </View>
            }
            {
                Platform.OS === 'web' && user?.uid ?
                    <View position={'fixed'} bottom={12} left={0} right={0}>
                        <ActionBtn
                            title={strings?.['Add new Location']}
                            onPress={() => {
                                nav.navigate('AddNewLocation', { addDetails: true })
                            }} />
                    </View>
                    :
                    <View />
            }

        </View>
    );
};

export default Locations;