import { Modal, Pressable, ScrollView, SimpleGrid, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Dimensions, Platform, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { strings } from '../../../../translations/index';
import ActionBtn from '../../../shared/ActionBtn';
import Text from '../../../shared/TextLabel';

const Slot = ({ selected, data = {} }) => {
    return (
        <View backgroundColor={selected ? '#20A2DF' : '#EDF2F5'} borderRadius={4}>
            <Text paddingX={2} paddingY={3} fontSize={13} fontFamily='medium' textAlign='center' style={{ color: selected ? '#fff' : '#3B3B3B' }}>
                {data?.id}
            </Text>
        </View>
    )
}
const TimmingSheet = ({ setTimeSlot, dismiss, data = {}, selected }) => {
    const { width } = useWindowDimensions();
    const [available, toggleAvailable] = useState(false)
    useEffect(() => {
        if (data) {
            let available = Object.values(data).reduce((val, currentVal) => {
                if (currentVal?.length > 1) {
                    val = true
                }
                return val
            }, false)
            toggleAvailable(available)
        }
    }, [data])
    return (
        <View >
            <Modal isOpen={true} onClose={dismiss} animationPreset='slide' position={Platform.OS === 'web' ? 'bottom' : 'static'} w={Dimensions.get('window').width > 900 ? '30%' : '100%'} marginLeft={Dimensions.get('window').width > 900 ? '35%' : 0} style={{ bottom: 0 }} size={'full'} >
                <Modal.Content borderTopRadius={20} borderBottomRadius={0} marginBottom={0} marginTop='auto' position={Platform.OS === 'web' ? 'bottom' : 'static'} marginX={0} w={'100%'} padding={4}>
                    {
                        available ?
                            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} contentContainerStyle={{ paddingBottom: 150 }}>
                                {
                                    Object.keys(data)?.map(rec => {
                                        let slots = data[rec];
                                        if (!slots?.length) {
                                            return <View key={rec} />
                                        }
                                        return (
                                            <View paddingTop={2} key={rec} >
                                                <Text style={{ color: '#9d9d9d', fontSize: 16 }} fontFamily='bold'>
                                                    {strings?.[rec]}
                                                </Text>
                                                <SimpleGrid columns={2} spacingX={4} spacingY={2} style={{ paddingTop: 8, paddingBottom: 12 }}>
                                                    {
                                                        slots?.map((r, i) =>
                                                            <Pressable
                                                                key={i}
                                                                onPress={() => setTimeSlot(r)}
                                                                style={{ width: ((width < 900 ? width : (Dimensions.get('window').width * 0.3)) / 2) - 8 - 16 }}>
                                                                <Slot data={r} selected={selected?.id === r.id} />
                                                            </Pressable>
                                                        )
                                                    }
                                                </SimpleGrid>
                                            </View>
                                        )
                                    })
                                }
                            </ScrollView>
                            :
                            <View height='100%' alignItems={'center'} paddingBottom={60} >
                                <View width={20} backgroundColor='#cacaca50' borderRadius={12} h={1} />
                                <View paddingTop={50} width={'70%'} paddingBottom={100}>
                                    <Text textAlign='center' fontSize={20} color='#0F172A' fontFamily='semiBold'>{strings?.["No Available Slots"]}</Text>
                                    <Text paddingTop={2} textAlign='center' fontFamily='Regular' style={{ color: '#C6C6C6' }}>{strings?.["Please check different date to see availability"]}</Text>
                                </View>
                                <ActionBtn title={strings?.['Check Next Date']} onPress={dismiss} />
                            </View>
                    }
                </Modal.Content>
            </Modal>
        </View>
    );
};

export default TimmingSheet;