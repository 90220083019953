import { Button, Flex, View } from 'native-base';
import React, { useState } from 'react';
import { ImageBackground, useWindowDimensions } from 'react-native';
import Text from '../../shared/TextLabel';
import backgroundImg from '../../../assets/bg.png'
import LinearGradient from 'react-native-web-linear-gradient';
import LoginSheet from './loginSheet';
import CustomerLogin from './CustomerLogin';
import { shadow } from '../../../helpers/helpers';

const Index = ({ navigation }) => {
    const [loginSheet, toggleLogin] = useState(false);
    return (
        <View flex={1}>
            {
                loginSheet === 'customer' ? <CustomerLogin dismiss={() => toggleLogin(false)} /> : loginSheet === 'staff' ? <LoginSheet dismiss={() => toggleLogin(false)} /> : <View />
                // loginSheet && <CustomerLogin />
            }
            <ImageBackground style={{
                height: '100%'
            }} source={backgroundImg}>
                <LinearGradient style={{ flex: 1, padding: 30 }} colors={['#00000090', '#000000']} >
                    <Flex flex={0.6} alignItems='flex-end'>
                        <Button
                            onPress={() => {
                                navigation.navigate('Home')
                            }}
                            variant={'ghost'}
                            padding={0}
                        >
                            <Text fontFamily='bold' fontSize={16} style={{ color: '#fff' }}>
                                Skip
                            </Text>

                        </Button>
                    </Flex>
                    <Flex flex={0.4}>
                        <Text fontSize={44} fontFamily='bold' style={{ color: '#fff' }}>
                            Metadoc
                        </Text>
                        <Text fontSize={34} fontFamily='medium' style={{ color: '#fff' }}>
                            The Future of healthcare
                        </Text>
                    </Flex>
                    <Button
                        borderRadius={8}
                        shadow={shadow({ color: '#00000040', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
                        style={{ marginTop: 80 }}
                        height={50} onPress={() => {
                            toggleLogin('customer')
                        }}>
                        Login
                    </Button>
                    <Button
                        variant={'ghost'}
                        style={{ marginBottom: 40 }}
                        height={50} onPress={() => {
                            toggleLogin('staff')
                        }}>
                        Staff login
                    </Button>
                </LinearGradient>


            </ImageBackground>

        </View>
    );
};

export default Index;