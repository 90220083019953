import { createAction } from 'redux-action';
export const SET_BRAND_INFO = 'SET_BRAND_INFO';
export const SET_BRAND_SERVICES = 'SET_BRAND_SERVICES';
export const SET_BRAND_BANNERS = 'SET_BRAND_BANNERS';
export const SET_BRAND_VARIATIONS = 'SET_BRAND_VARIATIONS';
export const SET_SERVING_AREAS = 'SET_SERVING_AREAS';
export const SET_ACTIVE_AREA = 'SET_ACTIVE_AREA';

export const SET_BRAND_LOYALTY = 'SET_BRAND_LOYALTY';
export const UPDATE_BRAND_VARIATIONS = 'UPDATE_BRAND_VARIATIONS';
export const SET_ACTIVE_PROVIDERS = 'SET_ACTIVE_PROVIDERS';
export const SET_LOADING = 'SET_LOADING';
export const SET_SCREEN_LOADING = 'SET_SCREEN_LOADING';

export const SET_SARECH_KEYWORDS = 'SET_SARECH_KEYWORDS';
export const SET_ALERT = 'SET_ALERT';
export const SET_LOGIN = 'SET_LOGIN';
export const SET_LOCATION_REQUIRED = 'SET_LOCATION_REQUIRED';
export const SET_LOYALTY_STORE = 'SET_LOYALTY_STORE';
export const SET_AMBASSADOR = 'SET_AMBASSADOR';
export const SET_CASH_PAYMENT = 'SET_CASH_PAYMENT';

export const TOGGLE_ONBOARD = 'TOGGLE_ONBOARD';
export const TOGGLE_SIGNUP = 'TOGGLE_SIGNUP';

export const TOGGLE_REDEEM = 'TOGGLE_REDEEM';
export const INTEGRATION_VIEW = 'INTEGRATION_VIEW';
export const APP_REVIEW_FLAG = 'APP_REVIEW_FLAG';
export const LATEST_CONCENT_VERSION = 'LATEST_CONCENT_VERSION';

export const SUBSCRIPTION_PLANS = 'SUBSCRIPTION_PLANS';


export const SetBrand = createAction(SET_BRAND_INFO, (data) => {
    return data
});
export const SetBrandServices = createAction(SET_BRAND_SERVICES, (data) => {
    return data
});
export const SetBrandBanners = createAction(SET_BRAND_BANNERS, (data) => {
    return data
});
export const SetBrandVariations = createAction(SET_BRAND_VARIATIONS, (data) => {
    return data
});
export const UpdateVariations = createAction(UPDATE_BRAND_VARIATIONS, (data) => {
    return data
});
export const SetServingAreas = createAction(SET_SERVING_AREAS, (data) => {
    return data
});
export const SetActiveArea = createAction(SET_ACTIVE_AREA, (data) => {
    return data
});
export const SetActiveProviders = createAction(SET_ACTIVE_PROVIDERS, (data) => {
    return data
});
export const SetLoading = createAction(SET_LOADING, (data) => {
    return data
});
export const SetScreenLoading = createAction(SET_SCREEN_LOADING, (data) => {
    return data
});
export const SetAlertMessage = createAction(SET_ALERT, (data) => {
    return data
});
export const SetSearchKeywords = createAction(SET_SARECH_KEYWORDS, (data) => {
    return data
});
export const SetLoginRequired = createAction(SET_LOGIN, (data) => {
    return data
});

export const SetRequiredLocation = createAction(SET_LOCATION_REQUIRED, (data) => {
    return data
});
export const SetBrandLoyalty = createAction(SET_BRAND_LOYALTY, (data) => {
    if (!data) {
        return false
    }
    return data
});
export const LoyaltyLinkOpened = createAction(SET_LOYALTY_STORE, (data) => {
    return data
});
export const SetAmbassador = createAction(SET_AMBASSADOR, (data) => {
    return data
});
export const ToggleOnboard = createAction(TOGGLE_ONBOARD, (data) => {
    return data
});
export const ToggleSignup = createAction(TOGGLE_SIGNUP, (data) => {
    return data
});


export const ToggleRedeemVoucher = createAction(TOGGLE_REDEEM, (data) => {
    return data
});

export const ToggleToWebView = createAction(INTEGRATION_VIEW, (data) => {
    return data
});
export const ToggleCashPayment = createAction(SET_CASH_PAYMENT, (data) => {
    return data
});
export const SetSubscriptionPlans = createAction(SUBSCRIPTION_PLANS, (data) => {
    return data
});

export const SetConsentVersion = createAction(LATEST_CONCENT_VERSION, date => date)


const getActiveServices = (state) => {
    if (state?.allServices) {
        let servicesKeys = state?.brand?.services;
        // let servicesKeys = ['dl99sLGm19FL3cIRLd2g', ...state?.brand?.services];
        let activeKeys = []
        let activeServices = servicesKeys?.reduce((val, currentKey, index) => {
            let currentService = state?.allServices?.find(r => r.id === currentKey)
            if (currentService) {
                activeKeys.push(currentKey)
                val.push({ ...currentService, index: index })
            }
            return val
        }, [])
        let variations = state?.allVariations
        if (state?.activeArea) {
            let activeCluster = state?.servingAreas?.find(r => {
                let idExisted = r.areas_list?.find(rec => rec?.id === String(state.activeArea))
                return idExisted
            })
            let variationsKeys = activeCluster?.variations || {}
            variations = Object.keys(variationsKeys)?.map(key => {
                let variation = state.allVariations?.find(rec => rec.id === key)
                if (variation) {
                    variation.pricing.total = variationsKeys?.[key]
                    return variation
                }
            })
        }
        variations = variations?.filter(r => activeKeys?.includes(r?.service_id))
        return { services: activeServices, variations }
    }
}

const initial = {
    loading: true,
    searchKeywords: [],
    isNewUser: true
};

let ex = (state = initial, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.payload }
        case SET_SCREEN_LOADING:
            return { ...state, screenLoading: action.payload }

        case SET_BRAND_INFO:
            return { ...state, brand: action.payload, ...getActiveServices({ ...state, brand: action.payload }) }

        case SET_BRAND_SERVICES:
            return { ...state, allServices: action.payload, ...getActiveServices({ ...state, allServices: action.payload }) }

        case SET_BRAND_BANNERS:
            return { ...state, banners: action.payload }

        case SET_BRAND_VARIATIONS:
            // let variations = variations;
            // if (state?.variations?.length) {
            //     variations = (state.variations || []).reduce((val, currentVal, index) => {
            //         let existed = allVariations.find(r => r.id === currentVal.id)
            //         if (existed) {
            //             val.push({ ...currentVal, index: index })
            //         }
            //         return val
            //     }, []);
            // }
            return { ...state, allVariations: action.payload, ...getActiveServices({ ...state, allVariations: action.payload }) }

        case UPDATE_BRAND_VARIATIONS:
            if (action.payload) {
                let updated = Object.keys(action.payload)?.map(key => {
                    let variation = state.allVariations?.find(rec => rec.id === key)
                    if (variation) {
                        variation.pricing.total = action.payload?.[key]
                        return variation
                    }
                })
                return { ...state, ...getActiveServices({ ...state, variations: updated?.filter(r => !!r) || [] }) }
            }
            return { ...state, ...getActiveServices({ ...state, variations: state.allVariations }) }
        case SET_ACTIVE_AREA:

            return { ...state, activeArea: action.payload, ...getActiveServices({ ...state, activeArea: action.payload }) }

        case SET_SERVING_AREAS:
            return { ...state, servingAreas: action.payload }

        case SET_ACTIVE_PROVIDERS:
            return { ...state, activeProviders: action.payload }

        case SET_BRAND_LOYALTY:
            return { ...state, brandLoyalty: action.payload }

        case SET_SARECH_KEYWORDS:
            return { ...state, searchKeywords: [action.payload, ...state.searchKeywords].slice(0, 5) }

        case SET_ALERT:
            return { ...state, alertObject: action.payload }

        case SET_LOGIN:
            return { ...state, loginRequired: action.payload }

        case SET_LOCATION_REQUIRED:
            return { ...state, toggleLocation: action.payload }

        case SET_LOYALTY_STORE:
            return { ...state, loyaltyFromLink: action.payload }
        case TOGGLE_ONBOARD:
            return { ...state, onboarded: true }

        case TOGGLE_SIGNUP:
            return { ...state, isNewUser: false }

        case SET_AMBASSADOR:
            return { ...state, ambassador: action.payload, readyToRedeem: action.payload ? state?.readyToRedeem : false }
        case TOGGLE_REDEEM:
            return { ...state, readyToRedeem: action.payload }

        case INTEGRATION_VIEW:
            return { ...state, webView: true }
        case APP_REVIEW_FLAG:
            return { ...state, reviewSubmitted: true }
        case SET_CASH_PAYMENT:
            return { ...state, cashEnabled: action.payload }

        case LATEST_CONCENT_VERSION:
            return { ...state, latestConsent: action.payload }
        case SUBSCRIPTION_PLANS:
            return { ...state, plans: action.payload }

        // (async () => {
        //     let active = await action.payload?.reduce(async (val, key) => {
        //         if (state.activeProviders?.[key]) {
        //             return val
        //         }
        //         console.log("getagain");
        //         let provider = await firestore().collection('providers').doc(key).get()
        //         val[key] = provider.data()
        //         return val
        //     }, {})
        //     console.log("getagainsss", { ...state.activeProviders, ...active });

        //     return { ...state, activeProviders: { ...state.activeProviders, ...active } }
        // })()

        default:
            return state;
    }
};
export default ex