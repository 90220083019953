import { useNavigation } from '@react-navigation/native';
import { Pressable, ScrollView, Toast, View } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Trash } from '../../../../constants/imgs';
import { UpdateUser } from '../../../../helpers/helpers';
import { SetAlertMessage } from '../../../../reducers/settings';
import ActionBtn from '../../../shared/ActionBtn';
import Image from '../../../shared/customImg'
import EmptyState from '../../../shared/EmptyState';
import Text from '../../../shared/TextLabel';
import { strings } from '../../../../translations/index';

const PaymentMethods = () => {
    const nav = useNavigation()

    const user = useSelector(state => state.user);
    const dispatch = useDispatch()

    console.log("user?.cards?.lengthuser?.cards?.length", !user?.cards);
    return (
        <View>
            {
                !user?.cards?.length ? <View height={'100%'}>
                    <EmptyState title={strings?.['No Saved Cards']} msg={strings?.["Place an order to add your Card"]} />
                </View>
                    :
                    <View height={'100%'}>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 16, paddingVertical: 16, paddingBottom: 100 }}>

                            {
                                user?.cards?.map(r => <Pressable
                                    onPress={() => {
                                        dispatch(SetAlertMessage({
                                            title: "Warning",
                                            msg: strings['Are you sure ,This card will be deleted permanently'],
                                            confirmText: strings['Delete'],
                                            onConfirm: async () => {
                                                let cards = user.cards.filter(rec => rec.id !== r.id)
                                                const { error } = await dispatch(UpdateUser({ cards }))
                                                if (error) {
                                                    Toast.show({
                                                        placement: 'top',
                                                        title: 'Error',
                                                        status: 'error',
                                                        description: error.message
                                                    })

                                                }
                                                dispatch(SetAlertMessage(false))
                                            }
                                        }))

                                    }}
                                    flexDirection={'row'}
                                    borderRadius={8}
                                    marginBottom={4}
                                    backgroundColor='#fff'
                                    padding={4}
                                    alignItems='center'
                                    justifyContent={'space-between'}
                                    key={r.id}>
                                    <View w={'80%'}>
                                        <Text fontFamily='semiBold'>
                                            {r?.card?.brand}
                                        </Text>
                                        <Text fontFamily='medium' paddingTop={1}>
                                            XXXX {r?.card?.last4}
                                        </Text>
                                    </View>
                                    <Image
                                        svg
                                        fill='red'
                                        uri={Trash}
                                        width={20}
                                        height={20}
                                    />
                                </Pressable>)
                            }

                        </ScrollView>
                    </View>
            }
            {
                Platform.OS === 'web' && user?.uid ?

                    <View position={'fixed'} bottom={12} left={0} right={0}>
                        <ActionBtn
                            title={strings?.['Add Card']}
                            onPress={() => {
                                nav.navigate('Payment', { screen: 'addCard' })
                            }} />
                    </View>
                    :
                    <View />
            }


        </View >
    );
};

export default PaymentMethods;