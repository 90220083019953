import { Pressable, Toast, View } from 'native-base';
import React, { useState } from 'react';
import { ImageBackground, SafeAreaView, Animated, Platform, ActivityIndicator, Share } from 'react-native';
import { useSelector } from 'react-redux';
import Image from '../../../shared/customImg'
import { BackSVG, ShareSVG } from '../../../../constants/imgs'
import Text from '../../../shared/TextLabel';
import { useNavigation } from '@react-navigation/native';
import LinearGradient from '../../../shared/LinearGradient';
import { Localize } from '../../../../helpers/helpers';
import { BuildShareLink } from '../../../../helpers/index';
const AnimFastImage = Animated.createAnimatedComponent(ImageBackground);

const ServicesHeader = ({ data, animStyle, revertStyle, basic, variation }) => {
    const navigation = useNavigation();
    const { webView, brand } = useSelector(state => state.settings);
    const { locale } = useSelector((state) => state.locale);
    const [showAll, setShowAll] = useState(false)
    const [loading, setLoading] = useState(false)
    let picture = data?.picture?.[0] || data?.image;
    return (
        <Animated.View style={{ width: '100%', height: webView ? 'calc(100vw / 1.7)' : 'auto', aspectRatio: 1.7 }}>
            <AnimFastImage accessibilityLabel={picture}
                style={[{ flex: 1, backgroundColor: '#fff' }, animStyle]}
                source={{ uri: picture, cache: 'force-cache' }}  >
                <LinearGradient style={{ flex: 1 }} start={{ x: 0, y: 0.5 }} end={{ x: 1, y: 0.5 }} locations={[0, 0.25, 0.5, 0.75]}
                    colors={['#00000070', '#00000050', '#00000030', '#00000015']} >
                    <LinearGradient style={{ flex: 1 }} colors={['#ffffff00', '#ffffff00', '#00000050', '#00000090']} >
                        <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === 'android' ? 52 : Platform.OS === 'web' ? 20 : 0 }}>
                            <Animated.View style={[{ paddingHorizontal: 16, paddingBottom: 26, flex: 1, justifyContent: 'space-between' }, revertStyle]} justifyContent='space-between'  >
                                {
                                    webView ? <View w={30} h={30} /> : <View flexDirection={'row'} justifyContent='space-between'>

                                        <Pressable
                                            onPress={() => {
                                                if (navigation.canGoBack()) {
                                                    navigation.goBack()
                                                    return
                                                }
                                                navigation.reset({
                                                    index: 0,
                                                    routes: [{ name: 'Home' }],
                                                })
                                            }}
                                            hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                                            w={30} h={30} style={{ backgroundColor: '#35353530' }} justifyContent='center' alignItems={'center'} borderRadius={8} >
                                            <Image
                                                rotate={locale === 'ar'}
                                                svg
                                                uri={BackSVG}
                                                width={15}
                                                height={15}
                                            />
                                        </Pressable>


                                        <Pressable paddingY={1}
                                            background="#35353530"
                                            justifyContent={'center'}
                                            alignItems='center'
                                            w={8}
                                            h={8}
                                            borderRadius={8}
                                            onPress={async () => {
                                                try {
                                                    setLoading(true)
                                                    let url = await BuildShareLink({
                                                        sharedPath: variation ? 'service_page' : 'Services',
                                                        item: data,
                                                        brandName: brand?.name?.en || 'Metadoc'
                                                    })
                                                    if (Platform.OS === 'web') {
                                                        await navigator.share({
                                                            // title: 'Metadoc Order status',
                                                            // text: `Customer Name: ${data?.contact?.name}\nAddress: ${Localize(data?.location?.address)}\nService: ${Localize(data?.service?.name)}`,
                                                            url: url
                                                        })
                                                    } else {
                                                        await Share.share({
                                                            url: url,
                                                            // title: 'Metadoc Order status',
                                                        })
                                                    }
                                                    setLoading(false)
                                                } catch (error) {
                                                    setLoading(false)
                                                    Toast.show({
                                                        title: 'This Feature is not supported on current browser',
                                                        placement: 'top'
                                                    })
                                                    console.log("errorerrorerror", error);
                                                }
                                            }}
                                        >
                                            {
                                                loading ? <ActivityIndicator />
                                                    :
                                                    <Image
                                                        svg
                                                        uri={ShareSVG}
                                                        fill='#ffffff'
                                                        width={20}
                                                        height={20}
                                                    />
                                            }

                                        </Pressable>

                                    </View>
                                }

                                {
                                    !basic && <View>
                                        <Text
                                            fontFamily='bold'
                                            fontSize={20} style={{ color: '#fff' }}>
                                            {Localize(data?.name)}
                                        </Text>
                                        <Pressable onPress={() => setShowAll(!showAll)} >
                                            <Text fontSize={10} numberOfLines={showAll ? '' : 2} style={{ color: '#fff' }}>
                                                {Localize(data?.details)}
                                            </Text>
                                        </Pressable>
                                    </View>
                                }



                            </Animated.View>
                        </SafeAreaView>

                    </LinearGradient>
                </LinearGradient>
            </AnimFastImage>
        </Animated.View>
    );
};

export default ServicesHeader;