import React, { useEffect, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { Animated } from 'react-native';

const QRcode = ({ value }) => {
    const startValue = new Animated.Value(1);
    const endValue = 5;

    useEffect(() => {
        Animated.loop(
            Animated.timing(startValue, {
                duration: 3000,
                toValue: endValue,
                // friction: 1,
                useNativeDriver: false,
            }),
            {},
        ).start();
    }, [startValue, endValue]);

    return (
        <Animated.View

            style={{
                borderRadius: 8,
                width: '60%',
                justifyContent: 'center',
                padding: 16,
                alignItems: 'center',
                borderWidth: 3,
                borderColor: startValue.interpolate({
                    inputRange: [0, 3, 5],
                    outputRange: ['#cacaca', '#2f8af4', '#cacaca']
                })
            }}
            borderRadius={8} width='60%' justifyContent='center' padding={4} alignItems='center' borderWidth={1} >
            <QRCodeCanvas value={value} style={{ width: '100%', height: '100%' }} />
        </Animated.View>
    );
};

export default QRcode;