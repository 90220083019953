import { useNavigation } from '@react-navigation/native';
import { View } from 'native-base';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateUser } from '../../../helpers/helpers';
import ActionBtn from '../ActionBtn';

const Consent = ({ readOnly = false, type }) => {
    const { latestConsent } = useSelector(state => state.settings)
    const dispatch = useDispatch();
    if (readOnly) {
        return <View flex={1} background={'#fff'} padding={4} >
            <iframe id='myiFrame' style={{ flex: 1, padding: '0 16px 0 0', margin: 0, width: '100%', border: 'none' }} src={type === 'privacy' ? latestConsent?.link : latestConsent?.termsLink} ></iframe>
        </View>
    }
    const navigation = useNavigation();
    return (
        <View flex={1} background={'#fff'} padding={4} >
            <iframe id='myiFrame' style={{ flex: 1, padding: '0 16px 0 0', margin: 0, width: '100%', border: 'none' }} src={latestConsent?.link} ></iframe>
            <View padding={4} background='#fff'>
                <ActionBtn
                    onPress={async () => {
                        await dispatch(UpdateUser({ consent: latestConsent?.version }))
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'Home' }],
                        })
                    }}
                    relative={true}
                    title={'Accept'}
                />
            </View>
        </View>
    );
};

export default Consent;