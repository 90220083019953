import { Flex, ScrollView, Switch, useToast, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../shared/CustomInput';
import PhoneNumber from '../../../shared/PhoneInput/index';
import Text from '../../../shared/TextLabel';
import OrderSummarySheet from '../../../shared/OrderSummarySheet';
import { SetOrderContact } from '../../../../reducers/order';
import { Localize } from '../../../../helpers/helpers';
import { Platform } from 'react-native';
import { strings } from '../../../../translations/index';

const ContactInfo = ({ navigation }) => {
    const [state, setState] = useState({});
    const [contactInfo, toggleContact] = useState(true);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const { brand } = useSelector(state => state.settings)
    const toast = useToast();
    const [fields] = useState(
        brand?.customLocations ?
            [
                {
                    title: strings?.['Contact Person Name'],
                    type: 'input',
                    key: 'name'

                }
            ] :
            [
                {
                    title: strings?.['Contact Person Name'],
                    type: 'input',
                    key: 'name'

                },
                {
                    title: strings?.['Room Number'],
                    type: 'input',
                    key: 'room'
                },
                {
                    title: strings?.['Building'],
                    type: 'input',
                    key: 'building'
                },
                {
                    title: strings?.['Your Customer will receive status updates'],
                    type: 'msg',
                    key: 'msg'
                },
            ])

    const [CustomerFields] = useState([
        {
            title: strings?.['Email'],
            type: 'input',
            key: 'email'
        },
        {
            title: strings?.['Contact Person Number'],
            type: 'numebr',
            key: 'phoneNumber',
            disabled: user.role !== 'concierge'
        },
    ])
    useEffect(() => {
        if (user.role !== 'concierge') {
            let { phoneNumber, email, displayName } = user;
            (async () => {
                // let snap = await firestore().collection('customers').doc(user.uid).get()
                setState({ ...state, email, name: displayName, ...user?.contact, phoneNumber, building: Localize(brand?.name) })
            })()
        }
    }, [user])
    return (
        <View flex={1}>
            <ScrollView contentContainerStyle={{ paddingBottom: 150 }} >
                <Flex paddingX={4}>
                    <Text
                        fontFamily='semiBold'
                        paddingTop={8}>
                        {strings?.["Customer information"]}
                    </Text>
                    <View paddingTop={4}>
                        {
                            (contactInfo ? [...fields, ...CustomerFields] : fields).map(rec => <View key={rec.key} paddingBottom={2}>
                                {
                                    rec.type === 'msg' ?
                                        user.role === 'concierge' ?
                                            <View key={rec.key} backgroundColor='#F5B82F30' padding={4} borderRadius={8} mt={6} mb={4} flexDirection='row' justifyContent={'space-between'} alignItems='center'>
                                                <Text maxWidth={'80%'} fontSize={12} style={{ color: '#F5B82F' }} >
                                                    {
                                                        rec.title
                                                    }
                                                </Text>
                                                <Switch
                                                    onToggle={(val) => {
                                                        toggleContact(!contactInfo)
                                                    }}
                                                    isChecked={contactInfo}
                                                    onTrackColor={'#FFB926'}
                                                    onThumbColor='#ffffff'
                                                    offThumbColor={'#fff'}
                                                />
                                            </View>
                                            :
                                            <View />
                                        :
                                        <>
                                            <Text paddingBottom={1} style={{ fontSize: 12 }} >
                                                {rec.title}
                                            </Text>
                                            {
                                                rec.type === 'numebr' ?
                                                    <View style={{ direction: 'ltr' }}>
                                                        <PhoneNumber disabled={rec.disabled} value={state[rec.key]} country={'ae'} onChange={phone => setState({ ...state, [rec.key]: phone })} />

                                                    </View>
                                                    :
                                                    <Input value={state[rec.key]} onChange={e => setState({ ...state, [rec.key]: Platform.OS === 'web' ? e.target.value : e.nativeEvent.text })} />
                                            }
                                        </>
                                }


                            </View>)
                        }
                    </View>
                </Flex>

            </ScrollView>

            <OrderSummarySheet 
            bottom={88}
            fixed={true}
                onPress={() => {
                    let { name, email, phoneNumber } = state

                    switch (user.role) {
                        case 'concierge':
                            if (contactInfo) {
                                if (name && email && phoneNumber) {
                                    dispatch(SetOrderContact(state));
                                    navigation.navigate('PaymentType');
                                    return
                                }
                            } else {
                                if (name) {
                                    dispatch(SetOrderContact({ name: name, email: user.email, room: state.room, concierge: user.uid }));
                                    navigation.navigate('PaymentType');
                                    return
                                }
                            }

                        default:
                            if (name && email && phoneNumber) {
                                dispatch(SetOrderContact(state));
                                navigation.navigate('PaymentType');
                                return
                            }
                    }
                    toast.show({
                        title: strings?.['Incomplete data'],
                        status: "error",
                        placement: "top",
                        description: strings?.["Please fill all required fields"]
                    })


                }} />
        </View>
    );
};

export default ContactInfo;