import { useNavigation } from '@react-navigation/native';
import { Pressable, View } from 'native-base';
import React from 'react';
import { Platform, SafeAreaView } from 'react-native';
import { useSelector } from 'react-redux';
import { BackSVG } from '../../../../constants/imgs';
import { Localize } from '../../../../helpers/helpers';
import { strings } from '../../../../translations/index';
import Image from '../../../shared/customImg'
import Text from '../../../shared/TextLabel';

const Header = ({ step = 1, totalSteps = 3 }) => {
    const { service } = useSelector(state => state.order);
    const { locale } = useSelector(state => state.locale);
    const { webView } = useSelector(state => state.settings);

    const navigate = useNavigation();
    if (webView) {
        return (
            <View />
        )
    }
    return (
        <SafeAreaView style={{ backgroundColor: '#fff' }}>
            <View marginTop={Platform.OS == 'android' ? 6 : 0} style={{ backgroundColor: '#fff', flexDirection: 'row', padding: 20 }}>
                <Pressable
                    onPress={() => navigate.goBack()}
                    hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                    paddingY={1}>
                    <Image
                        rotate={locale === 'ar'}
                        svg
                        fill='#000000'
                        uri={BackSVG}
                        width={15}
                        height={15}
                    />
                </Pressable>

                <View paddingX={4} >
                    <Text fontSize={16} >
                        {strings["Schedule Appointment"]}
                    </Text>
                    <View flexDirection={'row'}>
                        <Text numberOfLines={1} fontWeight='Bold' fontFamily='bold' maxWidth={'70%'}>
                            {Localize(service?.name)}
                        </Text>
                        <Text fontWeight='400' paddingX={1} fontFamily='semiBold' style={{ color: '#20A2DF' }}>
                            {strings.Step} {step} {strings["out of"]} {totalSteps}
                        </Text>
                    </View>


                </View>
            </View>
        </SafeAreaView>
    );
};

export default Header;