import { getPathFromState, getStateFromPath, NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import Home from '../component/screens/home/index';
import Services from '../component/screens/services'
import Booking from '../component/screens/Booking/navigator'
import CheckoutNavigator from '../component/screens/OrderStatus/navigator';
import MenuNavigator from '../component/screens/Menu/navigator';
import Login from '../component/screens/login/index'
import { auth, firestore, analytics } from '../constants/firebase/config.web';
import { batch, useDispatch, useSelector } from 'react-redux';
import { SetActiveArea, SetActiveProviders, SetAlertMessage, SetBrand, SetBrandBanners, SetBrandServices, SetBrandVariations, SetConsentVersion, SetLoading, SetScreenLoading, SetServingAreas, ToggleCashPayment, ToggleSignup, ToggleToWebView, UpdateVariations } from '../reducers/settings';
import { FlushUser, GetUser, SetUserDefaultLocation, SetUserOrders } from '../reducers/user';
import { FlushOrder, SetOrderLocation } from '../reducers/order';
import { Center, Flex, Skeleton, useToast, View, VStack } from 'native-base';
import PaymentForm from '../component/Payment/index';
import OrderDetailsComp from '../component/screens/OrderStatus/index';
import Pharmacy from '../component/screens/pharmacy'
import { CheckValidPromoForUser, ChillCheckLocationServed, GetGlobalPromos, GetUserRecord, SetupHotelLocation, UpdateCurrnetLanguage, UpdateUser } from '../helpers/helpers';
import Header from '../component/shared/Header';
import ServicePage from '../component/screens/services/com/VariationPage';
import AddressNavigator from "../component/screens/Locations/navigator";
import BottomModal from "../component/shared/BottomModal";
import * as Sentry from "@sentry/react";
import { strings } from '../translations/index';
import { Platform } from 'react-native';
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";
import { isAndroid, isIOS } from "react-device-detect";
import queryString from 'query-string';
import Consent from '../component/shared/Consent/Consent';
import MeetingDoc from "../component/screens/MeetingDoc";
import ProfileInfo from '../component/screens/Menu/com/ProfileInfo';
const HomeStack = createStackNavigator();
const ServicesStack = createStackNavigator();

const ServicesNavigator = ({ route }) => {
    return (
        <ServicesStack.Navigator
            initialRouteName="Services"
            screenOptions={{
                headerShown: true,
                cardStyle: { backgroundColor: '#F5F7F8' }
            }}
        >
            <ServicesStack.Screen name="Services" component={Services} initialParams={route?.params} options={{
                header: () => null,
            }} />
        </ServicesStack.Navigator>
    )
}


const Routes = () => {
    const [initialScreen, setInitial] = useState('Home');
    const [listenToUpdates, toggleListenToUpdates] = useState(false);
    const [showBanner, ToggleBanner] = useState(window?.location?.hostname?.split('.')?.[0] === 'app');


    const navigationRef = useNavigationContainerRef();
    const dispatch = useDispatch();
    const toast = useToast();

    const userData = useSelector(state => state.user);
    const { locale } = useSelector(state => state.locale)
    const { discount: PromoDiscount } = useSelector(state => state.order)
    const [linkingEnabled, toggleLinking] = useState(true)
    const { servingAreas, brand, isNewUser, loading, webView, latestConsent } = useSelector(state => state.settings)
    useEffect(() => {
        dispatch(SetOrderLocation(userData?.defaultLocation || brand?.address))
    }, [userData?.defaultLocation])

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search);
        const pasedLocale = queryParams?.locale;
        if (pasedLocale) {
            dispatch(UpdateCurrnetLanguage(pasedLocale))
        } else {
            document.location.search = document.location.search + `&locale=${locale ? locale : 'en'}`;
        }
    }, [])
    useEffect(() => {
        if (listenToUpdates) {
            let ref = isIOS ? window?.webkit?.messageHandlers : window;
            const searchParams = new URL(window.location.href)?.searchParams;

            // (isIOS ? window : document).addEventListener("message", message => {
            if (searchParams.get('fromMeta')) {
                ToggleBanner(false)
                dispatch(ToggleToWebView());
                // window.webkit.messageHandlers.ReactNativeWebView.postMessage(searchParams.get('fromMeta'));
                // ref.ReactNativeWebView.postMessage(message.data);
                // window.webkit.messageHandlers.ReactNativeWebView.postMessage(searchParams.get('currentLocation'));
                // window.webkit.messageHandlers.ReactNativeWebView.postMessage(JSON.stringify({userToken:searchParams.get('userToken')}));

                let currentLocation = JSON.parse(searchParams.get('currentLocation'))
                let userToken = searchParams.get('userToken')
                let locale = searchParams.get('locale')
                if (locale) {
                    dispatch(UpdateCurrnetLanguage(locale))
                }
                let { location } = currentLocation;
                (async () => {
                    try {
                        auth().signInWithCustomToken(userToken);
                        let { error, success } = await dispatch(ChillCheckLocationServed(servingAreas, location));
                        if (error) {
                            ref?.ReactNativeWebView?.postMessage(JSON.stringify(error));
                            return dispatch(SetAlertMessage(error))
                        } else if (success) {
                            dispatch(SetUserDefaultLocation(success))
                        }
                        ref?.ReactNativeWebView?.postMessage(JSON.stringify(success || { noSuccess: "" }));
                    } catch (error) {
                        ref?.ReactNativeWebView?.postMessage(JSON.stringify(error));
                    }
                })()
            }
            // });
        }

    }, [listenToUpdates])
    useEffect(() => {
        let ref = isIOS ? window?.webkit?.messageHandlers : window;
        if (servingAreas && !listenToUpdates && ref?.ReactNativeWebView) {
            toggleListenToUpdates(true)
            ref.ReactNativeWebView.postMessage(JSON.stringify({ appLoaded: true }));
        }
    }, [servingAreas])

    useEffect(() => {
        // window.onresize = function () {
        //     window.document.body.style.height = `${window.innerHeight}px`
        // }
        batch(() => {
            dispatch(SetAlertMessage(false))
            dispatch(SetUserDefaultLocation(false))
            dispatch(SetLoading(true));
            dispatch(FlushOrder());
        })
        let unsubscripe = [];
        (async () => {
            let partnerKey = window.location.hostname.split('.')?.[0];
            // let partnerKey = 'app'
            let partnerSnap = await firestore().collection('partners').where('url', '==', partnerKey.toLowerCase()).get();
            let data = { ...partnerSnap.docs?.[0]?.data(), id: partnerSnap.docs?.[0]?.id };
            if (data?.parent_id) {
                let branSnap = await firestore().collection('brands').doc(data.parent_id).get();
                data = { ...data, parent: branSnap.data() }
            }
            let servicesIds = data.services;
            // let servicesIds = ['dl99sLGm19FL3cIRLd2g',...data.services];
            let services = []

            await Promise.all((servicesIds ? servicesIds : []).map(async key => {
                let serviceSnap = await firestore().collection('services').doc(key).onSnapshot(snap => {
                    if (snap.data()?.deactivated !== true && snap.data()?.active === true) {
                        // if (snap.data()?.deactivated !== true) {

                        services.push({ ...snap.data(), id: snap.id })
                    }
                });
                unsubscripe.push(serviceSnap)
            }))
            let locationsSnap = await firestore().collection('partners').doc(data?.id).collection('locations').onSnapshot(snap => {
                let locationsData = snap?.docs?.map(rec => {
                    if (rec.data()?.deactivated !== true) {
                        return ({ ...rec.data(), id: rec.id })
                    }
                })
                dispatch(SetServingAreas(locationsData));
                if (!data?.customLocations) {
                    dispatch(SetupHotelLocation({ ...data?.address, locations: locationsData }))
                }
            });


            let variationsSnap = await firestore().collection('partners').doc(data?.id).collection('variations').onSnapshot(snap => {
                let variationsData = snap?.docs?.map(rec => {
                    if (rec.data()?.deactivated !== true && !!services?.find(r => r.id === rec?.data().service_id)) {
                        return ({ ...rec.data(), id: rec.id })
                    }
                })?.filter(r => r)
                dispatch(SetBrandVariations(variationsData));
            });
            let bannersSnap = await firestore().collection('partners').doc(data?.id).collection('banners').onSnapshot(snap => {
                let bannersData = snap?.docs?.map(rec => {
                    if (rec.data()?.deactivated !== true && rec.data()?.active) {
                        return ({ ...rec.data(), id: rec.id })
                    }
                })
                dispatch(SetBrandBanners(bannersData))
            });
            firestore().collection('offers').where('global', '==', true).onSnapshot(snap => {
                dispatch(GetGlobalPromos(snap, data?.id))
            });

            unsubscripe.push(bannersSnap)
            unsubscripe.push(variationsSnap)
            unsubscripe.push(locationsSnap)

            let activeProvidersSnap = await firestore().collection('providers').get()
            let activeProviders = activeProvidersSnap.docs.map(rec => {
                if (rec.data()?.deactivated !== true) {
                    return ({ ...rec.data(), id: rec.id })
                }
            })
            firestore().collection('partners').doc(data?.id).collection('settings').doc('cashPaymentWeb').onSnapshot(snap => {
                let { active } = snap.data() || {};
                dispatch(ToggleCashPayment(active || false))

            })

            batch(() => {
                dispatch(SetBrand(data));
                dispatch(SetBrandServices(services))
                dispatch(SetActiveProviders(activeProviders))
                dispatch(SetLoading(false));
            })
        })()

        firestore().collection('forceUpdate').doc('t&c').onSnapshot(snap => {
            let consentVersion = snap?.data();
            dispatch(SetConsentVersion(consentVersion))
        })

        auth().onAuthStateChanged(async user => {

            if (user) {
                if ((user?.metadata?.creationTime === user?.metadata?.lastSignInTime) && isNewUser) {
                    dispatch(ToggleSignup())
                    await analytics().logEvent('sign_up', { method: 'phone_number', platform: Platform.OS })
                }
                // else {
                //     await analytics().logEvent('login', { method: 'phone_number', platform: Platform.OS })
                // }
                Sentry.setUser({ id: user?.uid });

                let idTokenResult = await auth().currentUser.getIdTokenResult();

                let { role, parent_id } = idTokenResult?.claims || {};
                if (role === 'concierge') {
                    let partnerKey = window.location.hostname.split('.')?.[0];
                    if (parent_id === partnerKey) {
                        dispatch(GetUser({ ...user, role: role }))


                        if (!['Home', 'OrderDetails', 'order_status_provider'].includes(navigationRef?.getCurrentRoute()?.name)) {
                            setInitial('Home')
                            // navigationRef.resetRoot({
                            //     index: 0,
                            //     routes: [{ name: 'Home' }],
                            // })
                        }
                    } else {
                        await auth().signOut()
                        return toast.show({
                            title: 'Error unauthorized account',
                            status: 'error',
                            placement: 'top'
                        })
                    }
                } else {
                    try {
                        let userSnap = await GetUserRecord(user.uid);
                        dispatch(GetUser({ ...JSON.parse(JSON.stringify(user)), ...userSnap, role: 'customer', consent: latestConsent?.version }))
                        dispatch(SetScreenLoading(false))
                        if ((userSnap?.consent || 0) !== (latestConsent?.version || 0)) {
                            dispatch(UpdateUser({ consent: latestConsent?.version }))
                        }
                    } catch (error) {
                        console.log("errorerrorerrorerrorerrorerror", error);
                        dispatch(FlushUser())
                        dispatch(SetScreenLoading(false))

                    }
                    // let userSnapRef = await firestore().collection('customers').doc(user.uid).onSnapshot(userSnap => {
                    //     dispatch(GetUser({ ...user, ...userSnap.data(), role: 'customer' }))
                    //     // if (!['Home', 'OrderDetails', 'Services','Address Details'].includes(navigationRef?.getCurrentRoute()?.name)) {
                    //     //     setInitial('Home')
                    //     //     navigationRef.resetRoot({
                    //     //         index: 0,
                    //     //         routes: [{ name: 'Home' }],
                    //     //     })
                    //     // }
                    // })
                    // unsubscripe.push(userSnapRef)
                }
                let ordersSnap = firestore().collection('orders').where(role === 'concierge' ? 'concierge' : 'customer_id', '==', user.uid).onSnapshot(snap => {
                    let data = snap?.docs?.map(rec => {
                        return ({ ...rec.data(), id: rec.id })
                    })
                    dispatch(SetUserOrders(data))
                })
                unsubscripe.push(ordersSnap)
            } else {
                dispatch(FlushUser());
                // navigationRef.resetRoot({
                //     index: 0,
                //     routes: [{ name: 'Login' }],
                // })
            }
        })
    }, [])
    useEffect(() => {
        if (PromoDiscount && userData.uid) {
            dispatch(CheckValidPromoForUser(userData.uid))
        }
    }, [userData, PromoDiscount]);

    useEffect(() => {
        let { place_id } = userData?.defaultLocation || {};
        if (place_id) {
            dispatch(SetActiveArea(place_id))
            // let activeCluster = servingAreas?.find(r => {
            //     let idExisted = r.areas_list?.find(rec => rec?.id === String(place_id))
            //     return idExisted
            // })
            // let { variations } = activeCluster || {}
            // if (variations) {
            //     dispatch(UpdateVariations(variations))

            // }
        } else {
            dispatch(SetActiveArea(false))

            // dispatch(UpdateVariations(false))

        }
    }, [userData?.defaultLocation, servingAreas])
    useEffect(() => {
        if (userData?.uid && ((userData?.consent || 0) < (latestConsent?.version || 0))) {
            navigationRef.reset({
                index: 0,
                routes: [{ name: 'Consent' }],
            })
        }
    }, [userData?.consent, latestConsent])

    return (
        <NavigationContainer

            onStateChange={(e) => {
                if (e.routes?.[e.routes.length - 1]?.name === "Chat") {
                    toggleLinking(false)
                } else {
                    toggleLinking(true)
                }
                if (webView) {
                    let ref = isIOS ? window?.webkit?.messageHandlers : window;
                    let finalRoute = e?.routes?.[e?.routes?.length - 1];
                    let canGoBack = e?.routes?.length > 1 || false;
                    let path = finalRoute?.state?.routes[finalRoute?.state?.routes.length - 1]?.name || finalRoute?.name;
                    if (ref?.ReactNativeWebView) {
                        ref.ReactNativeWebView.postMessage(JSON.stringify({ navigation: { path, canGoBack } }));
                    }
                }
            }}
            ref={navigationRef}
            linking={{
                prefixes: ['https://web-metadoc.web.app', 'https://*.metadocapp.com', 'https://*.localhost:3000.com'],
                enabled: linkingEnabled,
                config: {
                    screens: {
                        Home: '/home',
                        Menu: '/menu',
                        Services: {
                            screens: {
                                Services: '/services',
                            }
                        },
                        Pharmacy: '/services_results/:title',
                        service_page: 'service_page',
                        Booking: '/bookings',
                        OrderDetails: {
                            screens: {
                                order: '/order_details/order/:order_id'
                            }
                        },
                        provider_order: '/provider_order/:order_id',
                        Payment: {
                            screens: {
                                order: 'Payment/order/:order_id'
                            }
                        }

                        // OrderDetails: '/order_details'
                    }
                },
                // getStateFromPath: (path, options) => {
                //     console.log("pathpathpathpath", path);
                //     // Return a state object here
                //     return getStateFromPath(path,options)
                //     // You can also reuse the default logic by importing `getStateFromPath` from `@react-navigation/native`
                // },
                // getPathFromState(state, config) {
                //     // return 'home'
                //     console.log("statestatestate",state);
                //     let test = getPathFromState(state, config)
                //     console.log("testtesttesttest", test);
                //     // if (test === '/Chat') {
                //     //     return '/Chat'
                //     // }
                //     return test
                // },
            }}
        >
            {
                showBanner && !webView && <SmartBanner
                    title={"Metadoc - Healthcare at Home"}
                    daysHidden={0}
                    daysReminder={0}
                    position="top"
                    force={isAndroid ? "android" : isIOS ? 'ios' : ''}
                    appMeta={{ ios: 'apple-itunes-app', android: 'google-play-app' }}
                    storeText={{ ios: 'On the App Store', android: 'In Google Play' }}
                    onClose={() => {
                        ToggleBanner(false)
                    }}
                />
            }
            <HomeStack.Navigator
                screenOptions={{
                    animationEnabled: true,
                    cardStyle: { backgroundColor: '#F5F7F8' }
                }}
                initialRouteName={initialScreen}
            >
                <HomeStack.Screen name="Payment" component={PaymentForm} options={{
                    header: () => null,
                }} />
                <HomeStack.Screen name="Consent" component={Consent} options={{
                    header: () => null,
                }} />

                <HomeStack.Screen name="Login" component={Login} options={{
                    header: () => null,
                }} />
                <HomeStack.Screen name="Home" component={Home} options={{
                    header: () => null,
                }} />
                <HomeStack.Screen name="Menu" component={MenuNavigator} options={{
                    cardStyleInterpolator: CardStyleInterpolators.forBottomSheetAndroid,
                    header: () => null,
                }} />
                <HomeStack.Screen name="Services" component={ServicesNavigator} options={{
                    cardStyleInterpolator: CardStyleInterpolators.forBottomSheetAndroid,
                    header: () => null,
                }} />
                <HomeStack.Screen name="Pharmacy" component={Pharmacy} options={{
                    // cardStyleInterpolator: CardStyleInterpolators.forBottomSheetAndroid,
                    header: (props) => <Header style={{ borderBottomWidth: 0 }} title={strings?.[props?.route?.params?.title] || props?.route?.params?.title} />,
                }} />
                <HomeStack.Screen name="service_page" component={ServicePage} options={{
                    // cardStyleInterpolator: CardStyleInterpolators.forBottomSheetAndroid,
                    header: () => null,
                }} />
                <HomeStack.Screen name="Booking" component={Booking} options={{
                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                    header: () => null,
                }} />

                <HomeStack.Screen name="OrderDetails" component={CheckoutNavigator} options={{
                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                    header: () => null,
                }} />

                <HomeStack.Screen name="provider_order" component={OrderDetailsComp} options={{
                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                    header: () => null,
                }} />

                <HomeStack.Screen name="Chat" component={MeetingDoc} options={{
                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                    header: () => null,
                }} />
                <HomeStack.Screen name="profile-info" component={ProfileInfo} options={{
                    header: (e) => <Header title={e?.route?.params?.title || strings?.['Profile info']} />,
                }} />
                {/* <HomeStack.Screen name="OrderDetails" component={OrderDetails} options={{
                    header: () => null,
                }} /> */}
                {AddressNavigator(HomeStack)}

            </HomeStack.Navigator>
            <BottomModal />
            {
                loading && <Flex flex={1} position={'absolute'} w={'100%'} zIndex={9999} backgroundColor='#ffffff'>
                    <Loader />
                </Flex>
            }
        </NavigationContainer>
    );
};

const Loader = () => {
    return <Center w="100%">
        <VStack w="100%" borderWidth="1" space={6} rounded="md" alignItems="center" _dark={{
            borderColor: "coolGray.500"
        }} _light={{
            borderColor: "coolGray.200"
        }}>

            <Skeleton style={{ height: 220 }} />
            <Skeleton borderWidth={1} borderColor="coolGray.200" endColor="warmGray.50" size="20" rounded="full" mt="-190" />
            <Skeleton.Text lines={2} borderColor="coolGray.200" endColor="warmGray.50" alignItems="center" px="12" mb='50' />
            {
                [1, 2, 3, 4, 5, 6, 7, 8].map(r =>
                    <VStack w="90%" key={r}>
                        <Skeleton style={{ height: 148, paddingHorizontal: 8 }} rounded="md" />
                    </VStack>
                )
            }
        </VStack>
    </Center>;
};
export default Routes;