import { ScrollView, View } from 'native-base';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { ArabicSVG, EnglishSVG, PhoneSVG, MailSVG } from '../../../../constants/imgs';
import MenuCell from './menuCell';
import call from 'react-native-phone-call';
import email from 'react-native-email';

const ContactUs = () => {

    return (
        <View>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 16, paddingVertical: 16 }}>
                {/* <View paddingY={2}>
                    <TouchableOpacity>
                        <MenuCell data={{ title: 'Whatsapp', img: WhatsappSVG }} />
                    </TouchableOpacity>
                </View> */}
                <View paddingY={2}>
                    <TouchableOpacity onPress={() => {
                        const number = '+971502023718'
                        const args = {
                            number: number,
                            prompt: true,
                        };
                        call(args).catch(console.error);
                    }}>
                        <MenuCell data={{ title: 'Call us', img: PhoneSVG }} />
                    </TouchableOpacity>
                </View>
                <View paddingY={2}>
                    <TouchableOpacity onPress={() => {
                      const to = ['letstalk@metadocapp.com']; // string or array of email addresses
                      email(to).catch(console.error);
                    }}>
                        <MenuCell data={{ title: 'Reach us', img: MailSVG }} />
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </View>
    );
};

export default ContactUs;