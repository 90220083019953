import Axios from 'axios';
import { auth } from '../constants/firebase/config'
import { AuthorizeMeetingdocUser, CreateMeetingdocUser, Localize, RemoveTags  } from './helpers';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { REACT_APP_GOOGLE_API } from './envVariables/index';
import { Platform } from 'react-native';
export async function onGoogleButtonPress() {
  const provider = new auth.GoogleAuthProvider();
  provider.setCustomParameters({
    'display': 'popup'
  });
  return auth().signInWithPopup(provider)
}
export async function onFacebookButtonPress() {
  const provider = new auth.FacebookAuthProvider();
  provider.setCustomParameters({
    'display': 'popup'
  });
  return auth().signInWithPopup(provider)

}
export async function onAppleButtonPress() {
}
export function logout() {
  auth().signOut()

}
export async function BuildShareLink(params) {
  let { sharedPath, item, brandName = 'Metadoc' } = params;
  let hostname = window.location.hostname;
  let encoded = encodeURIComponent(`https://${hostname}/${sharedPath}?sharedPath=${sharedPath}`);
  switch (sharedPath) {
    case 'Services':
      encoded = encodeURIComponent(`https://${hostname}/services?sharedPath=${sharedPath}&serviceID=${item?.id}`);
      break
    case 'service_page':
      encoded = encodeURIComponent(`https://${hostname}/service_page?sharedPath=${sharedPath}&id=${item?.id}&type=${item?.service_id === 'pharmacy' ? 'medicine' : 'variation'}`);
      break
    default: break;
  }

  let baseLink = (Platform.OS === 'web' && hostname !== 'app.metadocapp.com') ? 'metadocapp' : 'metadoc';
  let link;
  switch (baseLink) {
    case 'metadocapp':
      link = (`https://${baseLink}.page.link/?link=${encoded}&efr=1&utm_medium=referral&st=${Localize(item.name)} | ${brandName}&sd=${RemoveTags(Localize(item.details))}&si=${encodeURI(item?.picture?.[0] || item?.image || '')}`)
      break;
    default:
      link = (`https://${baseLink}.page.link/?link=${encoded}&apn=com.metadocapp&isi=6443656840&ibi=org.metadocapp&efr=1&utm_medium=referral&st=${Localize(item.name)} | ${brandName}&sd=${RemoveTags(Localize(item.details))}&si=${encodeURI(item?.picture?.[0] || item?.image || '')}`)
      break;
  }
  let results = await Axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${REACT_APP_GOOGLE_API}`, {
    'longDynamicLink': link,
    "suffix": {
      "option": "SHORT"
    },

  })
  console.log("results?.data?.shortLink", results?.data?.shortLink);
  return results?.data?.shortLink

}
export function appsFlyerLogEvent(eventkey, eventParams) {
  // appsFlyer.logEvent(eventkey, eventParams)
}

export function RateOnAppstore() {

}


export function InitializeSentry() {
  Sentry.init({
    ignoreErrors: [/(localhost|127\.0\.0\.1)/i],
    dsn: "https://e95d7f0e148f4b3a8ea2f72078cc59e6@o1430237.ingest.sentry.io/4503891099582464",
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export const WrapApp = (app) => {
  return app
}

export const TeleConsultation = (userData) => async (dispatch) => {
  let { md_token, uid } = userData;

  if (!md_token) {
    await dispatch(CreateMeetingdocUser(userData))
    md_token = uid
  }
  AuthorizeMeetingdocUser(md_token).then(({ JWT }) => {
    console.log("resultresultresultresult", JWT);
    window.meetingdoctors.initialize({
      apiKey: "d23872cf5a2726a2",
      jwt: JWT,
      displayMode: 'contained',
      containerId: 'MeetingDocContainer',
      language: "en",
      // pushToken: 'eZ0ohxmQMUmphMhQQ3lO0T:APA91bFcySwe5aDsdfItasWsbihz159IS1MmPqWZrxeFUY6J2-tj5bNtPfIDW3w7nbU5gYPaebnKEpyJlFN2c6TDk1wBfqMAs1xryWvAgGpTZicz66iHdS3CK3UE_s9jMdiHrKVPJUqi',
      defaultOpen: false
    })
  }).catch(error => {
    console.log("errorerrorerror", error);
  });

}