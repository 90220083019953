import { View } from 'native-base';
import React from 'react';
import LinearGradient from '../../../shared/LinearGradient';
import { shadow } from '../../../../helpers/helpers';
import Text from '../../../shared/TextLabel';

const CardComponent = (props) => {
    const { data } = props;
    let pendingPayment = data?.status === 'Pending Payment';
    let colors = pendingPayment ? ['#F5832F', '#F59D2F', '#F5B82F'] : ['#ffffff', '#ffffff', '#ffffff'];

    return (

        <LinearGradient start={{ x: 0.0, y: 0.25 }} end={{ x: 0.5, y: 1.0 }} style={{ flex: 1, borderRadius: 10, }} colors={colors}  >
            <View borderWidth={1} padding={4} borderColor={'transparent'} borderRadius={10} flexDirection='row' justifyContent={'space-between'} alignItems='center' >
                <View maxW={'70%'}>
                    <Text fontSize={16} fontFamily='semiBold' style={{  color: pendingPayment ? '#fff' : '#3B3B3B' }} >
                        {data?.title}
                    </Text>
                    {/* <Text  style={{ fontSize: 10, paddingTop: 4, color: pendingPayment ? '#fff' : '#3B3B3B' }}> */}
                        {data?.desc}
                    {/* </Text> */}
                </View>
                <View >
                    {props?.children}
                </View>
            </View>

        </LinearGradient >


    );
};

export default CardComponent;