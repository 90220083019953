import { createAction } from 'redux-action';
import moment from 'moment';
import { FormatAddress } from '../helpers/helpers';

export const UPDATE_ORDER_ITEMS = 'UPDATE_ORDER_ITEMS';
export const UPDATE_ORDER_LOCATION = 'UPDATE_ORDER_LOCATION';
export const UPDATE_ORDER_DATE = 'UPDATE_ORDER_DATE';
export const UPDATE_ORDER_CONTACT = 'UPDATE_ORDER_CONTACT';
export const FLUSH_ORDER = 'FLUSH_ORDER';
export const UPDATE_ORDER_DISCOUNT = 'UPDATE_ORDER_DISCOUNT';
export const UPDATE_ORDER_SERVICE = 'UPDATE_ORDER_SERVICE';

export const UpdateOrderItems = createAction(UPDATE_ORDER_ITEMS, (list) => {
    return list
});

export const SetOrderLocation = createAction(UPDATE_ORDER_LOCATION, (list) => {
    let post = {
        address: FormatAddress({ ...list, additional_directions: false }),
        latLng: list?.latLng ||
        {
            lat: list?.geometry?.location?.lat || list?.geometry?.lat || '--',
            lng: list?.geometry?.location?.lng || list?.geometry?.lng || '--'
        },
        id: list?.id || '--',
        place_id: list?.place_id || '--',
        cluster: list?.cluster || '--',
        area: list?.area || '--',
        street: list?.street || '--',
        label: list?.label || '--',
    }

    if (list?.villa) {
        post.villa = list?.villa
    }
    if (list?.flat) {
        post.flat = list?.flat
    }
    if (list?.building) {
        post.building = list?.building
    }

    return post
});
export const SetOrderService = createAction(UPDATE_ORDER_SERVICE, (list) => {
    return list
});
export const FlushOrder = createAction(FLUSH_ORDER, (list) => {
    return list
});
export const SetOrderDate = createAction(UPDATE_ORDER_DATE, (list) => {
    return list
});
export const SetOrderContact = createAction(UPDATE_ORDER_CONTACT, (list) => {
    return list
});
export const setOrderDiscount = createAction(UPDATE_ORDER_DISCOUNT, (list) => {
    return list
});

const initial = { items: {} };

let ex = (state = initial, action) => {
    switch (action.type) {
        case UPDATE_ORDER_ITEMS:
            let service_id = Object.values(action.payload)?.[0]?.service_id
            return { ...state, items: action.payload, service: { ...state.service, id: service_id } }
        // return { ...state, items: action.payload }
        case UPDATE_ORDER_SERVICE:
            return { ...state, service: action.payload }

        case UPDATE_ORDER_LOCATION:
            return { ...state, location: action.payload }
        case UPDATE_ORDER_DATE:
            let { selectedDate, selectedTime, notes } = action.payload;
            let optimizedDate = moment(moment(selectedDate?.dateTimestamp).format('yyyy-MM-DD') + ' ' + moment(selectedTime.start).format('HH:mm')).valueOf();
            return { ...state, date: { date: optimizedDate, time: selectedTime }, notes: notes, candidates: selectedDate?.candidates }
        case UPDATE_ORDER_CONTACT:
            return { ...state, contact: action.payload }
        case FLUSH_ORDER:
            return { items: {}, location: state.location }
        case UPDATE_ORDER_DISCOUNT:
            return { ...state, discount: action.payload }
        default:
            return state;
    }
};
export default ex