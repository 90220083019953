import { CardStyleInterpolators } from '@react-navigation/stack';
import React from 'react';
import NewAddress from "./index";
import AddressDetails from "./locationDetails";
import Header from '../../shared/Header';
import { strings } from '../../../translations/index';

const Navigator = (AddressStack) => {
    return (
        <AddressStack.Group
            screenOptions={{
                cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
            }}
        >

            <AddressStack.Screen name="AddNewLocation" component={NewAddress} options={{
                // cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
                header: () => <Header title={strings?.['Pick current location']} />,
            }} />
            <AddressStack.Screen name="Address Details" component={AddressDetails} options={{
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                header: () => <Header title={strings?.['Address Details']} />,
            }} />
        </AddressStack.Group>


    );
};

export default Navigator;