import { Center, Flex, View, Image, Row, Pressable } from 'native-base';
import React, { useEffect, useState } from 'react';
import CalenderImg from '../../../../assets/complete.png'
import Text from '../../../shared/TextLabel';
import CustomImg from '../../../shared/customImg'
import { StarOutlineSVG, StarSVG } from '../../../../constants/imgs';
import { useSelector } from 'react-redux';
import { strings } from '../../../../translations/index';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { firestore } from '../../../../constants/firebase/config';
import { useNavigation } from '@react-navigation/native';
const Rating = ({ route }) => {
    const { order_id } = route.params || {}
    const [activeOrder, setActiveOrder] = useState({});
    const { orders } = useSelector(state => state?.user)
    const navigator = useNavigation()
    const [state, setState] = useState(4);
    useEffect(() => {
        if (orders?.length && orders?.find(r => r.id === order_id)) {
            setActiveOrder(orders?.find(r => r.id === order_id))
        }
    }, [order_id])

    return (
        <Flex flex={1} bgColor='#20A2DF'>
            <Center flex={0.4} paddingTop={20}>
                <Image
                    alt='calender'
                    flex={1}
                    style={{ width: '50%' }}
                    resizeMode='contain'
                    source={CalenderImg}
                />

            </Center>
            <Flex flex={0.5} alignItems='center' justifyContent={'space-between'}>
                <Center>
                    <Text fontFamily='bold' fontSize={24} style={{ color: '#fff' }}>
                        {strings?.["Request Completed"]}
                    </Text>
                    <Row padding={4}>
                        {
                            [1, 2, 3, 4].map(r => <View key={r} style={{ width: 50, margin: 2, height: 8, borderRadius: 20, backgroundColor: '#97E09C' }} />)
                        }
                    </Row>
                    <Text style={{ color: '#fff' }}>
                        {strings?.["How was your booking experience"]}
                    </Text>
                    <Text style={{ color: '#fff' }}>
                        {strings?.["Please rate below"]}
                    </Text>
                </Center>

                <View w={'80%'} paddingTop={10}>
                    <Text style={{ color: '#fff', fontSize: 12 }}>
                        {strings?.["Rate Your experience"]}
                    </Text>
                    <Row borderColor={'#fff'} justifyContent='center' borderWidth={2} borderRadius={8} padding={2} mt={1}>
                        {
                            [1, 2, 3, 4, 5].map(r => <Pressable
                                onPress={() => {
                                    setState(r)
                                }}
                                paddingX={2} paddingY={1} key={r}>
                                <CustomImg
                                    svg
                                    width={40}
                                    height={40}
                                    fill={'#E0CF97'}
                                    style={{ width: 40, height: 40 }}
                                    uri={state >= r ? StarSVG : StarOutlineSVG}
                                />
                            </Pressable>)
                        }

                    </Row>
                    <Text textAlign='center' style={{ color: '#fff', fontSize: 12, paddingTop: 8 }}>
                        {strings?.["This will help us improve our services"]}


                    </Text>
                </View>
                <TouchableOpacity onPress={async () => {
                    try {
                        await firestore().collection('orders').doc(activeOrder?.id).update({
                            status: 'rated',
                            rate: state
                        })
                    } catch (error) {
                        console.log("errorerror", error);
                    }
                    navigator.goBack()
                }}>
                    <Text color='#fff' fontFamily='bold' fontSize={18}>Submit</Text>
                </TouchableOpacity>
                {/* <Center >
                    <Text textAlign='center' style={{ color: '#fff', fontSize: 12, paddingTop: 8 }}>
                        {strings?.["Having any inquiry"]}

                        <Text style={{ color: '#fff', textDecoration: 'underline' }} fontFamily='bold'>  {strings?.["contact us"]} </Text>
                    </Text>
                    <Text textAlign='center' style={{ color: '#fff', fontSize: 12, paddingTop: 8 }}>
                        {strings?.["Order ID:"]}
                        <Text style={{ color: '#fff', textDecoration: 'underline' }} fontFamily='bold'>{activeOrder?.orderNumber}</Text>
                    </Text>

                </Center> */}

            </Flex>

        </Flex>
    );
};

export default Rating;