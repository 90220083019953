import React from 'react';

import { createStackNavigator } from "@react-navigation/stack";
import Main from './main';
import Header from '../screens/OrderStatus/com/Header';
import AddCard from './CardPayment'

const PaymentStack = createStackNavigator();
const PaymentNavigator = ({ route }) => {
    return (
        <PaymentStack.Navigator
            initialRouteName="order"
            screenOptions={{
                headerShown: true,
                cardStyle: { backgroundColor: '#F5F7F8' }
            }}>
            <PaymentStack.Screen name="order" component={Main} initialParams={route?.params} options={{
                header: () => <Header title='Payment' />,
            }} />
            <PaymentStack.Screen name="addCard" component={AddCard} options={{
                header: () => <Header title='Add Card' />,
            }} />

        </PaymentStack.Navigator>
    )
}

export default PaymentNavigator;
