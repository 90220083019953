import React from 'react';

import { CardStyleInterpolators, createStackNavigator } from "@react-navigation/stack";
import Main from './index';
import Header from './com/Header';
import DateScreen from '../Booking/com/DateScreen';
import Rating from './com/Rating';
const CheckoutStack = createStackNavigator();
const CheckoutNavigator = ({ route }) => {
    return (
        <CheckoutStack.Navigator
            initialRouteName="order"
            screenOptions={{
                headerShown: true,
                cardStyle: { backgroundColor: '#F5F7F8' }
            }}>
            <CheckoutStack.Screen name="order" initialParams={route?.params} component={Main}
                options={{
                    header: () => null,
                }}
            />
            <CheckoutStack.Screen name="Reschedule" initialParams={route?.params} component={DateScreen}
                options={{
                    header: () => <Header title='Reschedule Request' />,
                }}
            />

            <CheckoutStack.Screen name="Rating" component={Rating}
                options={{
                    header: () => null,
                    cardStyleInterpolator: CardStyleInterpolators.forBottomSheetAndroid,

                }}
            />
        </CheckoutStack.Navigator>
    )
}

export default CheckoutNavigator;
