import PharmacyCardList from '../PharmacyCardList';
import CardList from "../CardList";
import { FixedSizeList } from 'react-window';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { View } from 'native-base';
import { useRef } from 'react';

const LongList = ({ allItems, navigation, serviceID }) => {
    const scrolled = useRef(false)
    const { height, width } = useWindowDimensions()
    const RenderRow = ({ index, style }) => {
        const item = allItems[index];
        return (
            <View key={index} style={style} paddingTop={2} paddingX={4}>
                {
                    serviceID === 'pharmacy' ?
                        <PharmacyCardList data={{ ...item, id: item?.sku, service_id: serviceID }} navigation={navigation} />
                        :
                        <CardList data={item} />
                }
            </View>
        );
    };

    return (
        <FixedSizeList
            style={{ paddingTop: 10, paddingBottom: 200 }}
            onScroll={(ss) => {
                if (ss.scrollOffset === 0 && ss.scrollDirection === 'backward') {
                    scrolled.current = false
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                } else {
                    if (!scrolled.current) {
                        window.scrollTo({
                            top: 177,
                            left: 0,
                            behavior: 'smooth'
                        })
                    }
                    scrolled.current = true
                }
            }}
            height={height - 177}
            width={width > 900 ? width * 0.3 : width}
            itemCount={allItems?.length}
            itemSize={serviceID === 'pharmacy' ? 120 : 135}
        >
            {RenderRow}
        </FixedSizeList>

    );
}
export default LongList;