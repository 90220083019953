import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = (props) => {
    return (
        <DatePicker {...props} />
    );
};

export default Datepicker;