import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneNumber = (props) => {
    return (
        <PhoneInput

            inputStyle={{
                height: 55,
                borderColor: '#E9F0F7',
                paddingLeft: 58,
                borderRadius: 8,
                backgroundColor:'#E9F0F7',
                width: '100%',
            }}
            // isValid={(inputNumber, country, countries) => {
            //     console.log("valuevalue,",inputNumber,country);
            //     // if (value.match(/12345/)) {
            //     //   return 'Invalid value: '+value+', '+country.name;
            //     // } else if (value.match(/1234/)) {
            //     //   return false;
            //     // } else {
            //     //   return true;
            //     // }
            //   }}
            autoFormat={false}
            buttonStyle={{
                borderColor: '#E9F0F7',
                backgroundColor: '#E9F0F7',
                borderRightColor: '#EEF3F6',
                minWidth: 50,
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                margin: 0,
                padding: 4
            }}
            dropdownStyle={{
                zIndex: 999,
                borderRadius: 8,

            }}
            containerStyle={{
                borderRadius: 8,
                borderColor: '#E9F0F7',
                backgroundColor: '#E9F0F7'
            }} {...props}
        />
    );
};
export default PhoneNumber;