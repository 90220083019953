export default {
    "morning": "Утро",
    "afternoon": "День",
    "evening": "Вечер",
    "dismiss": "закрыть",
    "searchPlace": "Поиск места",
    "confirmLocation": "Подтвердить местоположение",
    "yourName": "Ваше имя",
    "yourNumber": "Ваш номер",
    "yourEmail": "Ваш адрес электронной почты",
    "area": "Область",
    "street": "Улица",
    "buildingName": "Название здания",
    "floor": "Этаж",
    "flat": "Квартира",
    "villa": 'Вилла',
    "instruction": "Инструкции",
    "nickName": 'Ник',
    "Home": "Дом",
    "Work": "Работа",
    "Other": "Другой",
    "saveAddress": "Сохранить адрес",
    "Bookings": "Бронирование",
    "Locations": "Места",
    "Payment Methods": "Способы оплаты",
    "Metrics": "Критерии",
    "Logout": "Выйти",
    "Login": "Войти",
    "Staff login": "Вход для персонала",
    "Upcoming": "Предстоящий",
    "All": "Все",
    "Completed": "Завершенный",
    "Failed": "Не удалось",
    "Placed On": "Размещены на",
    "PENDING PAYMENT": "ОЖИДАЕТ ОПЛАТЫ",
    "Pending Payment": "Ожидает оплаты",
    "payment link is sent for your guest and we are awaiting payment to continue": "ссылка для оплаты отправлена для вашего гостя, и мы ожидаем оплаты, чтобы продолжить",
    "Your booking is received": "Ваше бронирование получено",
    "Confirmed": "Подтверждено",
    "We will match you with top-rated professionals in your area": "Мы подберем для вас лучших специалистов в вашей области",
    "Service provider": "Поставщик услуг",
    "Assigned": "Назначенный",
    "In Progress": "В ходе выполнения",
    "On the way": "В пути",
    "Session started": "Сеанс начался",
    "Your order is Completed": "Ваш заказ выполнен",
    "Your request has been completed successfully": "Ваш запрос успешно выполнен",
    "Your booking is Canceled": "Ваше бронирование отменено",
    "Canceled": "Отменено",
    "Your booking is Rejected": "Ваше бронирование отклонено",
    "Rejected": "Отклонено",
    "Flat": "Квартира",
    "Room": "Номер",
    "Call us": "Позвонить нам",
    'Reach us': 'Связаться с нами',
    "Whatsapp": "WhatsApp",
    "English": "Английский",
    "Arabic": "Арабский",
    "Warning": "Предупреждение",
    "Are you sure ,This locations will be deleted permanently": "Вы уверены, что эти местоположения будут удалены навсегда?",
    "Delete": "Удалить",
    "Are you sure ,This card will be deleted permanently": "Вы уверены, что эта карта будет удалена навсегда?",
    "Profile": "Профиль",
    "Order History": "История заказов",
    "Language": "Язык",
    "Contact Us": "Свяжитесь с нами",
    "Services": "Услуги",
    "AED": "дирхам ОАЭ",
    "Subtotal": "Промежуточный итог",
    "Discount": "Скидка",
    "DiscountCode": "Скидка",
    "VAT": "НДС",
    "Total": "Всего",
    "View items": "Просмотр элементов",
    "Continue": "Продолжить",
    "Pay now": "Заплатить сейчас",
    "Share payment link": "Поделитесь ссылкой на оплату",
    "Schedule Appointment": "Запланировать встречу",
    "Step": "Шаг",
    "out of": "снаружи",
    "Welcome": "Добро пожаловать",
    "Phone verification": "Проверка телефона",
    "Please enter your mobile number to login": "Пожалуйста, введите свой номер мобильного телефона для входа",
    "Enter your OTP code here": "Введите здесь свой OTP-код",
    "This site is protected by reCAPTCHA and the Google": "Этот сайт защищен reCAPTCHA и Google",
    "Privacy Policy": "Политика конфиденциальности",
    "and": "и",
    "Terms of Service": "Условия обслуживания",
    "apply": "подать заявление",
    "Reschedule Request": "Запрос на перенос",
    "RescadualMsg": "Ваш запрос будет перенесен на {0} в {1}.",
    "Reschedule": "Перенести",
    "Select preferred Date and Time": "Выберите желаемую дату и время",
    "Do you have any specific instructions?": "У вас есть какие-то конкретные инструкции?",
    "Incomplete data": "Неполные данные",
    "Please select date and time to proceed": "Пожалуйста, выберите дату и время, чтобы продолжить",
    "Please select Date first": "Сначала выберите дату",
    "Contact Person Name": "Имя контактного лица",
    "Room Number": "Номер комнаты",
    "Building": "Строительство",
    "Your Customer will receive status updates": "Ваш клиент получит обновления статуса",
    "Contact Person Number": "Номер контактного лица",
    "Email": "Эл. адрес",
    "Customer information": "Информация для покупателей",
    "Please fill all required fields": "Пожалуйста, заполните все обязательные поля",
    "Ask the customer to pay by sharing the payment link with them with auto generated payment link": "Попросите клиента заплатить, поделившись с ним ссылкой для оплаты с автоматически сгенерированной ссылкой для оплаты.",
    "Complete the payment on behalf of the customer using the online payment gateway": "Завершите платеж от имени клиента, используя шлюз онлайн-платежей.",
    "Auto-generate payment link can be shared with anyone to pay on your behalf": "Автоматически сгенерированной платежной ссылкой можно поделиться с кем угодно, кто заплатит от вашего имени.",
    "Complete the payment using the online payment gateway": "Завершите платеж, используя шлюз онлайн-платежей",
    "Payment Options": "Варианты оплаты",
    "Payment Details": 'Детали платежа',

    "Please check entered Phone Number in previous step": "Пожалуйста, проверьте введенный номер телефона на предыдущем шаге",
    "Please try again later or contact us": "Повторите попытку позже или свяжитесь с нами.",
    "Where do you want to have the service": "Где вы хотите получить услугу",
    "+ Add new address": "+ Добавить новый адрес",
    "Please select your address": "Пожалуйста, выберите ваш адрес",
    "Details": "Подробности",
    "You need to complete the payment in order to process your request": "Вам необходимо завершить платеж, чтобы обработать ваш запрос",
    "Order Status": "Статус заказа",
    "Order Details": "Информация для заказа",
    "Pay Now": "Заплатить сейчас",
    "Order ID": "Номер заказа",
    "Customer Name": "Имя Клиента",
    "Customer Number": "Номер клиента",
    "Service": "Услуга",
    "Service Details": "Сведения об услуге",
    "Service Provider": "Поставщик услуг",
    "Instructions": "Инструкции",
    "Address": "Адрес",
    "Flat number:": "Номер квартиры:",
    "Room number:": "Номер комнаты:",
    "Date & Time": "Дата и время",
    "Payment details": "Детали платежа",
    "Cancel": "Отмена",
    "Cancel Request?": "Отменить запрос?",
    "Are you sure you want to cancel this request?": "Вы уверены, что хотите отменить этот запрос?",
    "Cancel Request": "Отменить запрос",
    "Are you sure you want to send this request?": "Вы уверены, что хотите отправить этот запрос?",
    "Book Now": "Забронируйте сейчас",
    "Coming soon!": "Скоро!",
    "Book": "Книга",
    "Select time": "Выберите время",
    "Payment Status": "Статус платежа",
    "Overview": "Обзор",
    "Collect Payment Via QR Code": "Получить платеж с помощью QR-кода",
    "OK": "ОК",
    "Ask customer to scan for Payment": "Попросите клиента отсканировать для оплаты",
    "Paid": "Оплачено",
    "Not Paid (To be collected)": "Не оплачено (забрать)",
    "Pending": "Ожидает",
    "Payment": "Оплата",
    "Add Card": "Добавить карту",
    "Card": "Карта",
    "Apple Pay / Google Pay": 'Apple Pay / Google Pay',
    "Apple Pay": 'Apple Pay',
    "Google Pay": 'Google Pay',
    "Pay by Card": "Оплатить картой",
    "Promocode Applied": "Промокод применен",
    "Payment options": "Варианты оплаты",
    "Apply": "Подать заявку",
    "+ Add new card": "+ Добавить новую карту",
    "Having a promo code?": "Есть промокод?",
    "Card number": "Номер карты",
    "Expiration date": "Срок годности",
    "No Available Slots": "Нет доступных слотов",
    "Please check different date to see availability": "Пожалуйста, проверьте другую дату, чтобы увидеть наличие свободных мест",
    "No appointments": "Нет встреч",
    "No Saved Locations": "Нет сохраненных местоположений",
    "Place an order to add your location": "Разместите заказ, чтобы добавить свое местоположение",
    "No Saved Cards": "Нет сохраненных карт",
    "Place an order to add your Card": "Оформить заказ на добавление карты",
    "Available Services": 'Доступные услуги',
    "My Profile": 'Мой профиль',
    "Refer friends & family": 'Пригласить друзей и семью',
    "Add": 'Добавить',
    "Similar services": 'Похожие услуги',
    "View all": 'Посмотреть все',
    "Description": 'Описание',
    "Report Delay": 'Сообщить о задержке',
    "Rate Booking experience": 'Оцените опыт бронирования',
    "PromoCode": 'Промо-код',
    "Pharmacy": 'Аптека',
    "Cart": 'Корзина',
    "Caution": 'Осторожно',
    "Hi there": "Привет",
    "Confirm": 'Подтвердить',
    "Refer Now": 'Обратиться сейчас',
    "Add new Location": "Добавить новое местоположение",
    "Select": 'Выбрать',
    "Sorry": 'Простите',
    "Select Location": 'Выберите местоположение',
    "Pick location on map": 'Выберите место на карте',
    "Add a location from the map": "Добавить место с карты",
    'Check Next Date': 'Проверить следующую дату',
    "Location": 'Местоположение',
    "Personal info": 'Личная информация',
    "Total Credit": 'Общий кредит',
    "Expiry date:": 'Дата истечения срока:',
    "referFriend": 'Приведи друга',
    "yourFriendWillGet": 'Ваши друзья получат',
    "No Records Found": 'Записи не найдены',
    "getUpTo": 'Получите до',
    "InviteYourFriends": 'Пригласите своих друзей',
    "One more thing!": 'Еще кое-что!',
    'youWillReceiveExtra': 'Вы получите дополнительно',
    "Invite Friends": 'Пригласить друзей',
    "Search": 'Поиск',
    "No results found": 'Результаты не найдены',
    "We couldn’t find what you are looking for. please try again": 'Мы не смогли найти то, что вы ищете. Пожалуйста, попробуйте снова',
    "Recent search": 'Недавний поиск',
    "Appointments": 'Встречи',
    "Explore Services": 'Исследуйте услуги',
    'No bookings': 'Бронирования нет',
    "booking scheduled": "Бронирование запланировано",
    "Terms of Use": 'Условия пользования',
    "I have read and agree to the Metadoc": 'Я прочитал и согласен с Metadoc',
    "Pick current location": 'Выберите текущее местоположение',
    "Address Details": 'Подробный адрес',
    "Congratulations": "Поздравления",
    "Sorry!": 'Простите!',
    "Update Required": "Требуется обновление",
    "Update": 'Обновить',
    "New Version": "Новая версия",

    "Signup": 'Подписаться',
    "Your Gift is waiting": 'Ваш подарок ждет',
    "Gift Received": 'Подарок получен',
    "Credit already received!": 'Кредит уже получен!',
    "Get more credit": 'Получите больше кредитов',
    "You have already received your invitation gift, but you can still get more reward credit by inviting friends.": 'Вы уже получили свой подарок-приглашение, но вы все еще можете получить больше вознаграждения, пригласив друзей.',
    "This link is yours!": 'Эта ссылка ваша!',
    "Share this link with your friends and family to get even more free credit in your wallet": 'Поделитесь этой ссылкой с друзьями и семьей, чтобы получить еще больше бесплатных кредитов в своем кошельке.',
    "Explore services": 'Изучите услуги',
    "Your friend {0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!": "Ваш друг {0} пригласил вас присоединиться к Metadoc. В вашем бумажнике есть {1} дирхамов ОАЭ. Войти Сейчас!",
    "{0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!": "{0} приглашает вас присоединиться к Metadoc. В вашем бумажнике есть {1} дирхамов ОАЭ. Войти Сейчас!",
    "We apologize for any inconvenience, Some of selected services are currently not served in your area at the moment.": "Приносим извинения за неудобства. Некоторые из выбранных услуг в настоящее время не предоставляются в вашем регионе.",
    "Unfortunately, the selected service is currently unavailable in your area":"Приносим извинения за неудобства. Некоторые из выбранных услуг в настоящее время не предоставляются в вашем регионе.",
    'Wallet': 'Кошелек',
    'Saved Location detected': 'Обнаружено сохраненное местоположение',
    'Use saved location': 'Использовать сохраненное местоположение',
    'Add New Location': 'Добавить новое местоположение',
    'Got it': 'Понятно',
    "yourProfessional": "Ваш профессионал",
    'History': 'История',
    "Referral Points": "Реферальные баллы",
    "Friend Order Reward": "Награда за заказ друга",
    "Signup Reward": "Награда за регистрацию",
    "Friend Signup": "Регистрация друга",
    "Use wallet credit": 'Использовать кредит кошелька',
    "View Details": 'Посмотреть детали',
    "Phone Number": 'Номер телефона',
    "ValidTill": "Действителен до",
    "Redeem Code": 'Активировать код',
    "You have already enrolled in this program": 'Вы уже зарегистрированы в этой программе',
    "Please enter valid promocode!": 'Пожалуйста, введите действующий промокод!',
    "Promocode already used": 'Промокод уже использован',
    "Promocode expired!": 'Срок действия промокода истек!',
    "You exceeded maximum number of redemption!": 'Вы превысили максимальное количество погашений!',
    "Promocode is not applied on selected services or variations!": 'Промокод не применяется к выбранным услугам или вариантам!',
    "Error while processing your request!": 'Ошибка при обработке вашего запроса!',
    "Please enter your contact details": 'Пожалуйста, введите ваши контактные данные',
    "Alert": 'Внимание',
    "Request Completed": 'Запрос выполнен',
    "How was your booking experience": 'Как прошел ваш опыт бронирования',
    "Please rate below": 'Пожалуйста, оцените ниже',
    "Rate Your experience": 'Оцените свой опыт',
    "This will help us improve our services": 'Это поможет нам улучшить наши услуги',
    "Having any inquiry": 'Если остались вопросы',
    "contact us": 'свяжитесь с нами',
    "Order ID:": 'Номер заказа:',
    "Refund": 'Возврат',
    "Similar Products": 'Похожие продукты',
    'We are not currently serving this area, but we are expanding very fast, check us later!': 'В настоящее время мы не обслуживаем этот регион, но мы очень быстро расширяемся, проверьте нас позже!',
    'We apologize for any inconvenience, but we are not serving your area at the moment!': 'В данный момент мы не обслуживаем этот регион!',
    "Ohh Sorry!": 'О, простите!',
    "Hi": 'Привет',
    'Book now': 'Заказать сейчас',
    "Subscriptions": 'Подписки',
    "Upgrade to Pro": 'Обновить до Про',
    "Subscribe": 'Подписаться',
    "Settle Now": 'Урегулировать сейчас',
    "Contact Info": 'Контактная информация',
    "Profile info": 'Информация о профиле',
    "This service require pro subscription, Subscribe now to access this service": 'Для этой услуги требуется профессиональная подписка. Подпишитесь сейчас, чтобы получить доступ к этой услуге.',
    "/per Month": '/в месяц',
    "Days Trial": 'Пробная версия',
    "Subscribed": 'Подписан',
    "Congrats, You are successfully subscribed to Metadoc Plus": 'Поздравляем, Вы успешно подписались на Метадок Плюс',
    "Manage Subscriptions": 'Управление подписками',
    "Invoices": 'Счета',
    "Subscription": 'Подписка',
    "Cancel Subscription": 'Отменить подписку',
    "Renewal date:": 'Дата обновления:',
    "Why you are cancelling": 'Почему вы отменяете',
    "Later": 'Позже',
    "Know more": 'Узнать больше',
    "Current plan": 'Текущий план',
    "New": 'New',
    "General information": 'General information',
    "Full Name": 'Full Name',
    "Date of birth": 'Date of birth',
    "Gender": 'Gender',
    "male": 'male',
    "female": 'female',
    "We apologize for any inconvenience, Please Contact us to process your request": 'We apologize for any inconvenience, Please Contact us to process your request',
    "See all": 'See all',
    "Severity:": 'Severity:',
    "Start Date:": 'Start Date:',
    "Diagnosis date:": 'Diagnosis date:',
    "Height:": 'Height:',
    "Weight:": 'Weight:',

















};


