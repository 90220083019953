import { Button, Modal, View } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { shadow } from '../../../../helpers/helpers';
import Text from '../../../shared/TextLabel';
import { strings } from '../../../../translations/index';

const ActionsSheet = ({ onConfirm, onCancel, type, btnTitle = 'Reschedule', color, disabled, pharmacy = false }) => {
    return (
        <View
            position={Platform.OS === 'web' ? 'sticky' : 'absolute'}
            bottom={0}
            w={'100%'}
            backgroundColor='#fff'
            shadow={shadow({ color: '#00000030', radius: 4, offsetWidth: 0, offsetHeight: 0 })}
            paddingX={4}
            paddingY={6}
            justifyContent='space-between'
            borderTopRadius={8}>
            {
                pharmacy ?
                    <Button onPress={onCancel} variant={'outline'} borderRadius={8} borderColor='danger.600' height={50} colorScheme='danger'>
                        <Text style={{ color: 'red' }}> {strings?.Cancel}</Text>
                    </Button>
                    : type === 'single' ?
                        <Button disabled={disabled} style={{ opacity: disabled ? 0.5 : 1 }} onPress={onConfirm} backgroundColor={color} height={50} borderRadius={8}>
                            <Text style={{ color: '#ffffff' }}>{strings?.[btnTitle]}</Text>

                        </Button>
                        :
                        <View flexDirection={'row'} justifyContent='space-between' alignItems={'center'}>
                            <Button onPress={onCancel} width={'30%'} variant={'outline'} borderRadius={8} height={50} colorScheme='danger'>
                                <Text style={{ color: 'red' }}> {strings?.Cancel}</Text>
                            </Button>
                            <Button disabled={disabled} style={{ opacity: !!disabled ? 0.5 : 1 }} onPress={onConfirm} width={'65%'} height={50} borderRadius={8}>
                                <Text style={{ color: '#ffffff' }}>{strings?.[btnTitle]} </Text>
                            </Button>
                        </View>
            }
        </View>
    );
};

export default ActionsSheet;