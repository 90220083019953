/**
 * @format
 */

import { AppRegistry, Platform } from 'react-native';
import App from './App';
import './index.css'



AppRegistry.registerComponent('metadoc', () => App);
AppRegistry.runApplication('metadoc', {
  rootTag: document.getElementById('root'),
});