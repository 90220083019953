import { Button, Card, Flex, InputGroup, InputRightAddon, Pressable, ScrollView, useToast, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import Input from '../../../shared/CustomInput'

import { useDispatch, useSelector } from 'react-redux';
import { ApplePaySVG, EmptyDot, FilledDot, CardPaymentSVG, Visa, MasterCard, CloseBtn, SharePaymentSVG, CashPaymentSVG } from '../../../../constants/imgs';
import Text from '../../../shared/TextLabel';
import Image from '../../../shared/customImg'
import OrderSummarySheet from '../../../shared/OrderSummarySheet';
import { applyPromocode, calcTotalCost, placeOrder, PlaceOrderAfterConfirm, shadow } from '../../../../helpers/helpers';
import CardOptionComp from '../../../shared/CardOptionComp';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { setOrderDiscount } from '../../../../reducers/order';
import ReceiptComp from './ReceiptComp';
import { strings } from '../../../../translations/index';
import { SetAlertMessage } from '../../../../reducers/settings';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);


const OptionComp = ({ selected, data }) => {

    return (
        <View
            paddingY={1}
            flexDirection='row'
            alignItems='center'
        >
            <View>
                <Image
                    svg
                    fill='#20A2DF'
                    uri={selected ? FilledDot : EmptyDot}
                    width={15}
                    height={15}
                />

            </View>
            <View paddingX={3} flexDirection='row' alignItems={'center'}>
                <Image
                    svg
                    fill='#20A2DF'
                    uri={data?.img}
                    width={20}
                    height={20}
                />
                <Text style={{ fontSize: 14, paddingHorizontal: 8 }}>
                    {strings?.[data.title]}
                </Text>
            </View>
        </View>
    )
}
const PaymentType = ({ navigation }) => {
    let order = useSelector(state => state.order);
    let { brand, brandLoyalty, webView, cashEnabled } = useSelector(state => state.settings);
    let { cards } = useSelector(state => state.user);
    const stripe = useStripe();
    const dispatch = useDispatch()
    const { locale } = useSelector(state => state.locale)
    const Toast = useToast();
    const [loading, toggleLoading] = useState(false);
    const [useWallet, setUseWallet] = useState(true)

    const user = useSelector(state => state.user)
    const { uid, role, stripeId } = user;
    const initialMenu = [
        {
            key: 'wallet',
            title: 'Apple Pay / Google Pay',
            img: ApplePaySVG
        },
        {
            key: 'card',
            title: 'Card',
            img: CardPaymentSVG
        },
        {
            key: 'guest',
            title: 'Share payment link',
            img: SharePaymentSVG
        }
    ];

    const [types, setTypes] = useState(webView ? initialMenu?.filter(r => !['guest', 'cash'].includes(r.key)) : initialMenu);

    useEffect(() => {
        if (cashEnabled) {
            let options = types.filter(r => r.key !== 'cash')
            setTypes([...options, {
                key: 'cash',
                title: 'Cash',
                img: CashPaymentSVG
            }])
        }
    }, [cashEnabled]);
    const [state, setState] = useState(initialMenu);

    return (
        <View flex={1}>
            <ScrollView
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ paddingHorizontal: 16, paddingBottom: 180 }} >
                <Flex>
                    <Text fontFamily='semiBold' paddingTop={8} >
                        {strings?.["Payment Details"]}
                    </Text>
                    <View paddingTop={4}>
                        <ReceiptComp data={order} updateUseWallet={(e) => setUseWallet(e)} />
                    </View>
                </Flex>
                <Flex>
                    <Text fontFamily='semiBold' paddingTop={8} >
                        {strings?.['PromoCode']}
                    </Text>
                    <View paddingTop={4}>
                        {
                            order?.discount ?
                                <View key={'loyaltyDiscount'}>

                                    <View height={50}
                                        borderRadius={8}
                                        backgroundColor={'#20A2DF30'}
                                        justifyContent='space-between'
                                        padding={4}
                                        alignItems='center'
                                        flexDirection='row'
                                        shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })}>
                                        <Text style={{ color: '#20A2DF' }}>
                                            <Text fontFamily='bold' style={{ color: '#20A2DF' }} >{order?.discount?.code}</Text>
                                        </Text>
                                        <Pressable
                                            onPress={() => dispatch(setOrderDiscount(false))}
                                            style={{
                                                // position: 'absolute',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: 13,
                                                // right: -10,
                                                // top: -10,
                                                width: 26,
                                                height: 26,
                                                backgroundColor: '#20A2DF',
                                                zIndex: 999
                                            }}>
                                            <Image
                                                svg
                                                fill='#fff'
                                                width={10}
                                                height={10}
                                                uri={CloseBtn}
                                            />
                                        </Pressable>
                                    </View>

                                </View>

                                :
                                <Input
                                    _focus={
                                        { borderColor: 'transparent' }
                                    }
                                    textAlign={locale === 'ar' ? 'right' : 'left'}
                                    w='100%'
                                    // style={{ boxShadow: 'none' }}
                                    backgroundColor={'#fff'}
                                    borderColor='#fff'
                                    height={50}
                                    placeholder={strings?.['Having a promo code?']}
                                    onChange={(e) => setState({ ...state, promoCode: e.nativeEvent.text })}
                                    InputRightElement={<Button
                                        _spinner={{
                                            color: '#2F8AF4'
                                        }}
                                        backgroundColor={'#fff'}

                                        isLoading={loading === 'promo'}
                                        onPress={async () => {
                                            toggleLoading('promo')
                                            let res = await applyPromocode({ code: state.promoCode, orderData: { brand, receipt: dispatch(calcTotalCost(order?.items, false, false, false)), customer_id: uid, service: order?.service, }, preOrder: true })

                                            switch (res.status) {
                                                case 'error':
                                                    toggleLoading(false)
                                                    return Toast.show({
                                                        placement: 'top',
                                                        title: 'Error',
                                                        status: 'error',
                                                        description: res.msg
                                                    })
                                                case 'affiliate':
                                                    toggleLoading(false)
                                                    return dispatch(SetAlertMessage({
                                                        title: 'Alert',
                                                        msg: strings?.['You have already received your invitation gift, but you can still get more reward credit by inviting friends.'],
                                                        cancelText: 'dismiss',
                                                        alert: true,
                                                    }))
                                                default:
                                                    break;

                                            }

                                            dispatch(setOrderDiscount(res))
                                            toggleLoading(false)

                                        }} >
                                        <Text fontFamily='semiBold' style={{ color: '#32A2DC' }} > {strings?.["Apply"]}</Text>
                                    </Button>}
                                />

                        }
                    </View>
                </Flex>
                {
                    <Flex>
                        <Text paddingTop={8} fontFamily='semiBold' >
                            {strings?.["Payment Options"]}
                        </Text>
                        <View paddingTop={4}>
                            {
                                types?.map(rec =>
                                    <View key={rec.key}>
                                        <Pressable paddingBottom={4} key={rec.key} onPress={() => setState({ ...state, selectedOption: rec, selectedCard: false })}>
                                            <OptionComp
                                                key={rec.key}
                                                data={rec}
                                                selected={state?.selectedOption?.key === rec.key} />
                                        </Pressable>
                                        {
                                            state?.selectedOption?.key === 'card' && rec.key === 'card' &&
                                            <View>
                                                {
                                                    cards?.map(card => <Pressable
                                                        paddingBottom={4}
                                                        key={card.id}

                                                        onPress={() => setState({ ...state, selectedCard: card.id })}>
                                                        <CardOptionComp
                                                            card={true}
                                                            backgroundColor='#ffffff'
                                                            key={card.id}
                                                            data={{ title: `XXXX ${card?.card?.last4}`, image: card?.card?.brand === 'visa' ? Visa : MasterCard }}
                                                            selected={state?.selectedCard === card.id} />
                                                    </Pressable>)

                                                }
                                                <Pressable style={{ marginBottom: 8 }} onPress={() => navigation.navigate('Payment', { screen: 'addCard' })}>
                                                    <Text style={{ color: '#20A2DF' }}>{strings?.['+ Add new card']}</Text>
                                                </Pressable>
                                            </View>

                                        }
                                        {
                                            state?.selectedOption?.key === 'guest' && rec.key === 'guest' &&
                                            <View backgroundColor='#F5C34210' paddingY={2} borderRadius={8}>
                                                <Text style={{ color: '#F5C342', fontSize: 10, textAlign: 'center' }}>Booking will be confirmed after payment is completed</Text>
                                            </View>

                                        }
                                    </View>
                                )
                            }
                        </View>

                    </Flex>
                }

            </ScrollView>
            <OrderSummarySheet
                bottom={88}
                useWallet={useWallet}
                walletActive={state?.selectedOption?.key === 'wallet'}
                fixed={true}
                hideExtras={false}
                walletPayNow={async (id) => {
                    try {
                        let placedOrder = await dispatch(placeOrder({ ...order, brand, customer_id: uid, role: role, stripeId, payment_method: id, user, brandLoyalty, paymentType: state?.selectedOption?.key }));
                        const { error: confirmError } = await stripe.confirmCardPayment(
                            placedOrder?.paymentIntent?.id,
                            { payment_method: id },
                            { handleActions: false }
                        );
                        if (confirmError) {
                            // Report to the browser that the payment failed, prompting it to
                            // re-show the payment interface, or show an error message and close
                            // the payment interface.
                            if (confirmError?.code === 'testmode_charges_only') {
                                return alert('Online payment temporarily unavailable. Please use cash payment. Apologies for the inconvenience.')
                            }
                            return alert(confirmError?.message)
                        } else {
                            if (["requires_action"].includes(placedOrder.paymentIntent.status)) {
                                const { error } = await stripe.confirmCardPayment(placedOrder?.paymentIntent?.id);
                                if (error) {
                                    toggleLoading(false)
                                    if (error?.code === 'testmode_charges_only') {
                                        return alert('Online payment temporarily unavailable. Please use cash payment. Apologies for the inconvenience.')
                                    }
                                    return alert(error?.message)
                                }
                            }
                            await dispatch(PlaceOrderAfterConfirm({ ...placedOrder, user }))

                            const nav = navigation.reset({
                                index: 0,
                                type: 'stack',
                                routes: [
                                    { name: 'Home' },
                                    { name: "Menu" },
                                    { name: "OrderDetails", params: { order_id: placedOrder?.orderId } },
                                ],
                            })
                            navigation.dispatch(nav)
                        }
                    } catch (error) {
                        console.log("errorerrorerror", error);
                        toggleLoading(false)
                        Toast.show({
                            placement: 'top',
                            title: strings?.['Error'],
                            status: 'error',
                            description: error?.code === 'auth/invalid-phone-number' ? strings?.['Please check entered Phone Number in previous step'] : strings?.['Please try again later or contact us']
                        })

                    }
                }}
                loading={loading}
                btnProps={state?.selectedOption?.key === 'applePay' ? { backgroundColor: 'black' } : {}}
                btnTitle={
                    state?.selectedOption?.key === 'guest' ?
                        'Share payment link' :
                        state?.selectedOption?.key === 'cash' ?
                            'Book now' :
                            'Pay now'
                }
                onPress={async () => {
                    toggleLoading(true)
                    let cardID = state?.selectedOption?.key === 'card' && state.selectedCard
                    try {
                        let placedOrder = await dispatch(placeOrder({ ...order, brand, customer_id: uid, role: role, stripeId, payment_method: cardID, user, brandLoyalty, paymentType: state?.selectedOption?.key }));
                        if (["requires_confirmation", "requires_action"].includes(placedOrder.paymentIntent.status)) {
                            const { error } = await stripe.confirmCardPayment(placedOrder?.paymentIntent?.id);
                            if (error) {
                                toggleLoading(false)
                                if (error?.code === 'testmode_charges_only') {
                                    return alert('Online payment temporarily unavailable. Please use cash payment. Apologies for the inconvenience.')
                                }
                                return alert(error?.message)
                                // The payment failed -- ask your customer for a new payment method.
                            }
                            await dispatch(PlaceOrderAfterConfirm({ ...placedOrder, user }))
                        }
                        if (['guest', 'cash'].includes(state?.selectedOption?.key)) {
                            const nav = navigation.reset({
                                index: 0,
                                type: 'stack',
                                routes: [
                                    { name: 'Home' },
                                    { name: "Menu" },
                                    { name: "OrderDetails", params: { order_id: placedOrder?.orderId } },
                                ],
                            })
                            navigation.dispatch(nav)
                        } else {
                            const nav = navigation.reset({
                                index: 0,
                                type: 'stack',
                                routes: [
                                    { name: 'Home' },
                                    { name: "Menu" },
                                    { name: "OrderDetails", params: { order_id: placedOrder?.orderId } },
                                    // { name: "Payment", params: { order_id: placedOrder?.orderId } }
                                ],
                            })

                            navigation.dispatch(nav)
                        }

                    } catch (error) {
                        console.log("errorerrorerror", error);
                        toggleLoading(false)
                        Toast.show({
                            placement: 'top',
                            title: strings?.['Error'],
                            status: 'error',
                            description: error?.code === 'auth/invalid-phone-number' ? strings?.['Please check entered Phone Number in previous step'] : strings?.['Please try again later or contact us']
                        })
                    }
                }}
                disabled={(!order?.items) || !state?.selectedOption || ['card', 'wallet'].includes(state?.selectedOption?.key) && (!state?.selectedOption || (state?.selectedOption?.key === 'card' && !state.selectedCard))} />

        </View>


    );
};

const Index = ({ navigation }) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentType navigation={navigation} />
        </Elements>
    );
};
export default Index;