import { Modal, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Dimensions, Platform, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import ActionBtn from '../../../shared/ActionBtn';
import Text from '../../../shared/TextLabel';
import Lottie from 'lottie-react-native';


const WalletDetails = ({ dismiss, wallet }) => {
    return (
        <View >
            <Modal isOpen={true} onClose={dismiss} animationPreset='slide' position={Platform.OS === 'web' ? 'bottom' : 'static'} w={Dimensions.get('window').width > 900 ? '30%' : '100%'} marginLeft={Dimensions.get('window').width > 900 ? '35%' : 0} style={{ bottom: 0 }} size={'full'} >
                <Modal.Content borderTopRadius={20} borderBottomRadius={0} marginBottom={0} marginTop='auto' position={Platform.OS === 'web' ? 'bottom' : 'static'} marginX={0} w={'100%'} padding={4}>
                    <View className='popContainer' style={{ backgroundColor: '#fff' }}>
                        <View paddingX={4} alignItems='center' justifyContent='center' display='flex'>
                            <Lottie style={{ position: 'relative', height: 200, width: '100%', }} source={require('../../../../assets/gift.json')} autoPlay loop />
                        </View>
                        <View paddingX={4} paddingTop={4}>
                            <Text fontFamily='bold' fontSize={16} >{'Share To Earn'}</Text>
                            <Text fontFamily='medium' marginTop={1} >{'Share the app with your friends to earn credit and redeem it with Metadoc services'}</Text>

                        </View>
                        <View paddingX={4} paddingTop={4}>
                            <Text fontFamily='bold' fontSize={16} >{'How does it work'}</Text>
                            <View paddingTop={1} paddingX={2}>
                                <Text fontFamily='medium' marginTop={1} >{`\u2022 Receive credits by sharing the app with your friends`}</Text>
                                <Text fontFamily='medium' marginTop={1} >{`\u2022 Redeem your credits to get up to ${wallet?.capPercent}% off your next service request`}</Text>
                                <Text fontFamily='medium' marginTop={1} >{`\u2022 Discount is limited to maximum AED ${wallet?.capValue} per service request`}</Text>
                            </View>
                        </View>
                        <ActionBtn title={'Ok'} onPress={() => dismiss()} />
                    </View>

                </Modal.Content>
            </Modal>
        </View >
    );
};

export default WalletDetails;