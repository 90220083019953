import React from 'react';
import { Modal, View, TouchableOpacity, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
// import { TouchableOpacity } from 'react-native-gesture-handler';
import { secColor } from '../../constants/color';
import { SetAlertMessage } from '../../reducers/settings';
import Text from './TextLabel';
import { strings } from '../../translations/index';
import Lottie from 'lottie-react-native';

const Alert = ({ title = '', msg = "msg", cancelText = 'dismiss', confirmText = '', onCancel, onConfirm, alert, confirmBackground = '#20A2DF', gift = false }) => {
    const dispatch = useDispatch()
    return (
        <Modal
            animationType='fade'
            presentationStyle='overFullScreen'
            transparent={true}
            visible={true}
        >

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', alignItems: 'center', marginLeft: Dimensions.get('window').width > 900 ? '35%' : 0, width: Dimensions.get('window').width > 900 ? '30%' : '100%', height: '101%' }}>
                <View style={{ backgroundColor: '#fff', width: '80%', borderRadius: 16 }}>


                    <View style={{ justifyContent: 'center', alignItems: 'center', paddingVertical: 20, paddingHorizontal: 16, width: '100%' }}>
                        {
                            gift && <View alignItems='center' justifyContent='center' display='flex'>
                                <Lottie style={{ position: 'relative', height: 100, width: '100%', }} source={require('../../assets/gift.json')} autoPlay loop />
                            </View>
                        }
                        {title ? <Text fontFamily='bold' style={{ fontSize: 18 }}> {strings[title]}</Text> : <></>}
                        <Text style={{ lineHeight: 18, textAlign: 'center', fontSize: 12, paddingTop: 8, width: '100%' }}>{msg}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', backgroundColor: secColor, borderBottomStartRadius: 16, borderBottomEndRadius: 16 }}>

                        <View style={{ backgroundColor: '#F5F5F5', width: alert ? '100%' : '50%', borderBottomStartRadius: 16, borderBottomEndRadius: alert ? 16 : 0 }}>
                            <TouchableOpacity style={{ paddingVertical: 12 }} onPress={onCancel ? onCancel : () => dispatch(SetAlertMessage(false))}>
                                <Text fontFamily='semiBold' style={{ color: '#000000', textAlign: 'center', fontSize: 12 }}>{strings[cancelText]}</Text>
                            </TouchableOpacity>
                        </View>
                        {
                            !alert && <View style={{ backgroundColor: confirmBackground, width: '50%', borderBottomEndRadius: 16 }}>
                                <TouchableOpacity style={{ paddingVertical: 12 }} onPress={onConfirm} >
                                    <Text fontFamily='semiBold' style={{ textAlign: 'center', color: '#fff', fontSize: 12 }}>{strings[confirmText]}</Text>
                                </TouchableOpacity>
                            </View>
                        }

                    </View>
                </View>
            </View>

        </Modal >
    );
};

export default Alert;