import { useSelector } from 'react-redux';

export const fontFamily = (weight) => {
    let { locale } = useSelector(state => state?.locale)
    switch (weight) {
        case 'Bold':
            return locale === 'ar' ? 'Tajawal-Bold' : 'Poppins-Bold'
        case 'SemiBold':
            return locale === 'ar' ? 'Tajawal-Medium' : 'Poppins-SemiBold'
        case 'Medium':
            return locale === 'ar' ? 'Tajawal-Medium' : 'Poppins-Medium'
        case 'Regular':
            return locale === 'ar' ? 'Tajawal-Regular' : 'Poppins-Regular'
        default:
            break
    }
}

export const Bold = (locale) => (locale === 'ar' ? 'Tajawal-Bold' : 'Poppins-Bold');
export const SemiBold = (locale) => (locale === 'ar' ? 'Tajawal-Medium' : 'Poppins-SemiBold');
export const Medium = (locale) => (locale === 'ar' ? 'Tajawal-Medium' : 'Poppins-Medium');
export const Regular = (locale) => (locale === 'ar' ? 'Tajawal-Regular' : 'Poppins-Regular');
