import { ScrollView, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import Text from '../../../shared/TextLabel';
import Badge from '../../../shared/Badge';
import moment from 'moment';
import Rate from '../../../shared/Rate';
import { TouchableOpacity } from 'react-native';
import MedicalModal from '../../../shared/MedicalModal';
import { useSelector } from 'react-redux';

const MedialDetailsList = ({ route }) => {
    const { medicalMenu } = useSelector(state => state.helpers);
    let { params: { id } } = route;
    const [modalChilds, setChilds] = useState(false);
    const [state, setState] = useState([]);
    useEffect(() => {
        console.log("refresh", medicalMenu?.find(r => r.id === id)?.data?.filter(r => r.deleted));
        setState(medicalMenu?.find(r => r.id === id)?.data?.filter(r => !r.deleted))
    }, [medicalMenu]);

    return (
        <ScrollView flex={1} contentContainerStyle={{
            padding: 16,
            paddingBottom: 200,
        }}>

            {
                state?.map((rec, index) => {
                    return (<View key={rec.id} backgroundColor={'#fff'} borderRadius={8} marginBottom={2} padding={4} paddingY={3}>
                        {
                            id === 'allergies' ?
                                <TouchableOpacity onPress={() => setChilds({ ...route.params, data: rec, title: `Edit` })}>

                                    <Text fontFamily='semiBold'>
                                        {rec?.name}
                                    </Text>
                                    {
                                        rec?.description ? <View paddingY={2}>
                                            <Text fontSize={12} style={{ color: '#64748B' }}>
                                                {rec?.description}
                                            </Text>
                                        </View>
                                            :
                                            <View padding={1} />
                                    }
                                    <View flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <View flexDirection={'row'} alignItems={'center'}>
                                            <Text fontFamily='medium' fontSize={12} style={{ color: '#00A5E7' }}>
                                                Severity:
                                            </Text>
                                            <View paddingX={2}>
                                                <Rate value={rec?.severity} disabled size={12} />

                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{ color: '#00A5E7' }} fontSize={12} fontFamily='medium'>
                                                {moment(rec.created).format('MMM DD,yyyy')}
                                            </Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                                :
                                id === 'medications' ?
                                    <TouchableOpacity onPress={() => setChilds({ ...route.params, data: rec, title: `Edit` })}>

                                        <View flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Text fontFamily='semiBold'>
                                                {rec?.name}
                                            </Text>
                                            {/* <Badge title={rec?.posology} color='#81D146' /> */}
                                        </View>
                                        {
                                            rec?.description ? <View paddingY={3}>
                                                <Text fontSize={12} style={{ color: '#64748B' }}>
                                                    {rec?.description}
                                                </Text>
                                            </View>
                                                :
                                                <View padding={1} />
                                        }

                                        <View flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                            <Text fontSize={12} fontFamily='medium' style={{ color: '#32A2DC' }}>
                                                Start Date: {moment(rec?.date).format('MMM DD, yyyy')}
                                            </Text>
                                            <View>
                                                <Text fontSize={12} fontFamily='semiBold' style={{ color: '#81D146' }}>
                                                    {rec?.posology}
                                                </Text>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                    :
                                    id === 'diseases' ?
                                        <TouchableOpacity onPress={() => setChilds({ ...route.params, data: rec, title: `Edit` })}>
                                            <Text fontFamily='semiBold'>
                                                {rec?.name}
                                            </Text>
                                            {
                                                rec?.description ? <View paddingY={3}>
                                                    <Text fontSize={12} style={{ color: '#64748B' }}>
                                                        {rec?.description}
                                                    </Text>
                                                </View>
                                                    :
                                                    <View />
                                            }
                                            <View  >
                                                <Text fontSize={12} fontFamily='medium' style={{ color: '#32A2DC' }}>
                                                    Diagnosis date: {moment(rec?.diagnosis_date).format('MMM DD, yyyy')}
                                                </Text>
                                            </View>

                                        </TouchableOpacity>
                                        :
                                        id === 'imcs' ?
                                            <TouchableOpacity onPress={() => setChilds({ ...route.params, data: rec, title: `Edit` })}>
                                                <Text fontFamily='semiBold'>
                                                    {`BMI ${(rec?.weight / ((rec?.height * rec?.height / 10000)))?.toFixed(2)}`}
                                                </Text>
                                                <View flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} paddingTop={2}>
                                                    <Text style={{ color: '#64748B' }} fontSize={12}>
                                                        {`Height: ${rec?.height} | Weight: ${rec?.weight}`}
                                                    </Text>
                                                    <Text fontSize={12} fontFamily='medium' style={{ color: '#00A5E7' }}>
                                                        {moment(rec?.date).format('MMM DD, yyyy')}
                                                    </Text>
                                                </View>
                                            </TouchableOpacity>
                                            :
                                            <>
                                            </>

                        }

                    </View>)
                })
            }
            {
                modalChilds && <MedicalModal {...modalChilds} dismiss={() => setChilds(false)} />
            }

        </ScrollView>
    );
};

export default MedialDetailsList;