import React, { useRef } from 'react';
import { Pressable, Spinner, View } from 'native-base';
import { Add, minus } from '../../constants/imgs';
import Text from './TextLabel';
import Image from './customImg'
import { Animated, Platform } from 'react-native';
import { async } from 'regenerator-runtime';
import { analytics } from '../../constants/firebase/config';
import { appsFlyerLogEvent } from '../../helpers/index';
const Counter = ({ count, updateCount, direction, minCount, maxCount, data }) => {
    const animatedLoadingButton = useRef(new Animated.Value(0)).current;
    let size = direction ? 35 : 25
    if (!count || count === 0) {
        return (
            <Pressable
                hitSlop={{ top: 20, bottom: 20, left: 50, right: 50 }}
                onPress={() => {
                    Animated.spring(animatedLoadingButton, {
                        toValue: 1,
                        useNativeDriver: true,
                    }).start(async () => {

                        updateCount(Number(count || 0) + Number(minCount || 1))
                        appsFlyerLogEvent('add_to_cart', {
                            af_id: data?.id,
                            af_item: data?.name?.en,
                            af_description: data?.details?.en,
                            af_platform: Platform.OS
                        })
                        await analytics().logEvent('add_to_cart', {
                            id: data?.id,
                            item: data?.name?.en,
                            description: data?.details?.en,
                            platform: Platform.OS
                        })
                        animatedLoadingButton.setValue(0)
                    })
                }}

                w={size} h={size} borderRadius={4} >
                {({
                    isPressed
                }) => {
                    return (
                        <View >
                            <Animated.View style={{
                                position: 'absolute',
                                opacity: animatedLoadingButton.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 0],
                                    extrapolate: 'clamp',

                                }),
                                transform: [
                                    {
                                        scale: animatedLoadingButton.interpolate({
                                            inputRange: [0, 1],
                                            outputRange: [1, 0],
                                            extrapolate: 'clamp',

                                        })
                                    }
                                ],

                            }}>
                                <Animated.Image source={Add} />

                                <Image
                                    svg
                                    fill={'#20A2DF'}
                                    uri={Add}
                                    width={size}
                                    height={size}
                                />
                            </Animated.View>
                            <Animated.View style={{
                                position: 'absolute',
                                opacity: animatedLoadingButton.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [0, 1],
                                    extrapolate: 'clamp',
                                }),
                                transform: [
                                    {
                                        scale: animatedLoadingButton.interpolate({
                                            inputRange: [0, 1],
                                            outputRange: [0, 1],
                                            extrapolate: 'clamp',

                                        })
                                    }
                                ]
                            }}>
                                <Spinner />
                            </Animated.View>
                        </View>
                    )

                }}

            </Pressable >
        )
    }
    return (
        <View alignItems={'center'} flexDirection={direction} justifyContent='space-between'>
            <Pressable
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                onPress={() => {
                    Animated.spring(animatedLoadingButton, {
                        toValue: 1,
                        useNativeDriver: true,
                    }).start(() => {
                        // updateCount(Number(count || 0) + 1)
                        updateCount(count < (Number(maxCount) || 200) ? (Number(count || 0) + 1) : count);

                        animatedLoadingButton.setValue(0)
                    })
                }}
                w={size} h={size} borderRadius={4}>
                {({
                    isPressed
                }) => {
                    return (
                        <View style={{
                            transform: [{
                                scale: isPressed ? 0.96 : 1
                            }]
                        }}>
                            <Image
                                svg
                                fill={'#20A2DF'}
                                uri={Add}
                                width={size}
                                height={size}
                            />
                        </View>
                    )

                }}


            </Pressable>
            <View paddingY={0.5} style={{
                height: 32
            }} justifyContent='center' alignItems={'center'} >
                <Animated.View style={{
                    position: 'absolute',
                    opacity: animatedLoadingButton.interpolate({
                        inputRange: [0, 1],
                        outputRange: [1, 0],
                        extrapolate: 'clamp',

                    }),
                    transform: [
                        {
                            scale: animatedLoadingButton.interpolate({
                                inputRange: [0, 1],
                                outputRange: [1, 0],
                                extrapolate: 'clamp',

                            })
                        }
                    ],
                }}>
                    <Text fontWeight='bold' fontFamily='bold' fontSize={18} >{count}</Text>

                </Animated.View>
                <Animated.View style={{
                    position: 'absolute',
                    opacity: animatedLoadingButton.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 1],
                        extrapolate: 'clamp',
                    }),
                    transform: [
                        {
                            scale: animatedLoadingButton.interpolate({
                                inputRange: [0, 1],
                                outputRange: [0, 1],
                                extrapolate: 'clamp',

                            })
                        }
                    ]
                }}>
                    <Spinner />
                </Animated.View>

            </View>
            <Pressable
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                onPress={() => {
                    Animated.spring(animatedLoadingButton, {
                        toValue: 1,
                        useNativeDriver: true,
                    }).start(() => {
                        updateCount(count > (minCount || 1) ? (count - 1) : 0);

                        animatedLoadingButton.setValue(0)
                    })
                }}
                w={size} h={size} borderRadius={4}>
                {({
                    isPressed
                }) => {
                    return (
                        <View style={{
                            transform: [{
                                scale: isPressed ? 0.96 : 1
                            }]
                        }}>
                            <Image
                                svg
                                fill={'#20A2DF'}
                                uri={minus}
                                width={size}
                                height={size}
                            />
                        </View>
                    )

                }}
            </Pressable>
        </View>
    );
};

export default Counter;