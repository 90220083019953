import { Button, Flex, Image, View } from 'native-base';
import React from 'react';
import { DeliveryIcon, Wallet } from '../../constants/imgs';
import Text from './TextLabel';
import CustomImg from './customImg'
import Counter from './Counter';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateOrderItems } from '../../reducers/order';
import { Localize } from '../../helpers/helpers';
import { Platform, TouchableOpacity } from 'react-native';
import { SetAlertMessage } from '../../reducers/settings';
import { strings } from '../../translations/index';

const CardList = ({ data, navigation }) => {
    const { items } = useSelector(state => state.order);
    const dispatch = useDispatch();
    const url = data?.picture?.[0] || data?.image;

    return (
        <TouchableOpacity
            onPress={() => navigation.navigate('service_page', {
                id: data?.sku,
                type: 'medicine',
                goBack: true
            })
            }>
            <Flex
                flexDirection={'row'}
                minHeight={113}
                justifyContent='space-between'
                background={'#fff'}
                borderRadius={8}
                padding={4}
                alignItems='center'>
                <View style={{
                    width: 70,
                    height: 70,
                    transform: [{ scale: 1.3 }]
                }} >
                    <Image key={url} defaultSource={Platform.OS === 'web' ? { uri: url, cache: 'force-cache' } : {}} resizeMode='cover' alt={Localize(data?.name).slice(0, 3)} _alt={{ color: '#fff' }} source={{ uri: url, cache: 'force-cache' }} height={'100%'} width={'100%'} />
                </View>

                <View flex={1} marginX={3}>
                    <View >
                        <Text fontFamily='semiBold'
                            fontSize={12}
                            numberOfLines={1}
                        >
                            {Localize(data?.name)}
                        </Text>
                        <Text paddingTop={1} fontFamily='light'
                            fontSize={11}
                            style={{ color: '#bfbfbf' }}
                        >
                            {data?.unit}
                        </Text>
                    </View>
                    <View paddingTop={3} flexDirection='row' alignItems={'center'} >
                        <View>
                            <CustomImg
                                svg
                                width={13}
                                height={13}
                                fill="#E5E5E5"
                                uri={Wallet}
                            />
                        </View>

                        <Text paddingX={1}
                            fontSize={12}
                            fontFamily='bold' >
                            {data?.price} {strings?.AED}
                        </Text>
                    </View>
                </View>
                <View>
                    <Counter
                        count={items?.[data?.sku]?.count}
                        minCount={data?.min_count}
                        maxCount={data?.max_count}
                        data={data}
                        updateCount={
                            (count) => {
                                let orderList = JSON.parse(JSON.stringify(items))
                                if (count > 0) {
                                    let serviceID = Object.values(orderList)?.[0]?.service_id
                                    if (serviceID && serviceID !== data.service_id) {
                                        dispatch(SetAlertMessage({
                                            title: "Caution",
                                            confirmText: 'Confirm',
                                            msg: "By adding this item to cart ,Other Selected services will be removed from cart",
                                            onCancel: () => { dispatch(SetAlertMessage(false)) },
                                            onConfirm: () => {
                                                let updatedList = Object.keys(orderList).reduce((val, currentKey) => {
                                                    let currentVal = orderList?.[currentKey]
                                                    if (currentVal.service_id === data.service_id) {
                                                        val[currentKey] = currentVal
                                                    }
                                                    return val
                                                }, {});
                                                dispatch(UpdateOrderItems({ ...updatedList, [data?.sku || data?.id]: { count: data?.min_count || 1, price: { total: data?.price }, name: data?.name, service_id: data?.service_id } }));
                                                dispatch(SetAlertMessage(false));

                                            }
                                        }))
                                    } else {
                                        dispatch(UpdateOrderItems({ ...orderList, [data?.sku || data?.id]: { count: count, price: { total: data?.price }, name: data?.name, service_id: data?.service_id } }))
                                    }

                                } else {
                                    delete orderList?.[data?.sku]
                                    dispatch(UpdateOrderItems(orderList))
                                }
                            }
                        }
                    />
                </View>
            </Flex>
        </TouchableOpacity>
    );
};

export default CardList;