import { View } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { TeleConsultation } from '../../../helpers/index';
import Header from '../../shared/Header';
import { useDispatch, useSelector } from 'react-redux';

const Index = () => {
    const [state, setState] = useState(false);
    const userData = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(TeleConsultation(userData))
    }, []);

    function updateStyle(elements, display) {
        setTimeout(() => {
            for (var i = 0, len = elements.length; i < len; i++) {
                elements[i].style.display = display;
            }
        }, 10);

    }
    useEffect(() => {
        console.log(window.location.hash);
        let element = document.getElementsByClassName('__mdHeader');
        let event = window.addEventListener('hashchange', (state) => {
            let url = state.newURL
            if (url.includes('room') || url.includes('detail')) {
                updateStyle(element, 'flex')
                setState(true)
            } else {
                updateStyle(element, 'none')
                setState(false)
            }
        })

        return () => {
            window.removeEventListener('hashchange', event)
        }
    }, [])

    return (
        <View flex={1} >
            {
                state ? <View /> : <Header title={"Doctors"} webPath={state} />
            }
            <View height={state ? '100vh' : 'calc(100vh - 63px)'} id='MeetingDocContainer' nativeID='MeetingDocContainer'>
            </View>
        </View>
    );
};

export default Index;