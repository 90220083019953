import { Button, Modal, useToast, View } from 'native-base';
import React, { useState } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { useDispatch } from 'react-redux';
import { auth } from '../../../constants/firebase/config';
import { shadow } from '../../../helpers/helpers';
import { FlushUser } from '../../../reducers/user';
import Input from '../../shared/CustomInput';
import Text from '../../shared/TextLabel';

const loginSheet = ({ dismiss }) => {
    const { height } = useWindowDimensions();
    const [state, setState] = useState({});
    const [loading, toggleLoading] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch()
    // let bottom = window.matchMedia('(display-mode: standalone)').matches ? 0 : document?.body?.scrollHeight - height;

    return (
        <Modal minH={height} isOpen={true} onClose={() => dismiss()} size={'full'} >
            <Modal.Content marginX={0} w={'100%'} marginBottom={0} marginTop={'auto'} style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                <View
                    w={'100%'} background={'#E9F0F7'} paddingX={4} paddingY={8}>
                    <Text fontFamily='bold' fontWeight='bold' style={{ fontSize: 24 }}>
                        Welcome
                    </Text>
                    <Text >
                        Please enter your credentials to login
                    </Text>
                    <View paddingTop={6} paddingBottom={100}>
                        {
                            [{ key: 'email', title: 'Email', placeholder: 'Enter email' },
                            { key: 'password', title: 'Password', placeholder: 'Enter Password' }].map(r => <View key={r.key} paddingBottom={2}>
                                <Text fontSize={12} paddingBottom={1} >{r.title}</Text>
                                <Input
                                    key={r.key}
                                    onChange={(e) => setState({ ...state, [r.key]: e.target.value })}
                                    type={r.key}
                                    placeholder={r.placeholder}
                                />
                            </View>)
                        }
                    </View>
                    <View paddingBottom={8}>
                        <Button
                            borderRadius={8}
                            shadow={shadow({ color: '#00000040', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
                            isLoading={loading}
                            _loading={{
                                backgroundColor: '#20A2DF',
                            }}
                            _spinner={{
                                color: '#fff'
                            }}
                            isLoadingText=' '
                            height={50} onPress={async () => {
                                toggleLoading(true)
                                try {
                                    let result = await auth().signInWithEmailAndPassword(state.email, state.password);
                                    let idTokenResult = await auth().currentUser.getIdTokenResult();
                                    let { role, parent_id } = idTokenResult?.claims || {}
                                    let partnerKey = window.location.hostname.split('.')?.[0];
                                    if (role === 'concierge', parent_id === partnerKey) {
                                        // dispatch(GetUser({ ...result?.user, role: idTokenResult.claims?.role }))
                                        dismiss()
                                        // navigation.reset({
                                        //     index: 0,
                                        //     routes: [{ name: 'Home' }],
                                        // });
                                    } else {
                                        await auth().signOut()
                                        dispatch(FlushUser())
                                        toast.show({
                                            title: 'Error unautorized account',
                                            status: 'error',
                                            placement: 'top'
                                        })
                                    }

                                } catch (error) {
                                    console.log("error", error);
                                    toggleLoading(false);
                                    toast.show({
                                        title: 'Error',
                                        description: error?.message,
                                        position: 'top'
                                    })
                                }
                            }}>
                            Login
                        </Button>
                    </View>
                </View>


            </Modal.Content>
        </Modal>
    );
};

export default loginSheet;