import React from 'react';
import { CardStyleInterpolators, createStackNavigator } from "@react-navigation/stack";
import Main from './index';
import Contact from './com/ContactInfo';
import PaymentType from './com/PaymentType';
import Header from './com/Header';
import Address from './com/Address';
import Cart from "./com/Cart";
import { useSelector } from 'react-redux';
import AddressNavigator from "../Locations/navigator";
import { Platform } from 'react-native';
// import PaymentSheet from './com/paymentSheet';
const BookingStack = createStackNavigator();
const BookingNavigator = ({ navigation }) => {
    const { service } = useSelector(state => state.order);
    // let addedCount = brand?.customLocations ? 1 : 0
    let addedCount = 0

    return (
        <BookingStack.Navigator
            // initialRouteName={brand?.customLocations ? 'Address' : 'TimmingScreen'}
            initialRouteName={service?.id === 'pharmacy' ? 'Address' : 'TimmingScreen'}
            screenOptions={{
                headerShown: true,
                cardStyle: { backgroundColor: '#F5F7F8' }
            }}>
            <BookingStack.Screen name="Address" component={Address} options={{
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                
                header: () => <Header step={1} totalSteps={3 + addedCount} />,
            }} />
            <BookingStack.Screen name="Cart" component={Cart} options={{
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                header: () => <Header step={2} totalSteps={3 + addedCount} />,
            }} />
            {AddressNavigator(BookingStack)}
            <BookingStack.Screen name="TimmingScreen" component={Main} options={{
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,

                header: () => <Header step={1 + addedCount} totalSteps={2 + addedCount} />,
            }} />
            <BookingStack.Screen name="ContactInfo" component={Contact} options={{
                cardStyleInterpolator: Platform.OS === 'web' ? CardStyleInterpolators.forFadeFromCenter : CardStyleInterpolators.forHorizontalIOS,
                header: () => <Header step={2 + addedCount} totalSteps={3 + addedCount} />,
            }} />
            <BookingStack.Screen name="PaymentType" component={PaymentType} options={{
                cardStyleInterpolator: Platform.OS === 'web' ? CardStyleInterpolators.forFadeFromCenter : CardStyleInterpolators.forHorizontalIOS,
                header: () => <Header step={2 + addedCount} totalSteps={2 + addedCount} />,
            }} />
        </BookingStack.Navigator>
    )
}

export default BookingNavigator;
