import React from 'react';
import Text from './TextLabel';
import { Pressable, StatusBar, View } from 'native-base';
import { AddSVG, ArrowVector } from '../../constants/imgs';
import Image from './customImg'
import { useNavigation } from '@react-navigation/native';
import { Platform, SafeAreaView, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { colorTextBlue } from '../../constants/color';

const Header = ({ title, style, onlyTitle = false, onAdd = false, webPath = false }) => {
    const nav = useNavigation()
    const { locale } = useSelector(state => state.locale)
    const { webView } = useSelector(state => state.settings)

    if (webView) {
        return (
            <View />
        )
    }
    const goBack = () => {
        if (webPath) {
            window?.history?.back()
            return
        }
        if (nav.canGoBack()) {
            nav.goBack()
        } else {
            nav.reset({
                index: 0,
                routes: [{ name: 'Home' }],
            })
        }
    }
    return (
        <SafeAreaView style={{ backgroundColor: '#fff' }}>
            {Platform.OS === 'android' ?
                <StatusBar backgroundColor={'transperant'} animated={true} barStyle={'dark-content'} />
                : <></>}
            <View style={{ ...style }} marginTop={Platform.OS == 'android' ? 6 : 0} padding={4} flexDirection={'row'} backgroundColor='#fff' justifyContent='space-between' alignItems={'center'} borderBottomColor={'#E8E8EB'} borderBottomWidth={1}>
                {
                    onlyTitle ?
                        <View w={10} />
                        :
                        <Pressable
                            onPress={goBack}
                            hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                            w={10}>
                            <Image
                                uri={ArrowVector}
                                rotate={locale === 'ar'}
                                width={20}
                                height={20}
                                svg
                                fill='#0F172A'
                            />
                        </Pressable>
                }

                <Text fontFamily='semiBold' fontSize={20}>{decodeURI(title)}</Text>
                {
                    onAdd ?
                        <TouchableOpacity style={{ width: 40, alignItems: 'flex-end' }} onPress={onAdd}>
                            <Image
                                uri={AddSVG}
                                width={20}
                                height={20}
                                svg
                            />
                        </TouchableOpacity>
                        :
                        <View w={10} />
                }

            </View>
        </SafeAreaView>

    );
};

export default Header;