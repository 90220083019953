import React, { useEffect, useState } from 'react';
import { Button, InputGroup, InputRightAddon, Pressable, ScrollView, useToast, View } from 'native-base';
import Input from '../shared/CustomInput'
import Text from '../shared/TextLabel';
import { applyPromocode, shadow } from '../../helpers/helpers';
import { MasterCard, Visa } from '../../constants/imgs';
import { useNavigation } from '@react-navigation/native';
import OrderSummarySheet from '../shared/OrderSummarySheet';
import {
    CardNumberElement,
    useStripe,
} from "@stripe/react-stripe-js";
import { useSelector } from 'react-redux';
import { firestore } from '../../constants/firebase/config';
import OneTimeForm from './OneTimeForm';
import CardOptionComp from '../shared/CardOptionComp';
import { strings } from '../../translations/index';

const Main = ({ route }) => {
    const { cards, uid } = useSelector(state => state.user);
    useEffect(() => {
        let { order_id } = route?.params || {}
        let reff;
        if (order_id) {
            reff = firestore().collection('orders').doc(order_id).onSnapshot(orderSnap => {
                let { paymentStatus } = orderSnap.data();
                if (paymentStatus === 'paid') {
                    if (navigator.canGoBack()) {
                        navigator.goBack();
                    } else {
                        window.location.href = '/'
                    }
                }
                setState({ ...state, order: { ...orderSnap.data(), id: order_id }, anonimous: uid !== orderSnap.data()?.customer_id })
            })
        }
        return () => {
            if (reff) {
                reff()
            }
        }
    }, [])
    const navigator = useNavigation();
    const Toast = useToast()
    const stripe = useStripe();
    const [loading, setLoading] = useState(false)
    const [elements, setElements] = useState(false);
    const [state, setState] = useState({
        options: [
            {
                key: 'wallet',
                title: strings?.['Apple Pay / Google Pay']
            },
            {
                key: 'card',
                title: strings?.['Pay by Card']
            }
        ]
    })
    return (
        <View flex={1} justifyContent='space-between'>
            <ScrollView padding={4} contentContainerStyle={{ paddingBottom: 150 }} >
                {
                    state?.order?.receipt?.code ?
                        <View height={50}
                            borderRadius={8}
                            backgroundColor={'#20A2DF30'}
                            justifyContent='space-between'
                            padding={4}
                            alignItems='center'
                            flexDirection='row'
                            shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })}>
                            <Text style={{ color: '#20A2DF' }}>
                                {`${strings?.["Promocode Applied"]}:`}
                            </Text>
                            <Text fontFamily='bold' style={{ color: '#20A2DF' }} >{state?.order?.receipt?.code}</Text>
                        </View>
                        :
                        <InputGroup w={'100%'} borderRadius={8} style={{ boxShadow: 'none' }}>
                            <Input
                                _focus={
                                    { borderColor: 'transparent' }
                                }
                                w='70%'
                                style={{ boxShadow: 'none' }}
                                backgroundColor={'#fff'}
                                borderColor='#fff'
                                height={50}
                                shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
                                placeholder={strings?.['Having a promo code?']}
                                onChange={(e) => setState({ ...state, promoCode: e.target.value })}
                                borderWidth={1}
                                borderRightColor='#cacaca'
                            />
                            <InputRightAddon
                                borderBottomRadius={8}
                                borderTopRadius={8}
                                borderColor={'#fff'}
                                w='30%'
                                backgroundColor={'#fff'}
                                style={{ shadow: shadow({ color: '#000000', radius: 8, offsetWidth: 1, offsetHeight: 1 }) }}
                            >
                                <Button
                                    padding={0}
                                    _spinner={{
                                        color: '#2F8AF4'
                                    }}
                                    backgroundColor={'#fff'}

                                    isLoading={loading === 'promo'}
                                    onPress={async () => {
                                        setLoading('promo')
                                        let res = await applyPromocode({ code: state.promoCode, orderData: state.order })
                                        if (["error", "affiliate"].includes(res.status)) {
                                            setLoading(false)
                                            return Toast.show({
                                                placement: 'top',
                                                title: 'Error',
                                                status: 'error',
                                                description: res.msg
                                            })
                                        }

                                        setState({ ...state, order: { ...state.order, receipt: res } })
                                        setLoading(false)

                                    }} >
                                    <Text style={{ color: '#2F8AF4' }} > {strings?.["Apply"]}</Text>
                                </Button>
                            </InputRightAddon>
                        </InputGroup>
                }



                <Text paddingTop={8} fontFamily='semiBold' >
                    {strings?.["Payment options"]}

                </Text>
                <View paddingTop={4}>
                    {
                        state.options?.map(rec =>
                            <Pressable paddingBottom={4} key={rec.key} onPress={() => setState({ ...state, selectedOption: rec })}>
                                <CardOptionComp
                                    key={rec.key}
                                    data={rec}
                                    selected={state?.selectedOption?.key === rec.key} />
                            </Pressable>
                        )
                    }
                </View>
                {
                    state?.selectedOption?.key === 'card' && <View>
                        {
                            !state?.anonimous ? <View>
                                {
                                    cards?.map(card => <Pressable
                                        paddingBottom={4}
                                        key={card.id}

                                        onPress={() => setState({ ...state, selectedCard: card.id })}>
                                        <CardOptionComp
                                            card={true}
                                            backgroundColor='#ffffff'
                                            key={card.id}
                                            data={{ title: `XXXX ${card?.card?.last4}`, image: card?.card?.brand === 'visa' ? Visa : MasterCard }}
                                            selected={state?.selectedCard === card.id} />
                                    </Pressable>)
                                }
                                <Pressable onPress={() => navigator.navigate('addCard')}>
                                    <Text style={{ color: '#20A2DF' }}>{strings?.["+ Add new card"]}</Text>
                                </Pressable>
                            </View>
                                :
                                <View>
                                    <OneTimeForm enabled={(e) => setElements(e)} />
                                </View>
                        }
                    </View>
                }
            </ScrollView>
            <OrderSummarySheet
                loading={loading === 'order'}
                btnTitle='Pay now'
                fixed={true}
                orderData={state.order}
                onPress={async () => {
                    setLoading('order')
                    let paymentMethod;
                    if (state.anonimous && !elements?.disabled) {
                        const { paymentMethod: payload, error } = await stripe.createPaymentMethod({
                            type: "card",
                            card: elements.elements.getElement(CardNumberElement)
                        });
                        if (error) {
                            setLoading(false)
                            alert(error?.message)
                            return
                        }
                        paymentMethod = payload?.id
                    }
                    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                        state.order?.paymentIntent,
                        { payment_method: paymentMethod ? paymentMethod : state?.selectedCard, setup_future_usage: 'off_session' },
                        { handleActions: false }
                    );

                    if (confirmError) {
                        // Report to the browser that the payment failed, prompting it to
                        // re-show the payment interface, or show an error message and close
                        // the payment interface.
                        console.log("confirmError", confirmError);
                        setLoading(false)
                        alert(confirmError?.message)
                        // complete('fail');
                    } else {
                        // Report to the browser that the confirmation was successful, prompting
                        // it to close the browser payment method collection interface.
                        console.log("success");

                        // complete('success');
                        // Check if the PaymentIntent requires any actions and if so let Stripe.js
                        // handle the flow. If using an API version older than "2019-02-11"
                        // instead check for: `paymentIntent.status === "requires_source_action"`.
                        if (paymentIntent.status === "requires_action") {
                            // Let Stripe.js handle the rest of the payment flow.
                            const { error } = await stripe.confirmCardPayment(state.order?.paymentIntent);
                            if (error) {
                                setLoading(false)
                                alert(error?.message)

                                // The payment failed -- ask your customer for a new payment method.
                            } else {
                                // The payment has succeeded.
                                // navigator.goBack()
                            }
                        } else {
                            // navigator.goBack()
                            // The payment has succeeded.
                        }
                    }
                }}
                disabled={state?.selectedOption?.key === 'wallet' ? false : (state?.selectedOption?.key === 'card' && (state?.anonimous ? elements.disabled : !state.selectedCard))}
                walletActive={state?.selectedOption?.key === 'wallet'} />
        </View>

    );
};

export default Main;