import React from 'react';
import { IconButton, InfoIcon, Popover } from 'native-base';
import { Localize } from '../../helpers/helpers';
const PopoverIcon = ({ data }) => {
    return (
        <Popover placement='top' trigger={triggerProps => {
            return <IconButton  {...triggerProps} paddingY={0} paddingX={1} margin={0}  icon={<InfoIcon color='gray.300' size='xs' />} />
        }}>
            <Popover.Content w="56">
                <Popover.Arrow />
                <Popover.Body>
                    {Localize(data?.details)}
                </Popover.Body>
            </Popover.Content>
        </Popover>
    );
};

export default PopoverIcon;