import { useNavigation } from '@react-navigation/native';
import { FlatList, Flex, Pressable, ScrollView, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { shadow } from '../../../../helpers/helpers';
import EmptyState from '../../../shared/EmptyState';
import Text from '../../../shared/TextLabel';
import BookingCard from './BookingCard';
import { strings } from '../../../../translations/index';
import { RateOnAppstore } from '../../../../helpers/index';

const BookingHistory = () => {
    const navigation = useNavigation();
    const { orders } = useSelector(state => state.user)

    const [state, setState] = useState({
        status: 'active',
        orders: orders?.filter(rec => ['new', 'pending', 'accepted', 'ontheway', 'active']?.includes(rec.status))?.sort((a, b) => b?.created - a?.created)
    })

    useEffect(() => {
        if (orders?.filter(r => ['rated', 'completed'].includes(r?.status))?.length > 0) {
            RateOnAppstore()
        }
    }, [])
    const [status] = useState([
        {
            title: 'Upcoming',
            key: 'active',
            status: ['new', 'pending', 'accepted', 'ontheway', 'active']
        },
        {
            title: 'History',
            key: 'all',
            status: ['completed', 'rated', 'canceled', 'rejected']
        },
        // {
        //     title: 'Completed',
        //     key: 'completed',
        //     status: ['completed', 'rated']
        // },
        // {
        //     title: 'Canceled',
        //     key: 'canceled',
        //     status: ['canceled', 'rejected']
        // },
    ])
    useEffect(() => {
        let currentStatus = status?.find(r => r.key === state.status);
        setState({
            ...state,
            orders: orders?.filter(rec => currentStatus?.status?.includes(rec.status))?.sort((a, b) => b?.created - a?.created)
        })
    }, [orders])
    return (
        <ScrollView paddingTop={4} contentContainerStyle={{ paddingBottom: 150 }}>
            <>
                <FlatList
                    data={status}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={{
                        padding: 16,
                        alignItems: 'center'
                    }}
                    renderItem={({ item }) => <Pressable
                        onPress={() => setState({
                            status: item.key,
                            orders: orders?.filter(rec => item?.status?.includes(rec.status))?.sort((a, b) => b?.created - a?.created)
                        })}
                        key={item.key}
                        shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
                        paddingX={4}
                        paddingY={2}
                        backgroundColor={state.status === item.key ? '#20A2DF' : '#fff'}
                        borderRadius={8}
                        mr={2}>
                        <Text fontFamily='medium' style={{ color: state.status === item.key ? '#fff' : '#3B3B3B' }} >
                            {strings?.[item.title]}
                        </Text>

                    </Pressable>}
                    keyExtractor={(item) => item.key}
                />
                {
                    state?.orders?.length ?
                        <Flex padding={4}>
                            {
                                state?.orders?.map(rec => <Pressable mb={2} key={rec.id} onPress={() => navigation.navigate('OrderDetails', { order_id: rec.id })}>
                                    <BookingCard data={rec} />
                                </Pressable>)
                            }
                        </Flex>
                        :
                        <View height={Platform.OS === 'web' ? '60vh' : 'auto'}>
                            <EmptyState title={strings?.['No appointments']} />
                        </View>
                }
            </>
        </ScrollView>
    );
};

export default BookingHistory;