import { View } from 'native-base';
import React from 'react';
import Text from './TextLabel';

const Badge = ({ color = '#F5B82F', title, fontSize = 10, fontFamily = 'medium' }) => {
    return (
        <View paddingX={2} paddingY={1} justifyContent='center' alignItems={'center'} borderRadius={4} backgroundColor={`${color}30`} >
            <Text fontSize={fontSize} numberOfLines={1} textAlign='center' fontFamily={fontFamily} style={{ color: color }}>
                {title}
            </Text>
        </View>
    );
};

export default Badge;