export default {
    "morning": "上午",
    "afternoon": "下午",
    "evening": "晚上",
    "dismiss": "消除",
    "searchPlace": "搜索地点",
    "confirmLocation": "确认位置",
    "yourName": "名字",
    "yourNumber": "电话",
    "yourEmail": "电子邮件",
    "area": "地区",
    "street": "街道",
    "buildingName": "建筑物名称",
    "floor": "楼层",
    "flat": "单元房",
    "villa": '别墅',
    "instruction": "用法说明",
    "nickName": '昵称',
    "Home": "住所",
    "Work": "工作地点",
    "Other": "其他",
    "saveAddress": "保存地址",
    "Bookings": "预订",
    "Locations": "地点",
    "Payment Methods": "付款方式",
    "Metrics": "指标",
    "Logout": "退出",
    "Login": "登录",
    "Staff login": "员工登录",
    "Upcoming": "即将到来",
    "All": "全部",
    "Completed": "已完成",
    "Failed": "失败",
    "Placed On": "放在",
    "PENDING PAYMENT": "待付款",
    "Pending Payment": "待付款",
    "payment link is sent for your guest and we are awaiting payment to continue": "付款链接已发送给您的客人，我们正在等待其付款以继续下一步",
    "Your booking is received": "已收到您的预订",
    "Confirmed": "已确认",
    "We will match you with top-rated professionals in your area": "我们将为您匹配所在地区的顶级专业人员",
    "Service provider": "服务提供者",
    "Assigned": "已分配",
    "In Progress": "进行中",
    "On the way": "在途中",
    "Session started": "会话已开始",
    "Your order is Completed": "您的订单已完成",
    "Your request has been completed successfully": "您的请求已成功完成",
    "Your booking is Canceled": "您的预订已取消",
    "Canceled": "已取消",
    "Your booking is Rejected": "您的预订被拒绝",
    "Rejected": "被拒绝",
    "Flat": "单元房",
    "Room": "房间",
    "Call us": "联系我们",
    'Reach us': '联系我们',
    "Whatsapp": "Whatsapp",
    "English": "英语",
    "Arabic": "阿拉伯语",
    "Warning": "警告",
    "Are you sure ,This locations will be deleted permanently": "是否确定把该位置永久删除",
    "Delete": "删除",
    "Are you sure ,This card will be deleted permanently": "是否确定把该卡永久删除",
    "Profile": "用户资料",
    "Order History": "订单历史",
    "Language": "语言",
    "Contact Us": "联系我们",
    "Services": "服务",
    "AED": "迪拉姆",
    "Subtotal": "小计",
    "Discount": "折扣",
    "DiscountCode": "折扣",
    "VAT": "增值税",
    "Total": "全部的",
    "View items": "查看项目",
    "Continue": "继续",
    "Pay now": "立即付款",
    "Share payment link": "分享付款链接",
    "Schedule Appointment": "安排预约",
    "Step": "步骤",
    "out of": "离开",
    "Welcome": "欢迎",
    "Phone verification": "电话验证",
    "Please enter your mobile number to login": "请输入手机号码登录",
    "Enter your OTP code here": "在此处输入OTP代码",
    "This site is protected by reCAPTCHA and the Google": "本网站受 reCAPTCHA 和 Google 保护",
    "Privacy Policy": "隐私政策",
    "and": "和",
    "Terms of Service": "服务条款",
    "apply": "使用",
    "Reschedule Request": "更改请求",
    "RescadualMsg": "您的请求将由 {1}更改为 {0}",
    "Reschedule": "重新安排",
    "Select preferred Date and Time": "选择首选日期和时间",
    "Do you have any specific instructions?": "您有没有什么特别说明？",
    "Incomplete data": "资料不完整",
    "Please select date and time to proceed": "请选择日期和时间再继续",
    "Please select Date first": "请先选择日期",
    "Contact Person Name": "联系人名字",
    "Room Number": "房间号",
    "Building": "建筑",
    "Your Customer will receive status updates": "您的客户将收到更新状态",
    "Contact Person Number": "联系人电话",
    "Email": "电子邮件",
    "Customer information": "客户信息",
    "Please fill all required fields": "请填写所有必填字段",
    "Ask the customer to pay by sharing the payment link with them with auto generated payment link": "与客户共享自动生成的付款链接，他们可通过共享链接来进行付款",
    "Complete the payment on behalf of the customer using the online payment gateway": "代表客户使用在线支付网关完成支付",
    "Auto-generate payment link can be shared with anyone to pay on your behalf": "您可以共享自动生成的付款链接，以让他人代替您付款",
    "Complete the payment using the online payment gateway": "使用在线支付网关完成支付",
    "Payment Options": "付款方式",
    "Payment Details": '付款详情',
    "Please check entered Phone Number in previous step": "请检查上一步中输入的电话号码",
    "Please try again later or contact us": "请稍后再试或联系我们",
    "Where do you want to have the service": "您想在哪里获得服务",
    "+ Add new address": "+ 添加新地址",
    "Please select your address": "请选择您的地址",
    "Details": "详情",
    "You need to complete the payment in order to process your request": "您需要完成付款才能处理您的请求",
    "Order Status": "订单状态",
    "Order Details": "订单详情",
    "Pay Now": "立即支付",
    "Order ID": "订单号",
    "Customer Name": "客户名字",
    "Customer Number": "客户电话",
    "Service": "服务",
    "Service Details": "服务详情",
    "Service Provider": "服务提供者",
    "Instructions": "说明",
    "Address": "地址",
    "Flat number:": "单元房号：",
    "Room number:": "房间号：",
    "Date & Time": "日期和时间",
    "Payment details": "付款详情",
    "Cancel": "取消",
    "Cancel Request?": "取消请求？",
    "Are you sure you want to cancel this request?": "您确定要取消该请求吗？",
    "Cancel Request": "取消请求",
    "Are you sure you want to send this request?": "您确定要发送该请求吗？",
    "Book Now": "立即预订",
    "Coming soon!": "即将到来！",
    "Book": "预订",
    "Select time": "选择时间",
    "Payment Status": "付款状态",
    "Overview": "概况",
    "Collect Payment Via QR Code": "通过二维码收款",
    "OK": "好的",
    "Ask customer to scan for Payment": "客户需扫描付款",
    "Paid": "已支付",
    "Not Paid (To be collected)": "未支付(待收款)",
    "Pending": "等待处理",
    "Payment": "付款",
    "Add Card": "添加卡",
    "Card": '卡',
    "Apple Pay / Google Pay": "苹果支付/谷歌支付",
    "Apple Pay": '苹果支付',
    "Google Pay": '谷歌支付',
    "Pay by Card": "刷卡支付",
    "Promocode Applied": "已使用促销码",
    "Payment options": "付款方式",
    "Apply": "使用",
    "+ Add new card": "+ 添加新卡",
    "Having a promo code?": "有促销码吗？",
    "Card number": "卡号",
    "Expiration date": "截止日期",
    "No Available Slots": "无可用档期",
    "Please check different date to see availability": "查看不同日期以确认是否为空档期",
    "No appointments": "没有预约",
    "No Saved Locations": "无保存地点",
    "Place an order to add your location": "请下单以添加地点",
    "No Saved Cards": "无保存卡",
    "Place an order to add your Card": "请下单以添加卡",
    "Available Services": '可用服务',
    "My Profile": '我的资料',
    "Refer friends & family": '推荐朋友和家人',
    "Add": '添加',
    "Similar services": '类似服务',
    "View all": '查看全部',
    "Description": '说明',
    "Report Delay": '报告延迟',
    "Rate Booking experience": '对预订体验进行评价',
    "PromoCode": '促销码',
    "Pharmacy": '药店',
    "Cart": '购物车',
    "Caution": '提醒',
    "Hi there": "您好👋",
    "Confirm": '确认',
    "Refer Now": '立即参考',
    "Add new Location": "添加新地点",
    "Select": '选择',
    "Sorry": '抱歉',
    "Select Location": '选择地点',
    "Pick location on map": '在地图上选择地点',
    "Add a location from the map": "从地图中添加地点",
    'Check Next Date': '检查下一日期',
    "Location": '地点',
    "Personal info": '个人信息',
    "Total Credit": '总积分',
    "Expiry date:": '截止日期：',
    "referFriend": '推荐朋友并获得 {0}迪拉姆',
    "yourFriendWillGet": '您的朋友在注册时将有 {0} 迪拉姆转入他们的个人钱包',
    "No Records Found": '未找到记录',
    "getUpTo": '邀请好友最多可获得 {0} 迪拉姆积分',
    "InviteYourFriends": '您邀请朋友通过您的共享链接加入时，将有{0} 迪拉姆转入您的个人钱包，另外还有 {1} 迪拉姆。',
    "One more thing!": '还有件事！',
    'youWillReceiveExtra': '当他们完成第一个订单时，您可以获得额外的 {0} 迪拉姆。',
    "Invite Friends": '邀请朋友',
    "Search": '搜索',
    "No results found": '未找到结果',
    "We couldn’t find what you are looking for. please try again": '我们找不到您要查找的内容。请重试',
    "Recent search": '近期搜索',
    "Appointments": '预约',
    "Explore Services": '浏览服务',
    'No bookings': '没有预订',
    "booking scheduled": "已安排的预订",
    "Terms of Use": '使用条款',
    "I have read and agree to the Metadoc": '我已阅读并同意 Metadoc {0} 和 {1}',
    "Pick current location": '选择当前位置',
    "Address Details": '地址详情',
    "Congratulations": "祝贺你",
    "Sorry!": '抱歉！',
    "Update Required": "需要更新",
    "Update": '更新',
    "New Version": "新版本的 Metadoc 应用程序现已推出。请立即更新到最新版本。",
    "Signup": '注册',
    "Your Gift is waiting": '好礼等着您',
    "Gift Received": '已收到礼物🎁',
    "Credit already received!": '已收到积分！',
    "Get more credit": '获取更多积分',
    "You have already received your invitation gift, but you can still get more reward credit by inviting friends.": '您已收到邀请礼物，不过您仍然可以通过邀请朋友来获得更多奖励积分。',
    "This link is yours!": '这是您的链接！',
    "Share this link with your friends and family to get even more free credit in your wallet": '您可把该链接分享给朋友和家人，以获取更多免费积分转入您的个人钱包',
    "Explore services": '浏览服务',
    "Your friend {0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!": "您的朋友 {0} 已邀请您加入 Metadoc。 您的钱包中有 {1} 迪拉姆。 立即注册！",
    "{0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!": "{0} 已邀请您加入 Metadoc。 您的钱包中有 {1}迪拉姆。 立即注册！",
    "We apologize for any inconvenience, Some of selected services are currently not served in your area at the moment.": "我们为给您带来的不便表示歉意，目前在您所在地区暂不提供部分服务。",
    "Unfortunately, the selected service is currently unavailable in your area":"我们为给您带来的不便表示歉意，目前在您所在地区暂不提供部分服务。",
    'Wallet': '钱包',
    'Saved Location detected': '检测到已保存的地点',
    'Use saved location': '使用已保存的地点',
    'Add New Location': '添加新地点',
    'Got it': '知道了',
    "yourProfessional": "您的专业人员将在 {0}",
    'History': '历史记录',
    "Referral Points": "推荐积分",
    "Friend Order Reward": "朋友订单奖励",
    "Signup Reward": "注册奖励",
    "Friend Signup": "朋友注册",
    "Use wallet credit": '使用钱包积分',
    "View Details": '查看详情',
    "Phone Number": '电话号码',
    "ValidTill": "有效期至 {0}",
    "Redeem Code": '兑换代码',
    "You have already enrolled in this program": '您已报名参加此计划',
    "Please enter valid promocode!": '请输入有效促销码！',
    "Promocode already used": '已使用促销码',
    "Promocode expired!": '促销码已过期！',
    "You exceeded maximum number of redemption!": '您已超过兑换上限!',
    "Promocode is not applied on selected services or variations!": '促销码不适用于部分服务或变更！',
    "Error while processing your request!": '处理请求时出错！',
    "Please enter your contact details": '请输入您的联系方式',
    "Alert": '提示信号',
    "Request Completed": '请求已完成',
    "How was your booking experience": '您的预订体验如何',
    "Please rate below": '请在下方进行评价',
    "Rate Your experience": '对您的体验进行评价',
    "This will help us improve our services": '有助于我们改善服务',
    "Having any inquiry": '有任何疑问',
    "contact us": '联系我们',
    "Order ID:": '订单号：',
    "Refund": '退款',
    "Similar Products": '类似产品',
    'We are not currently serving this area, but we are expanding very fast, check us later!': '目前我们在该地区没有提供服务，我们正努力扩大服务范围，请在之后再查看！',
    'We apologize for any inconvenience, but we are not serving your area at the moment!': '我们为给您带来的不便道歉，目前我们没有为您的所在地区提供服务!',
    "Ohh Sorry!": '抱歉！',
    "Hi": '嗨👋',
    'Book now': '现在预订',
    "Subscriptions": '订阅',
    "Upgrade to Pro": '升级到专业版',
    "Subscribe": '订阅',
    "Settle Now": '现在定居',
    "Contact Info": '联系方式',
    "Profile info": '资料信息',
    "This service require pro subscription, Subscribe now to access this service": '此服务需要专业订阅，立即订阅以访问此服务',
    "/per Month": '/每月',
    "Days Trial": '天试用',
    "Subscribed": '已订阅',
    "Congrats, You are successfully subscribed to Metadoc Plus": '恭喜，您已成功订阅 Metadoc Plus',
    "Manage Subscriptions": '管理订阅',
    "Invoices": '发票',
    "Subscription": '订阅',
    "Cancel Subscription": '取消订阅',
    "Renewal date:": '更新日期：',
    "Why you are cancelling": '为什么要取消',
    "Later": '之后',
    "Know more": '了解更多',
    "Current plan": '当前计划',
      "New": 'New',
  "General information": 'General information',
  "Full Name": 'Full Name',
  "Date of birth": 'Date of birth',
  "Gender": 'Gender',
  "male": 'male',
  "female": 'female',
  "We apologize for any inconvenience, Please Contact us to process your request": 'We apologize for any inconvenience, Please Contact us to process your request',
  "See all": 'See all',
  "Severity:": 'Severity:',
  "Start Date:": 'Start Date:',
  "Diagnosis date:": 'Diagnosis date:',
  "Height:": 'Height:',
  "Weight:": 'Weight:',




}
