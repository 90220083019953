export default {
  "morning": "صباح",
  "afternoon": "بعد الظهر",
  "evening": "مساء",
  "dismiss": "انصرف",
  "searchPlace": "ابحث عن مكان",
  "confirmLocation": "تأكيد الموقع",
  "yourName": "اسمك",
  "yourNumber": "رقمك",
  "yourEmail": "بريدك الالكتروني",
  "area": "منطقة",
  "street": "شارع",
  "buildingName": "اسم المبنى",
  "floor": "أرضية",
  "flat": "طابق",
  "villa": 'فيلا',
  "instruction": "تعليمات إضافية",
  "nickName": 'كنية',
  "Home": "مسكن",
  "Work": "عمل",
  "Other": "آخر",
  "saveAddress": "حفظ العنوان",
  "Bookings": "الحجوزات",
  "Locations": "العناوين",
  "Payment Methods": "طرق الدفع",
  "Language": "اللغة",
  "Contact Us": "اتصل بنا",
  "Metrics": "المقاييس",
  "Logout": "تسجيل خروج",
  "Login": "تسجيل الدخول",
  "Staff login": "تسجيل دخول الموظفين",
  "Upcoming": "القادمة",
  "All": "الجميع",
  "Completed": "مكتمل",
  "Failed": "Failed",
  "Placed On": "Placed On",
  "PENDING PAYMENT": "في انتظار الدفع",
  "Pending Payment": "في انتظار الدفع",
  "payment link is sent for your guest and we are awaiting payment to continue": "تم إرسال رابط الدفع لضيفك ونحن في انتظار الدفع للمتابعة",
  "Your booking is received": "تم استلام الحجز الخاص بك",
  "Confirmed": "مؤكد",
  "We will match you with top-rated professionals in your area": "سنطابقك مع أفضل المحترفين في منطقتك",
  "Service provider": "مقدم الخدمة",
  "Assigned": "تعيين",
  "In Progress": "In Progress",
  "On the way": "فى الطريق",
  "Session started": "بدأت الجلسة",
  "Your order is Completed": "طلبك مكتمل",
  "Your request has been completed successfully": "تم استكمال طلبك بنجاح",
  "Your booking is Canceled": "تم إلغاء حجزك",
  "Canceled": "ألغيت",
  "Your booking is Rejected": "حجزك مرفوض",
  "Rejected": "مرفوض",
  "Flat": "شقة",
  "Room": "غرفة",
  "Call us": "اتصل بنا",
  'Reach us': 'تواصل معنا',
  "Whatsapp": "Whatsapp",
  "English": "English",
  "Arabic": "عربي",
  "Warning": "تحذير",
  "Are you sure ,This locations will be deleted permanently": "هل أنت متأكد ، سيتم حذف هذه المواقع بشكل دائم",
  "Delete": "حذف",
  "Are you sure ,This card will be deleted permanently": "هل أنت متأكد ، سيتم حذف هذه البطاقة نهائيًا",
  "Profile": "حساب تعريفي",
  "Order History": "تاريخ الطلب",
  "Language": "لغة",
  "Contact Us": "اتصل بنا",
  "Services": "خدمات",
  "AED": "درهم",
  "Subtotal": "المجموع الفرعي",
  "Discount": "خصم (رصيد)",
  "DiscountCode": "خصم",

  "VAT": "ضريبة القيمة المضافة",
  "Total": "المجموع",
  "View items": "عرض العناصر",
  "Continue": "استمر",
  "Pay now": "ادفع الآن",
  "Share payment link": "مشاركة رابط الدفع",
  "Schedule Appointment": "جدولة موعد",
  "Step": "خطوة",
  "out of": "من",
  "Welcome": "أهلا بك",
  "Phone verification": "التحقق من الهاتف",
  "Please enter your mobile number to login": "الرجاء إدخال رقم هاتفك المحمول لتسجيل الدخول",
  "Enter your OTP code here": "أدخل رمز OTP الخاص بك هنا",
  "This site is protected by reCAPTCHA and the Google": "هذا الموقع محمي بواسطة reCAPTCHA و Google",
  "Privacy Policy": "سياسة الخصوصية",
  "and": "و",
  "Terms of Service": "شروط الخدمة",
  "apply": "تطبق",
  "Reschedule Request": "طلب إعادة الجدولة",
  "RescadualMsg": "ستتم إعادة جدولة طلبك إلى {0} في {1}",
  "Reschedule": "إعادة الجدولة",
  "Select preferred Date and Time": "حدد التاريخ والوقت المفضل",
  "Do you have any specific instructions?": "هل لديك أي تعليمات محددة؟",
  "Incomplete data": "بيانات غير مكتملة",
  "Please select date and time to proceed": "يرجى تحديد التاريخ والوقت للمتابعة",
  "Please select Date first": "الرجاء تحديد التاريخ أولاً",
  "Contact Person Name": "اسم شخص الاتصال",
  "Room Number": "رقم الغرفة",
  "Building": "مبنى",
  "Your Customer will receive status updates": "سيتلقى عميلك تحديثات الحالة",
  "Contact Person Number": "رقم جهة الاتصال",
  "Email": "بريد الالكتروني",
  "Customer information": "معلومات العميل",
  "Please fill all required fields": "يرجى تعبئة جميع الحقول المطلوبة",
  "Ask the customer to pay by sharing the payment link with them with auto generated payment link": "اطلب من العميل الدفع عن طريق مشاركة رابط الدفع معهم باستخدام رابط الدفع المُنشأ تلقائيًا",
  "Complete the payment on behalf of the customer using the online payment gateway": "أكمل الدفع نيابة عن العميل باستخدام بوابة الدفع عبر الإنترنت",
  "Auto-generate payment link can be shared with anyone to pay on your behalf": "يمكن مشاركة رابط الدفع الذي يتم إنشاؤه تلقائيًا مع أي شخص للدفع نيابة عنك",
  "Complete the payment using the online payment gateway": "أكمل الدفع باستخدام بوابة الدفع عبر الإنترنت",
  "Payment Options": "خيارات الدفع",
  "Payment Details": 'بيانات الدفع',

  "Please check entered Phone Number in previous step": "يرجى التحقق من إدخال رقم الهاتف في الخطوة السابقة",
  "Please try again later or contact us": "يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بنا",
  "Where do you want to have the service": "أين تريد الحصول على الخدمة",
  "+ Add new address": "+ أضف عنوان جديد",
  "Please select your address": "الرجاء تحديد عنوانك",
  "Details": "تفاصيل",
  "You need to complete the payment in order to process your request": "تحتاج إلى إكمال الدفع من أجل معالجة طلبك",
  "Order Status": "حالة الطلب",
  "Order Details": "تفاصيل الطلب",
  "Pay Now": "ادفع الآن",
  "Order ID": "رقم بالطلب",
  "Customer Name": "اسم العميل",
  "Customer Number": "رقم العميل",
  "Service": "خدمة",
  "Service Details": "تفاصيل الخدمة",
  "Service Provider": "مقدم الخدمة",
  "Instructions": "تعليمات",
  "Address": "عنوان",
  "Flat number:": "رقم الشقة:",
  "Room number:": "رقم الغرفة:",
  "Date & Time": "التاريخ والوقت",
  "Payment details": "بيانات الدفع",
  "Cancel": "إلغاء",
  "Cancel Request?": "إلغاء الطلب؟",
  "Are you sure you want to cancel this request?": "هل أنت متأكد أنك تريد إلغاء هذا الطلب؟",
  "Cancel Request": "إلغاء الطلب",
  "Are you sure you want to send this request?": 'هل أنت متأكد أنك تريد إرسال هذا الطلب؟',
  "Book Now": 'احجز الآن',
  "Coming soon!": 'قريبا!',
  "Book": 'حجز',
  "Select time": 'حدد الوقت',
  "Payment Status": 'حالة السداد',
  "Overview": 'نظرة عامة',
  "Collect Payment Via QR Code": 'Collect Payment Via QR Code',
  "OK": 'تم',
  "Ask customer to scan for Payment": 'اطلب من العميل إجراء مسح للدفع',
  "Paid": 'مدفوع',
  "Not Paid (To be collected)": 'غير مدفوعة (يتم تحصيلها)',
  "Collect Payment Via QR Code": 'تحصيل المدفوعات عبر رمز الاستجابة السريعة',
  "Pending": 'قيد الانتظار',
  "Payment": 'الدفع',
  "Add Card": 'إضافة بطاقة',
  "Card": 'بطاقة',
  "Apple Pay / Google Pay": 'Apple Pay / Google Pay',
  "Apple Pay": 'Apple Pay',
  "Google Pay": 'Google Pay',
  'Pay by Card': 'الدفع عن طريق البطاقة',
  "Promocode Applied": 'Promocode Applied',
  "Payment options": 'خيارات الدفع',
  "Apply": 'تفعيل',
  "+ Add new card": '+ اضافة بطاقة جديدة',
  "Having a promo code?": 'هل لديك رمز ترويجي؟',
  "Card number": 'رقم البطاقة',
  "Expiration date": 'تاريخ إنتهاء',
  "No Available Slots": 'لا توجد مواعيد متاحة',
  "Please check different date to see availability": 'يرجى تغير التاريخ لايجاد مواعيد متاحة',
  "No appointments": 'لا توجد مواعيد',
  "No Saved Locations": 'لايوجد عناوين محفوظة',
  "Place an order to add your location": 'احجز موعدا و سيتم إضافة موقعك',
  "No Saved Cards": 'لا توجد بطاقات محفوظة',
  "Place an order to add your Card": 'احجز موعدا و سيتم إضافة بطاقتك',
  "Failed": 'ملغي',
  "Available Services": 'الخدمات المتاحة',
  "My Profile": 'ملفي',
  "Wallet": 'محفظتي',
  "Refer friends & family": 'قم بدعوة الأصدقاء والعائلة',
  "Add": 'إضافة',
  "Similar services": 'خدمات مماثلة',
  "View all": 'عرض الكل',
  "Description": 'تفاصيل',
  "Report Delay": 'الإبلاغ عن التأخير',
  "Rate Booking experience": 'قيم تجربة الحجز',
  "PromoCode": 'رمز ترويجي',
  "Pharmacy": 'الصيدلة',
  "Cart": 'عربة التسوق',
  "Caution": 'تحذير',
  "Hi there": "مرحبًا👋",
  "Confirm": 'تاكيد',
  "Refer Now": 'ارسال دعوة',
  "Add new Location": "إضافة موقع جديد",
  "Select": 'اختار',
  "Sorry": 'معذرة!',
  "Select Location": 'اختر موقعا',
  "Pick location on map": 'اختر الموقع على الخريطة',
  "Add a location from the map": "أضف موقعًا من الخريطة",
  'Check Next Date': 'تحقق من التاريخ التالي',
  "Location": 'موقع',
  "Personal info": 'معلومات شخصية',
  "Total Credit": 'إجمالي الرصيد',
  "Expiry date:": 'تاريخ انتهاء الصلاحية:',
  "referFriend": 'قم بدعوة صديق واحصل على {0} درهم إماراتي',
  "yourFriendWillGet": 'سيحصل أصدقاؤك على {0} درهم إماراتي في محفظتهم عند الاشتراك',
  "No Records Found": 'لا توجد سجلات',
  "getUpTo": 'احصل على رصيد يصل إلى {0} درهم إماراتي عند دعوة أصدقائك',
  "InviteYourFriends": 'قم بدعوة أصدقائك واحصل على {0} درهم إماراتي تضاف إلى محفظتك عندما ينضم صديقك من الرابط المشترك الخاص بك ، بالإضافة إلى {1} درهم إماراتي لهم أيضًا.',
  "One more thing!": 'شيء اخر!',
  'youWillReceiveExtra': 'سوف تتلقى {0} درهمًا إماراتيًا إضافيًا عند إكمال الطلب الأول.',
  "Invite Friends": 'ادعو أصدقاء',
  "Search": 'بحث',
  "No results found": 'لم يتم العثور على نتائج',
  "We couldn’t find what you are looking for. please try again": 'لم نتمكن من العثور على ما تبحث عنه. حاول مرة اخرى',
  "Recent search": 'البحث الأخير',
  "Appointments": 'المواعيد',
  "Explore Services": 'اكتشف خدماتنا',
  "Got it": 'تم',
  'No bookings': 'لا حجوزات مجدولة لهذا اليوم',
  "booking scheduled": "الحجوزات المقررة اليوم",
  "Terms of Use": 'شروط الاستخدام',
  "I have read and agree to the Metadoc": 'لقد قرأت ووافقت على {0} و{1} الخاصة بـ Metadoc',
  "Pick current location": 'اختر الموقع الحالي',
  "Address Details": 'تفاصيل العنوان',
  "Congratulations": "تهانينا",
  "Sorry!": 'معذرة!',
  "Update Required": "مطلوب التحديث",
  "Update": 'تحديث',
  "New Version": "إصدار جديد من تطبيق Metadoc متاح الآن. يرجى التحديث إلى أحدث إصدار الآن.",
  "Signup": 'سجل الان',
  "Your Gift is waiting": 'هديتك تنتظر',
  "Gift Received": 'تم استلام الهدية 🎁',
  "Credit already received!": 'تم استلام الهدية بالفعل!',
  "Get more credit": 'احصل على رصيد أكثر',
  "You have already received your invitation gift, but you can still get more reward credit by inviting friends.": 'لقد تلقيت بالفعل هدية الدعوة الخاصة بك ، ولكن لا يزال بإمكانك الحصول على المزيد من رصيد المكافأة عن طريق دعوة الأصدقاء.',
  "This link is yours!": 'هذا الرابط لك!',
  "Share this link with your friends and family to get even more free credit in your wallet": 'شارك هذا الرابط مع أصدقائك وعائلتك للحصول على المزيد من الرصيد المجاني في محفظتك',
  "Explore services": 'اكتشف الخدمات',
  "Your friend {0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!": "دعاك صديقك {0} للانضمام إلى Metadoc. لديك {1} درهم إماراتي في انتظارك في محفظتك. أفتح حساب الأن!",
  "{0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!": "دعاك {0} للانضمام إلى Metadoc. لديك {1} درهم إماراتي في انتظارك في محفظتك. أفتح حساب الأن!",
  "We apologize for any inconvenience, Some of selected services are currently not served in your area at the moment.": 'نعتذر عن أي إزعاج ، فبعض الخدمات المحددة غير متوفرة حاليًا في منطقتك في الوقت الحالي.',
  "Unfortunately, the selected service is currently unavailable in your area":'نعتذر عن أي إزعاج ، فبعض الخدمات المحددة غير متوفرة حاليًا في منطقتك في الوقت الحالي.',
  'Saved Location detected': 'تم الكشف عن الموقع المحفوظ',
  'Use saved location': 'استخدم الموقع المحفوظ',
  'Add New Location': 'أضف موقع جديد',
  "yourProfessional": "سيصل المهني الخاص بك في الفترة ما بين {0}",
  'History': 'السجلات',
  "Referral Points": "نقاط الإحالة",
  "Friend Order Reward": "مكافأة طلب الصديق",
  "Signup Reward": "مكافأة التسجيل",
  "Friend Signup": "تسجيل صديق",
  "Use wallet credit": 'استخدم رصيد المحفظة',
  "View Details": 'عرض التفاصيل',
  "Phone Number": 'رقم الهاتف',
  "ValidTill": "صالح لغاية {0}",
  "Redeem Code": 'استخدام الرمز',
  "You have already enrolled in this program": 'لقد قمت بالفعل بالتسجيل في هذا البرنامج',
  "Please enter valid promocode!": 'الرجاء إدخال رمز ترويجي صالح!',
  "Promocode already used": 'الرمز الترويجي مستخدم بالفعل',
  "Promocode expired!": 'انتهت صلاحية الرمز الترويجي!',
  "You exceeded maximum number of redemption!": 'لقد تجاوزت الحد الأقصى لعدد الاسترداد!',
  "Promocode is not applied on selected services or variations!": 'لا يتم تطبيق الرمز الترويجي على الخدمات أو المتغيرات المختارة!',
  "Error while processing your request!": 'خطأ أثناء معالجة طلبك!',
  "Please enter your contact details": 'الرجاء إدخال تفاصيل الاتصال الخاصة بك',
  "Alert": 'تنبيه',
  "Request Completed": 'الطلب إكتمل',
  "How was your booking experience": 'كيف كانت تجربة الحجز الخاصة بك',
  "Please rate below": 'يرجى التقييم أدناه',
  "Rate Your experience": 'قيم تجربتك',
  "This will help us improve our services": 'سيساعدنا هذا في تحسين خدماتنا',
  "Having any inquiry": 'وجود أي استفسار',
  "contact us": 'اتصل بنا',
  "Order ID:": 'رقم الطلب:',
  "Refund": 'استرداد',
  "Similar Products": 'منتجات مماثلة',
  'We are not currently serving this area, but we are expanding very fast, check us later!': 'نحن لا نخدم هذه المنطقة حاليًا ، لكننا نتوسع بسرعة كبيرة ، تحقق منا لاحقًا!',
  'We apologize for any inconvenience, but we are not serving your area at the moment!': 'نعتذر عن أي إزعاج ، لكننا لا نخدم منطقتك في الوقت الحالي!',
  "Ohh Sorry!": 'أوه عذرا!',
  "Hi": 'مرحبًا 👋',
  "Cash": 'نقدي',
  'Book now': 'احجز الآن',
  "Subscriptions": 'الاشتراكات',
  "Upgrade to Pro": 'الترقية إلى Metadoc pro',
  "Subscribe": 'إشترك',
  "Settle Now": 'تسوية الآن',
  "Contact Info": 'معلومات الاتصال',
  "Profile info": 'الملف الشخصي',
  "This service require pro subscription, Subscribe now to access this service": 'تتطلب هذه الخدمة اشتراكًا ، اشترك الآن للوصول إلى هذه الخدمة',
  "/per Month": '/كل شهر',
  "Days Trial": 'أيام تجريبية',
  "Subscribed": 'تم الاشتراك بنجاح',
  "Congrats, You are successfully subscribed to Metadoc Plus": 'تهانينا ، لقد تم اشتراكك بنجاح في Metadoc Plus',
  "Manage Subscriptions": 'إدارة الاشتراكات',
  "Invoices": 'الفواتير',
  "Subscription": 'الاشتراك',
  "Cancel Subscription": 'إلغاء الاشتراك',
  "Renewal date:": 'تاريخ التجديد:',
  "Why you are cancelling": 'لماذا تقوم بإلغاء',
  "Later": 'لاحقاً',
  "Know more": 'اعرف أكثر',
  "Current plan": 'الخطة الحالية',
  "New": 'جديد',
  "General information": 'معلومات عامة',
  "Full Name": 'الاسم الكامل',
  "Date of birth": 'تاريخ الميلاد',
  "Gender": 'الجنس',
  "male": 'ذكر',
  "female": 'أنثى',
  "We apologize for any inconvenience, Please Contact us to process your request": 'نعتذر عن أي إزعاج ، يرجى الاتصال بنا لمعالجة طلبك',
  "See all": 'اظهار الكل',
  "Severity:": 'خطورة:',
  "Start Date:": 'تاريخ البدء:',
  "Diagnosis date:": 'تاريخ التشخيص:',
  "Height:": 'ارتفاع:',
  "Weight:": 'الوزن:',

  "Drug allergy": 'حساسية من الدواء',
  "Chronic conditions": 'الأمراض المزمنة',
  "Current medications": 'الأدوية الحالية',
  "BMI": 'مؤشر كتلة الجسم',
  "Add new": 'اضف جديد',
  "Start Session": 'بدء الجلسة',
  "Save": 'حفظ',
  "Edit your": 'تعديل {0}',
  "Add your": 'أضف {0}',
  "Name": 'الاسم',
  "Severity": 'الشدة',
  "Condition name": 'اسم العرض',
  "Diagnosis date": 'تاريخ التشخيص',
  "Resolution date": 'تاريخ القرار',
  "Medication name": 'اسم الدواء',
  "Instructions": 'تعليمات',
  "Starting date": 'تاريخ البدء',
  "Height": 'ارتفاع',
  "Weight": 'وزن',
  "Date": 'تاريخ',
  "Set Date": 'تعيين التاريخ',
  "Your medical info": 'معلوماتك الطبية',
  "Medical Information": 'معلومات طبية',






};


