import { Image, View } from 'native-base';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { useDispatch } from 'react-redux';
import { SetAlertMessage } from '../../reducers/settings';
import ActionBtn from './ActionBtn';
import Modal from './Modal/Modal';
import Text from './TextLabel';

const StausNotification = ({ title, details, onConfirm, center = false, status = 'success', confirmText, img = (status === 'success' ? require('../../assets/Success.jpg') : require('../../assets/Error.jpg')) }) => {
    const dispatch = useDispatch();
    const { width } = useWindowDimensions()
    return (
        <Modal fixed={true} dismiss={() => dispatch(SetAlertMessage(false))} size={'full'}>
            <View position={'absolute'} width={'100%'} bottom={center ? 'auto' : 20} padding={4} >
                <View background='#fff' borderRadius={14} paddingTop={8} paddingBottom={4} alignItems={'center'}>
                    <Image width={width * 0.25} height={width * 0.25} source={img} />
                    <View width={'95%'} alignItems='center' padding={4} paddingBottom={8}>
                        <Text fontFamily='bold' fontSize={24}>
                            {title}
                        </Text>
                        <Text paddingTop={2} fontFamily='medium' fontSize={16} textAlign='center'>
                            {details}
                        </Text>

                    </View>
                    <View paddingX={4} flexDirection='row' alignItems='center'>
                        <ActionBtn onPress={onConfirm} relative title={confirmText} />
                    </View>
                </View>
            </View>
        </Modal>
    );
};

export default StausNotification;