import { strings } from '../translations/index';
import { createAction } from 'redux-action';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const localeSetLanguage = createAction(SET_LANGUAGE, (lang) => {
    return lang
});

const locales = [
    { locale: 'en', label: 'English' },
    { locale: 'ar', label: 'Arabic' },
    { locale: 'zh-CN', label: 'Chinese' },
    { locale: 'rus', label: 'Russian' },
];

const INITIAL_STATE = {
    locales: locales,
    strings: strings,
    isSet: false
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case SET_LANGUAGE:
            return { strings, locale: action.payload, isSet: true };
        default:
            return state;
    }
};

