import { useNavigation } from '@react-navigation/native';
import { Flex, Pressable, ScrollView, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../../constants/firebase/config';
import { Chat, Language, LocationBackgrounded, Logout, MetricsSVG, PaymentCardsSVG, ProfileSVG, RecentSVG, ReferralSVG } from '../../../constants/imgs';
import { FlushUser } from '../../../reducers/user';
import Text from '../../shared/TextLabel';
import MenuCell from './com/menuCell';
import StaffLogin from '../login/loginSheet'
import CustomerLogin from '../login/CustomerLogin'
import { SetLoginRequired } from '../../../reducers/settings';
import { strings } from '../../../translations/index';

const Index = () => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const [login, toggleLogin] = useState(false);
    const [Menu, setMenu] = useState([]);
    useEffect(() => {
        if (user.uid) {
            setMenu([
                {
                    title: 'My Profile',
                    key: 'contact-info',
                    img: ProfileSVG
                },
                {
                    title: 'Bookings',
                    key: 'bookings-history',
                    img: RecentSVG
                },
                {
                    title: 'Locations',
                    key: 'locations',
                    img: LocationBackgrounded
                },
                {
                    title: 'Payment Methods',
                    key: 'paymentMethods',
                    img: PaymentCardsSVG
                },
                // {
                //     title: 'Wallet',
                //     key: 'wallet',
                //     img: ReferralSVG
                // },
                {
                    title: 'Language',
                    key: 'language',
                    img: Language
                },
                {
                    title: 'Contact Us',
                    key: 'chat',
                    img: Chat
                },
            ])
        } else {
            setMenu([

                {
                    title: 'Language',
                    key: 'language',
                    img: Language
                },
                {
                    title: 'Contact Us',
                    key: 'chat',
                    img: Chat
                }
            ])
        }

    }, [user])

    return (
        <Flex flex={1} height={window.innerHeight}>
            {
                login === 'staff' ?
                    <StaffLogin dismiss={() => toggleLogin(false)} />
                    // : login === 'customer' ?
                    //     <CustomerLogin fixed={true} dismiss={() => toggleLogin(false)} />
                    :
                    <></>
            }
            <ScrollView flex={1} contentContainerStyle={{ paddingBottom: 100 }} >
                <View paddingX={4}>
                    <Text fontFamily='semiBold' paddingTop={8} paddingBottom={4} >
                        {strings?.["Welcome"]}
                    </Text>

                    {
                        Menu.map(rec => <Pressable key={rec.key} paddingBottom={4} onPress={() => navigation.navigate(rec.key)}>
                            <MenuCell data={rec} />
                        </Pressable>)
                    }
                </View>
                {
                    auth().currentUser?.uid ?
                        <Pressable paddingY={2} paddingX={4} onPress={async () => {
                            await auth().signOut();
                            dispatch(FlushUser());
                            navigation.reset({
                                index: 0,
                                routes: [{ name: 'Home' }],
                            })
                        }} >
                            <MenuCell data={{
                                title: 'Logout',
                                key: 'logout',
                                img: Logout
                            }} />
                        </Pressable>
                        :
                        <>
                            <Pressable paddingX={4} onPress={async () => {
                                dispatch(SetLoginRequired())
                            }} >
                                <MenuCell data={{
                                    title: 'Login',
                                    key: 'login',
                                    img: Logout
                                }} />
                            </Pressable>
                            <View height={2} marginY={6} background={'#E4EEF8'} />
                            <Pressable paddingX={4} onPress={async () => {
                                toggleLogin('staff')
                            }} >
                                <MenuCell data={{
                                    title: 'Staff login',
                                    key: 'staffLogin',
                                    img: Logout
                                }} />
                            </Pressable>
                        </>
                }




            </ScrollView>
        </Flex>
    );
};

export default Index;