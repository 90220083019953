import { View } from 'native-base';
import React from 'react';
import Text from './TextLabel';

const EmptyState = ({ title, msg }) => {
    return (
        <View flex={1} justifyContent='center' alignItems={'center'}>
            <View paddingTop={'50%'} width={'70%'} paddingBottom={100}>
                <Text textAlign='center' fontSize={16} color='#0F172A' fontFamily='semiBold'>{title}</Text>
                <Text paddingTop={2} textAlign='center' fontFamily='Regular' style={{ color: '#C6C6C6' }}>{msg}</Text>
            </View>
        </View>
    );
};

export default EmptyState;