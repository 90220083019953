import React, { useEffect, useMemo, useState } from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
// import './index.css'
import Text from "../shared/TextLabel";
import { ScrollView, View } from "native-base";
import ActionBtn from "../shared/ActionBtn";

const useOptions = () => {
    const fontSize = '16px';
    const options = useMemo(
        () => ({
            style: {

                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Poppins-Regular",
                    "::placeholder": {
                        color: "#cacaca",
                        fontFamily: 'Poppins-Regular,sans-serif',
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }),
        [fontSize]
    );

    return options;
};

const OneTimeForm = ({ enabled }) => {

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const [state, setState] = useState({})
    const handleSubmit = async () => {

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement)
        });

        return payload?.paymentMethod?.id
    };
    useEffect(() => {
        if (stripe && (state?.cardNumber && state?.cardExpiry && state?.cardCvc)) {
            enabled({ disabled: false, elements: elements })
        } else {
            enabled({ disabled: true, elements: null })
        }
    }, [stripe, state?.cardNumber, state?.cardExpiry, state?.cardCvc])

    return (
        <View flex={1}>
            <ScrollView flex={1} minHeight={'50vh'}>
                <View>
                    <Text>Card number</Text>
                    <View borderRadius={6} borderColor='#cacaca' padding={2} borderWidth={1}>
                        <CardNumberElement
                            options={options}
                            onChange={event => {
                                setState({ ...state, [event.elementType]: event.complete })
                            }}
                        />
                    </View>
                </View>
                <View flexDirection={'row'} justifyContent='space-between' paddingTop={2}>
                    <View w={'48%'}>
                        <Text fontSize={14}>Expiration date</Text>
                        <View borderRadius={6} borderColor='#cacaca' padding={2} borderWidth={1}>

                            <CardExpiryElement
                                options={options}
                                onChange={event => {
                                    setState({ ...state, [event.elementType]: event.complete })
                                }}
                            />
                        </View>
                    </View>
                    <View w={'48%'}>
                        <Text>CVV</Text>
                        <View borderRadius={6} borderColor='#cacaca' padding={2} borderWidth={1}>
                            <CardCvcElement
                                options={options}
                                onChange={event => {
                                    setState({ ...state, [event.elementType]: event.complete })
                                    // console.log("CardNumberElement [change]", event);
                                }}
                            />
                        </View>
                    </View>
                </View>

            </ScrollView>

            {/* <ActionBtn disabled={!stripe || !(state?.cardNumber && state?.cardExpiry && state?.cardCvc)} title='Add Card' onPress={() => {
                handleSubmit()
            }} /> */}
        </View>
    );
};

export default OneTimeForm;
