export default {
  "morning": "Morning",
  "afternoon": "Afternoon",
  "evening": "Evening",
  "dismiss": "Dismiss",
  "searchPlace": "Search for place",
  "confirmLocation": "Confirm Location",
  "yourName": "Your Name",
  "yourNumber": "Your Number",
  "yourEmail": "Your Email",
  "area": "Area",
  "street": "Street",
  "buildingName": "Building Name",
  "floor": "Floor",
  "flat": "Flat",
  "villa": 'Villa',
  "instruction": "Additional Instructions",
  "nickName": 'Nickname',
  "Home": "Home",
  "Work": "Work",
  "Other": "Other",
  "saveAddress": "Save Address",
  "Bookings": "Bookings",
  "Locations": "Locations",
  "Payment Methods": "Payment Methods",
  "Language": "Language",
  "Contact Us": "Contact Us",
  "Metrics": "Metrics",
  "Logout": "Logout",
  "Login": "Login",
  "Staff login": "Staff Login",
  "Upcoming": "Upcoming",
  "All": "All",
  "Completed": "Completed",
  "Failed": "Failed",
  "Placed On": "Placed On",
  "PENDING PAYMENT": "PENDING PAYMENT",
  "Pending Payment": "Pending Payment",
  "payment link is sent for your guest and we are awaiting payment to continue": "payment link is sent for your guest and we are awaiting payment to continue",
  "Your booking is received": "Your booking is received",
  "Confirmed": "Confirmed",
  "We will match you with top-rated professionals in your area": "We will match you with top-rated professionals in your area",
  "Service provider": "Service Provider",
  "Assigned": "Assigned",
  "In Progress": "In Progress",
  "On the way": "On the way",
  "Session started": "Session Started",
  "Your order is Completed": "Your order is Completed",
  "Your request has been completed successfully": "Your request has been completed successfully",
  "Your booking is Canceled": "Your booking is Canceled",
  "Canceled": "Canceled",
  "Your booking is Rejected": "Your booking is Rejected",
  "Rejected": "Rejected",
  "Flat": "Flat",
  "Room": "Room",
  "Call us": "Call Us",
  'Reach us': 'Reach us',

  "Whatsapp": "Whatsapp",
  "English": "English",
  "Arabic": "Arabic",
  "Warning": "Warning",
  "Are you sure ,This locations will be deleted permanently": "Are you sure ,This locations will be deleted permanently",
  "Delete": "Delete",
  "Are you sure ,This card will be deleted permanently": "Are you sure ,This card will be deleted permanently",
  "Profile": "Profile",
  "Order History": "Order History",
  "Language": "Language",
  "Contact Us": "Contact Us",
  "Services": "Services",
  "AED": "AED",
  "Subtotal": "Subtotal",
  "Discount": "Discount (Credit)",
  "DiscountCode": "Discount",

  "VAT": "VAT",
  "Total": "Total",
  "View items": "View Items",
  "Continue": "Continue",
  "Pay now": "Pay Now",
  "Share payment link": "Share payment link",
  "Schedule Appointment": "Schedule Appointment",
  "Step": "Step",
  "out of": "out of",
  "Welcome": "Welcome",
  "Phone verification": "Phone Verification",
  "Please enter your mobile number to login": "Please enter your mobile number to login",
  "Enter your OTP code here": "Enter your OTP code here",
  "This site is protected by reCAPTCHA and the Google": "This site is protected by reCAPTCHA and the Google",
  "Privacy Policy": "Privacy Policy",
  "and": "and",
  "Terms of Service": "Terms of Service",
  "apply": "apply",
  "Reschedule Request": "Reschedule Request",
  "RescadualMsg": "Your request will be rescheduled to {0} at {1}",
  "Reschedule": "Reschedule",
  "Select preferred Date and Time": "Select preferred Date and Time",
  "Do you have any specific instructions?": "Do you have any specific instructions?",
  "Incomplete data": "Incomplete data",
  "Please select date and time to proceed": "Please select date and time to proceed",
  "Please select Date first": "Please select Date first",
  "Contact Person Name": "Contact Person Name",
  "Room Number": "Room Number",
  "Building": "Building",
  "Your Customer will receive status updates": "Your Customer will receive status updates",
  "Contact Person Number": "Contact Person Number",
  "Email": "Email",
  "Customer information": "Customer Information",
  "Please fill all required fields": "Please fill all required fields",
  "Ask the customer to pay by sharing the payment link with them with auto generated payment link": "Ask the customer to pay by sharing the payment link with them with auto generated payment link",
  "Complete the payment on behalf of the customer using the online payment gateway": "Complete the payment on behalf of the customer using the online payment gateway",
  "Auto-generate payment link can be shared with anyone to pay on your behalf": "Auto-generate payment link can be shared with anyone to pay on your behalf",
  "Complete the payment using the online payment gateway": "Complete the payment using the online payment gateway",
  "Payment Options": "Payment Options",
  "Payment Details": 'Payment Details',
  "Please check entered Phone Number in previous step": "Please check entered Phone Number in previous step",
  "Please try again later or contact us": "Please try again later or contact us",
  "Where do you want to have the service": "Where do you want to have the service",
  "+ Add new address": "+ Add new address",
  "Please select your address": "Please select your address",
  "Details": "Details",
  "You need to complete the payment in order to process your request": "You need to complete the payment in order to process your request",
  "Order Status": "Order Status",
  "Order Details": "Order Details",
  "Pay Now": "Pay Now",
  "Order ID": "Order ID",
  "Customer Name": "Customer Name",
  "Customer Number": "Customer Number",
  "Service": "Service",
  "Service Details": "Service Details",
  "Service Provider": "Service Provider",
  "Instructions": "Instructions",
  "Address": "Address",
  "Flat number:": "Flat Number:",
  "Room number:": "Room Number:",
  "Date & Time": "Date & Time",
  "Payment details": "Payment Details",
  "Cancel": "Cancel",
  "Cancel Request?": "Cancel Request?",
  "Are you sure you want to cancel this request?": "Are you sure you want to cancel this request?",
  "Cancel Request": "Cancel Request",
  "Are you sure you want to send this request?": "Are you sure you want to send this request?",
  "Book Now": "Book Now",
  "Coming soon!": "Coming Soon!",
  "Book": "Book",
  "Select time": "Select Time",
  "Payment Status": "Payment Status",
  "Overview": "Overview",
  "Collect Payment Via QR Code": "Collect Payment Via QR Code",
  "OK": "OK",
  "Ask customer to scan for Payment": "Ask customer to scan for Payment",
  "Paid": "Paid",
  "Not Paid (To be collected)": "Not Paid (To be collected)",
  "Pending": "Pending",
  "Payment": "Payment",
  "Add Card": "Add Card",
  "Card": 'Card',
  "Apple Pay / Google Pay": 'Apple Pay / Google Pay',
  "Apple Pay": 'Apple Pay',
  "Google Pay": 'Google Pay',
  "Pay by Card": "Pay by Card",
  "Promocode Applied": "Promocode Applied",
  "Payment options": "Payment Options",
  "Apply": "Apply",
  "+ Add new card": "+ Add new card",
  "Having a promo code?": "Having a promo code?",
  "Card number": "Card number",
  "Expiration date": "Expiration Date",
  "No Available Slots": "No Available Slots",
  "Please check different date to see availability": "Please check different date to see availability",
  "No appointments": "No Appointments",
  "No Saved Locations": "No Saved Locations",
  "Place an order to add your location": "Place an order to add your location",
  "No Saved Cards": "No Saved Cards",
  "Place an order to add your Card": "Place an order to add your Card",
  "Failed": "Failed",
  "Available Services": 'Available Services',
  "My Profile": 'My Profile',
  "Wallet": 'Wallet',
  "Refer friends & family": 'Refer friends & family',
  "Add": 'Add',
  "Similar services": 'Similar Services',
  "View all": 'View All',
  "Description": 'Description',
  "Report Delay": 'Report Delay',
  "Rate Booking experience": 'Rate Booking experience',
  "PromoCode": 'PromoCode',
  "Pharmacy": 'Pharmacy',
  "Cart": 'Cart',
  "Caution": 'Caution',
  "Hi there": "Hi there👋 ",
  "Confirm": 'Confirm',
  "Refer Now": 'Refer Now',
  "Add new Location": "Add New Location",
  "Select": 'Select',
  "Sorry": 'Sorry',
  "Select Location": 'Select Location',
  "Pick location on map": 'Pick location on map',
  "Add a location from the map": "Add a location from the map",
  'Check Next Date': 'Check Next Date',
  "Location": 'Location',
  "Personal info": 'Personal info',
  "Total Credit": 'Total Credit',
  "Expiry date:": 'Expiry date:',
  "referFriend": 'Refer a friend and get {0} AED',
  "yourFriendWillGet": 'Your friends will get {0} AED in their wallet on signup',
  "No Records Found": 'No Records Found',
  "getUpTo": 'Get up to AED {0} referral credit in your wallet for every friend you refer',
  "InviteYourFriends": 'Invite your friends and get {0} AED credited to your wallet when your friend join from your shared link, plus {1} AED for them too.',
  "One more thing!": 'One more thing!',
  'youWillReceiveExtra': 'you will receive extra {0} AED when they complete their first order.',
  "Invite Friends": 'Invite Friends',
  "Search": 'Search',
  "No results found": 'No results found',
  "We couldn’t find what you are looking for. please try again": 'We couldn’t find what you are looking for. please try again',
  "Recent search": 'Recent search',
  "Appointments": 'Appointments',
  "Saved Location detected": 'Saved Location detected',
  "Use saved location": 'Use saved location',
  "Add New Location": 'Add New Location',
  "Got it": 'Got it',
  "Explore Services": 'Explore Services',
  'No bookings': 'No bookings scheduled for today',
  "booking scheduled": "bookings scheduled today",
  "Terms of Use": 'Terms of Use',
  "I have read and agree to the Metadoc": 'I have read and agree to the Metadoc {0} and {1}',
  "Pick current location": 'Pick current location',
  "Address Details": 'Address Details',
  "Congratulations": "Congratulations",
  "Sorry!": 'Sorry!',
  "Update Required": "Update Required",
  "Update": 'Update',
  "New Version": "New version of Metadoc App is now available. Please update to the latest version now.",

  "Signup": 'Sign up',
  "Your Gift is waiting": 'Your Gift is waiting',
  "Gift Received": 'Gift Received 🎁',
  "Credit already received!": 'Credit already received!',
  "Get more credit": 'Get more credit',
  "You have already received your invitation gift, but you can still get more reward credit by inviting friends.": 'You have already received your invitation gift, but you can still get more reward credit by inviting friends.',
  "This link is yours!": 'This link is yours!',
  "Share this link with your friends and family to get even more free credit in your wallet": 'Share this link with your friends and family to get even more free credit in your wallet',
  "Explore services": 'Explore services',
  "Your friend {0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!": "Your friend {0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!",
  "{0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!": "{0} has invited you to join Metadoc. You have {1} AED waiting in your wallet. Sign up now!",
  "We apologize for any inconvenience, Some of selected services are currently not served in your area at the moment.": 'We apologize for any inconvenience, Some of selected services are currently not served in your area at the moment.',
  "Unfortunately, the selected service is currently unavailable in your area":'Unfortunately, the selected service is currently unavailable in your area',
  "yourProfessional": "Your professional will arrive between {0}",
  'History': 'History',
  "Referral Points": "Referral Points",
  "Friend Order Reward": "Friend Order Reward",
  "Signup Reward": "Signup Reward",
  "Friend Signup": "Friend Signup",
  "Use wallet credit": 'Use wallet credit',
  "View Details": 'View Details',
  "Phone Number": 'Phone Number',
  "ValidTill": "Valid Till {0}",
  "Redeem Code": 'Redeem Code',
  "You have already enrolled in this program": 'You have already enrolled in this program',
  "Please enter valid promocode!": 'Please enter valid promocode!',
  "Promocode already used": 'Promocode already used',
  "Promocode expired!": 'Promocode expired!',
  "You exceeded maximum number of redemption!": 'You exceeded maximum number of redemption!',
  "Promocode is not applied on selected services or variations!": 'Promocode is not applied on selected services or variations!',
  "Error while processing your request!": 'Error while processing your request!',
  "Please enter your contact details": 'Please enter your contact details',
  "Alert": 'Alert',
  "Request Completed": 'Request Completed',
  "How was your booking experience": 'How was your booking experience',
  "Please rate below": 'Please rate below',
  "Rate Your experience": 'Rate Your experience',
  "This will help us improve our services": 'This will help us improve our services',
  "Having any inquiry": 'Having any inquiry',
  "contact us": 'contact us',
  "Order ID:": 'Order ID:',
  "Refund": 'Refund',
  "Similar Products": 'Similar Products',
  'We are not currently serving this area, but we are expanding very fast, check us later!': 'We are not currently serving this area, but we are expanding very fast, check us later!',
  'We apologize for any inconvenience, but we are not serving your area at the moment!': 'We apologize for any inconvenience, but we are not serving your area at the moment!',
  "Ohh Sorry!": 'Ohh Sorry!',
  "Hi": 'Hi 👋',
  "Cash": 'Cash',
  'Book now': 'Book now',
  "Subscriptions": 'Subscriptions',
  "Upgrade to Pro": 'Upgrade to Pro',
  "Subscribe": 'Subscribe',
  "Settle Now": 'Settle Now',
  "Contact Info": 'Contact Info',
  "Profile info": 'Profile info',
  "This service require pro subscription, Subscribe now to access this service": 'This service require pro subscription, Subscribe now to access this service',
  "/per Month": '/per Month',
  "Days Trial": 'Days Trial',
  "Subscribed": 'Subscribed',
  "Congrats, You are successfully subscribed to Metadoc Plus": 'Congrats, You are successfully subscribed to Metadoc Plus',
  "Manage Subscriptions": 'Manage Subscriptions',
  "Invoices": 'Invoices',
  "Subscription": 'Subscription',
  "Cancel Subscription": 'Cancel Subscription',
  "Renewal date:": 'Renewal date:',
  "Why you are cancelling": 'Why you are cancelling',
  "Later": 'Later',
  "Know more": 'Know more',
  "Current plan": 'Current plan',
  "New": 'New',
  "General information": 'General information',
  "Full Name": 'Full Name',
  "Date of birth": 'Date of birth',
  "Gender": 'Gender',
  "male": 'male',
  "female": 'female',
  "We apologize for any inconvenience, Please Contact us to process your request": 'We apologize for any inconvenience, Please Contact us to process your request',
  "See all": 'See all',
  "Severity:": 'Severity:',
  "Start Date:": 'Start Date:',
  "Diagnosis date:": 'Diagnosis date:',
  "Height:": 'Height:',
  "Weight:": 'Weight:',
  "Drug allergy": 'Drug allergy',
  "Chronic conditions": 'Chronic conditions',
  "Current medications": 'Current medications',
  "BMI": 'BMI',
  "Add new": 'Add new',
  "Start Session": 'Start Session',
  "Save": 'Save',
  "Edit your": 'Edit your {0}',
  "Add your": 'Add your {0}',
  "Name": 'Name',
  "Severity": 'Severity',
  "Condition name": 'Condition name',
  "Diagnosis date": 'Diagnosis date',
  "Resolution date": 'Resolution date',
  "Medication name": 'Medication name',
  "Instructions": 'Instructions',
  "Starting date": 'Starting date',
  "Height": 'Height',
  "Weight": 'Weight',
  "Date": 'Date',
  "Set Date": 'Set Date',
  "Your medical info": 'Your medical info',
  "Medical Information": 'Medical Information',




























};


