import { useNavigation } from '@react-navigation/native';
import { Pressable, View, Image } from 'native-base';
import React from 'react';
import CustomImage from '../../../shared/customImg'
import { Language, MenuSVG } from '../../../../constants/imgs';
import { shadow } from '../../../../helpers/helpers';
import { useSelector } from 'react-redux';
import { Platform } from 'react-native';

const FixedHeader = ({ data }) => {

    const navigation = useNavigation()
    let logo = data?.parent?.picture?.[0];
    const { locale } = useSelector(state => state.locale)
    return (
        <View
            shadow={shadow({ color: '#00000020', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
            style={{
                position: Platform.OS === 'web' ? 'sticky' : 'absolute',
                top: 0,
                background: '#fff',
                width: '100%',
                zIndex: 999,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 16,
                paddingVertical: 8,
                minHeight: 50
            }}>
            <Pressable
                style={{ zIndex: 999 }}
                onPress={() => {
                    navigation.navigate('Menu');
                }}>
                <CustomImage
                    rotate={locale === 'ar'}
                    svg
                    uri={MenuSVG}
                    width={30}
                    height={30}
                />
            </Pressable >
            <View flexDirection={'row'} justifyContent='center' alignItems='center' width={'50%'}>
                <Image key={logo}
                    alt='logo'
                    resizeMode='cover'
                    style={{ height: 50, minWidth: 50 }}
                    source={{ uri: logo || '' }} />
            </View>

            <Pressable
                style={{ zIndex: 999 }}
                onPress={() => {
                    navigation.navigate('Menu', { screen: 'language' });
                }}>
                <CustomImage
                    svg
                    uri={Language}
                    width={35}
                    height={35}
                />
            </Pressable>

        </View >
    );
};

export default FixedHeader;