import { createAction } from 'redux-action';
export const GET_USER = 'GET_USER';
export const GET_FAV_LIST = 'GET_FAV_LIST';
export const FLUSH_USER = 'FLUSH_USER';
export const SET_USER_ORDERS = 'SET_USER_ORDERS';
export const SET_USER_CARDS = 'SET_USER_CARDS';
export const UPDATE_USER_WALLET = 'UPDATE_USER_WALLET';
export const TOGGLE_USER_WALLET = 'TOGGLE_USER_WALLET';
export const SET_USER_DEFAULT_LOCATION = 'SET_USER_DEFAULT_LOCATION';
export const SET_USER_SUBSCRIPTION = 'SET_USER_SUBSCRIPTION';
export const SET_USER_INVOICES = 'SET_USER_INVOICES';

export const GetUser = createAction(GET_USER, (list) => {
    let { email, displayName, emailVerified, phoneNumber, uid, role, locations, stripeId, cards, avatar, affiliate, consent, md_token, dob, gender } = list || {}
    let post = {
        email,
        displayName,
        emailVerified,
        phoneNumber,
        role,
        locations,
        uid,
        cards,
        stripeId,
        avatar,
        affiliate,
        consent,
        md_token,
        dob,
        gender
    }
    return post
});
export const favList = createAction(GET_FAV_LIST, (list) => {
    return list
});

export const SetUserOrders = createAction(SET_USER_ORDERS, (list) => {
    return list
});
export const UpdateUserCards = createAction(SET_USER_CARDS, (list) => {
    return list
});
export const UpdateUserWallet = createAction(UPDATE_USER_WALLET, (list) => {
    return list
});
export const ToggleUserWallet = createAction(TOGGLE_USER_WALLET, (list) => {
    return list
});
export const SetUserDefaultLocation = createAction(SET_USER_DEFAULT_LOCATION, (list) => {
    return list
});
export const SetUserSubscription = createAction(SET_USER_SUBSCRIPTION, (data) => {
    if (data) {
        return { ...data, active: ['trialing', 'active'].includes(data.status) }
    }
    return data
})

export const SetUserInvoices = createAction(SET_USER_INVOICES, (data) => {
    return data
})
export const FlushUser = createAction(FLUSH_USER, (list) => {
    return list
});



const initial = {
    wallet: {},
    orders: []
};

let ex = (state = initial, action) => {
    switch (action.type) {
        case GET_USER:
            return action.payload ? { ...state, ...action.payload, locations: action.payload.locations } : false;

        case GET_FAV_LIST:
            return { ...state, fav: action.payload }

        case SET_USER_ORDERS:
            return { ...state, orders: action.payload ? action.payload : [] }

        case SET_USER_DEFAULT_LOCATION:
            return { ...state, defaultLocation: action.payload }

        case SET_USER_CARDS:
            return { ...state, cards: state.cards ? [...state.cards, action.payload] : [action.payload] }

        case UPDATE_USER_WALLET:
            return { ...state, wallet: action.payload }

        case TOGGLE_USER_WALLET:
            return { ...state, wallet: { ...state?.wallet, useWallet: action.payload } }

        case SET_USER_SUBSCRIPTION:
            return { ...state, subscription: action.payload }
        case SET_USER_INVOICES:
            return { ...state, invoices: action.payload }

        case FLUSH_USER:
            return initial

        default:
            return state;
    }
};
export default ex