import AsyncStorage from '@react-native-async-storage/async-storage';
import user from './reducers/user';
import helpers from './reducers/prds';
import order from './reducers/order';
import offer from './reducers/offers';
import locale from './reducers/locale';
import settings from './reducers/settings';
import alertsReducer from './reducers/alertsReducer';

import { persistCombineReducers } from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    keyPrefix: ''
}
const rootReducer = persistCombineReducers(persistConfig, {
    user,
    helpers,
    order,
    offer,
    settings,
    locale,
    alertsReducer
});

export default rootReducer;