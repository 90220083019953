import { ScrollView, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import Text from '../../../shared/TextLabel';
import Input from '../../../shared/CustomInput';
import { TouchableOpacity } from 'react-native';
import moment from 'moment';
import ActionBtn from "../../../shared/ActionBtn";
import DynamicCard from '../../../shared/DynamicCard';
import { useDispatch, useSelector } from 'react-redux';
import { SetAlertMessage, SetScreenLoading } from '../../../../reducers/settings';
import { AuthorizeMeetingdocUser, CreateMeetingdocUser, InitialMedicalMenu, UpdateMedicalReport, UpdateUser } from '../../../../helpers/helpers';
import { TeleConsultation } from '../../../../helpers/index';
import { useNavigation } from '@react-navigation/native';
import MedicalModal from '../../../shared/MedicalModal';
import { setMedicalMenu } from '../../../../reducers/prds';
import { strings } from "../../../../translations/index";
const ProfileInfo = ({ route }) => {
    let { params: navigateToDoctors } = route;
    const navigation = useNavigation();
    const userData = useSelector(state => state.user)
    const [state, setState] = useState({
        displayName: userData?.displayName,
        gender: userData?.gender,
        dob: userData?.dob,
    });
    const dispatch = useDispatch()
    const [modalChilds, setChilds] = useState(false);
    const { medicalMenu } = useSelector(state => state.helpers);

    useEffect(() => {
        (async () => {
            if (userData?.md_token) {
                dispatch(SetScreenLoading(true))
                let { JWT, success } = await AuthorizeMeetingdocUser(userData?.md_token);
                if (success) {
                    let database = await Promise.all(InitialMedicalMenu?.map(async (rec, index) => {
                        let respnose = await UpdateMedicalReport({ type: rec.id, mode: 'get', JWT, userToken: userData?.md_token });
                        if (respnose) {
                            InitialMedicalMenu[index].data = respnose.data;
                            return InitialMedicalMenu
                        }
                    }))
                    dispatch(setMedicalMenu(database?.[0]))
                    setState({ ...state, updated: Date.now() })
                } else {
                    dispatch(SetAlertMessage({
                        title: 'Ohh Sorry!',
                        msg: `We apologize for any inconvenience, Please Contact us to process your request`,
                        cancelText: 'Got it',
                        alert: true,
                        onCancel: () => {
                            dispatch(SetAlertMessage(false));
                            navigation.goBack();
                        }
                    }))
                }
            }
            dispatch(SetScreenLoading(false))
        })()
    }, [userData?.md_token])
    return (
        <>
            <ScrollView
                showsVerticalScrollIndicator={false}
                flex={1}
                contentContainerStyle={{
                    padding: 16,
                    paddingBottom: 200,
                }}>
                <View>
                    <View paddingBottom={3}>
                        <Text fontFamily='medium' fontSize={16}>
                            {strings?.["General information"]}
                        </Text>
                    </View>

                    <View padding={4} marginBottom={2} borderRadius={8} background={'#fff'}>
                        <Text >
                            {strings?.["Full Name"]}

                        </Text>
                        <View paddingY={2} flexDirection={'row'} justifyContent={'space-between'}>
                            <Input width={'100%'}
                                value={state?.displayName}
                                onChange={(e) => setState({ ...state, displayName: e.nativeEvent.text })}
                                placeholder={strings?.["Full Name"]} style={{
                                    borderRadius: 8,
                                    borderColor: '#E2E9F0',
                                    borderWidth: 1
                                }} />
                            {/* <Input width={'49%'} onChange={(e) => setState({ ...state, lastName: e.target.value })} placeholder='FirstName' style={{
                            borderRadius: 8,
                            borderColor: '#E2E9F0',
                            borderWidth: 1
                        }} /> */}
                        </View>
                    </View>
                    <View padding={4} marginBottom={2} borderRadius={8} background={'#fff'}>
                        <Text >
                            {strings?.["Date of birth"]}
                        </Text>
                        <View paddingY={2}>
                            <TouchableOpacity onPress={() => setChilds({ title: strings?.["Date of birth"], id: 'dob', initial: [{ type: 'date', key: 'dob' }] })}>
                                <View width={'100%'} paddingX={4} paddingY={3} borderRadius={8} borderColor={'#E2E9F0'} borderWidth={1}>
                                    <Text fontSize={12} style={{ color: state?.dob ? '#000' : '#E2E9F0' }}>{state?.dob ? moment(state.dob).format('MMM DD, yyyy') : strings?.["Date of birth"]}</Text>
                                </View>

                            </TouchableOpacity>
                        </View>
                    </View>

                    <View padding={4} marginBottom={2} borderRadius={8} background={'#fff'}>
                        <Text>
                            {strings?.["Gender"]}
                        </Text>
                        <View flexDirection={'row'} justifyContent={'space-between'} paddingY={2}>
                            {
                                ['male', 'female'].map(r => {
                                    return <TouchableOpacity
                                        onPress={() => setState({ ...state, gender: r })}
                                        style={{
                                            backgroundColor: state.gender === r ? '#32A2DC' : '#EDF2F5',
                                            width: '49%',
                                            padding: 12,
                                            borderRadius: 8

                                        }}
                                        key={r}>
                                        <Text style={{ textAlign: 'center', color: state.gender === r ? '#fff' : '#334155' }}>
                                            {strings?.[r]}
                                        </Text>

                                    </TouchableOpacity>

                                })
                            }
                        </View>

                    </View>

                </View>
                <View paddingY={3}>
                    <View paddingBottom={3}>
                        <Text fontFamily='medium' fontSize={16}>
                            {strings?.["Medical Information"]}
                        </Text>
                    </View>

                    {
                        medicalMenu?.map(rec => {
                            return (<DynamicCard
                                key={rec.id}
                                {...rec}
                                navigate={() => navigation.navigate('MedicalDetails', {
                                    ...rec,
                                    medicalMenu: medicalMenu,

                                    dismiss: () => {
                                        setChilds(false)
                                    }
                                })}
                                // data={[]}
                                data={rec?.data?.filter(r => !r.deleted)}
                                edit={(data) => setChilds({ ...rec, data, title: strings.formatString(strings?.["Edit your"], strings?.[rec?.title]) })}
                                onPress={() => setChilds({ ...rec, data: false, title: strings.formatString(strings?.["Add your"], strings?.[rec?.title]) })} />)
                        })
                    }

                </View>

                {
                    modalChilds && <MedicalModal
                        {...modalChilds}
                        setState={setState}
                        state={state}
                        dismiss={() => setChilds(false)}
                    // medicalMenu={medicalMenu}
                    />
                }

            </ScrollView>

            <View position={'absolute'} bottom={0} padding={4} left={0} right={0}>
                <ActionBtn
                    disabled={!state?.displayName || !state.gender}
                    relative
                    title={navigateToDoctors ? strings?.['Start Session'] : 'Save'}
                    onPress={async () => {
                        dispatch(SetScreenLoading(true));
                        dispatch(UpdateUser(state));
                        let updates = medicalMenu?.reduce((val, current) => {
                            let filtered = current.data.filter(r => r.new || r.deleted);
                            if (filtered.length) {
                                val[current.id] = filtered
                            }
                            return val
                        }, {})
                        if (!userData.md_token) {
                            let newToken = await dispatch(CreateMeetingdocUser(userData));
                            if (!newToken?.data?.token) {
                                return dispatch(SetAlertMessage({
                                    title: 'Ohh Sorry!',
                                    msg: strings?.[`We apologize for any inconvenience, Please Contact us to process your request`],
                                    cancelText: 'Got it',
                                    alert: true,
                                }))
                            }
                            userData.md_token = newToken?.data?.token
                        }
                        let { JWT, success } = await AuthorizeMeetingdocUser(userData?.md_token);
                        if (success) {
                            Object.keys(updates).map(async rec => {
                                let payloads = updates[rec];
                                await Promise.all(payloads.map(async payload => {
                                    delete payload.created;
                                    await UpdateMedicalReport({ type: rec, mode: payload?.deleted ? 'delete' : 'post', payload, JWT, userToken: userData?.md_token })
                                }))

                            })
                            if (navigateToDoctors) {
                                await dispatch(TeleConsultation(userData));
                                dispatch(SetScreenLoading(false))
                            } else {
                                navigation.goBack();
                                dispatch(SetScreenLoading(false));

                            }
                        } else {
                            dispatch(SetScreenLoading(false));
                            dispatch(SetAlertMessage({
                                title: 'Ohh Sorry!',
                                msg: strings?.[`We apologize for any inconvenience, Please Contact us to process your request`],
                                cancelText: 'Got it',
                                alert: true,
                                onCancel: () => {
                                    dispatch(SetAlertMessage(false));
                                    navigation.goBack();
                                }
                            }))
                        }

                    }} />

            </View>

        </>
    );
};

export default ProfileInfo;