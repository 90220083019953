import { View } from 'native-base';
import React from 'react';
import DateScreen from './com/DateScreen';
import Header from './com/Header';

const Index = () => {
    return (
        <View flex={1}>
            <DateScreen />
        </View>
    );
};

export default Index;