import { Pressable, ScrollView, View } from 'native-base';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Language as LanguageSVG } from '../../../../constants/imgs';
// import { localeSetLanguage } from '../../../../reducers/locale';
import MenuCell from './menuCell';
// import { strings } from '../../../../translations/index';
// import RNRestart from 'react-native-restart';
// import AsyncStorageStatic from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { UpdateCurrnetLanguage } from '../../../../helpers/helpers';

const Language = () => {
    const dispatch = useDispatch()
    const [languages] = useState([
        {
            title: 'English',
            key: 'en',
            img: LanguageSVG
        },
        {
            title: 'عربي',
            key: 'ar',
            img: LanguageSVG
        },
        {
            title: 'Chinese',
            key: 'zh-CN',
            img: LanguageSVG
        },
        {
            title: 'Russian',
            key: 'rus',
            img: LanguageSVG
        },
    ])
    return (
        <View>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 16, paddingVertical: 16 }}>
                {
                    languages.map(r =>
                        <Pressable key={r.key} paddingY={2} onPress={async () => {
                            await dispatch(UpdateCurrnetLanguage(r.key))
                            if (Platform.OS === 'web') {
                                let params = document.location.search.split('&');
                                params = params.filter(r => r.includes('locale='))
                                document.location.search = '?' + params.join('&') + `&locale=${r.key}`;
                            }
                            // let lang = await AsyncStorageStatic.getItem('language');
                            // if (lang === r.key) {
                            //     return
                            // }
                            // dispatch(localeSetLanguage(r.key))
                            // I18nManager.forceRTL(r.key === 'ar' ? true : false);
                            // strings.setLanguage(r.key);
                            // AsyncStorageStatic.setItem('language', r.key);
                            // if (Platform.OS !== 'web') {
                            //     setTimeout(() => {
                            //         RNRestart.Restart()
                            //     }, 1000);
                            // } else {
                            //     I18nManager.allowRTL(r.key === 'ar' ? true : false);
                            //     window.location.reload()
                            // }

                        }}>
                            <MenuCell translated data={{ title: r.title, img: r.img }} ><View /></MenuCell>
                        </Pressable>
                    )
                }
            </ScrollView>
        </View>
    );
};

export default Language;