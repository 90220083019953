export const mainColor = '#2E373F';
export const secColor = '#0D0D0D';
export const redColor = '#E0262D';
export const greenColor = '#28AF33';
export const backgroundColor = '#151F28';

export const colorBorder = '#7070707f';
export const colorText = '#555555';
export const colorTextBlue = '#32A2DC';
export const colorTextGrey = '#afafaf';
export const colorTextDanger = '#f84848';
export const colorTextComplete = '#00E17A';
