import { useNavigation } from '@react-navigation/native';
import { Pressable, View } from 'native-base';
import React from 'react';
import { Platform, SafeAreaView } from 'react-native';
import { useSelector } from 'react-redux';
import { BackSVG } from '../../../../constants/imgs';
import Image from '../../../shared/customImg'
import Text from '../../../shared/TextLabel';
import { strings } from '../../../../translations/index';

const Header = ({ title = "" }) => {
    const navigation = useNavigation();
    const { webView } = useSelector(state => state.settings);
    const { locale } = useSelector(state => state.locale);
    if (webView) {
        return <View />
    }
    return (
        <SafeAreaView style={{ backgroundColor: '#fff', paddingTop: Platform.OS === 'android' ? 32 : 0 }}>
            <View style={{ backgroundColor: '#fff', flexDirection: 'row', padding: 20 }}>
                <Pressable paddingY={1}
                    hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                    onPress={() => {
                        if (navigation.canGoBack()) {
                            return navigation.goBack()
                        }
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'Home' }],
                        });

                    }}
                >
                    <Image
                        rotate={locale === 'ar'}
                        svg
                        fill='#000000'
                        uri={BackSVG}
                        width={15}
                        height={15}
                    />
                </Pressable>

                <View paddingX={4} >
                    <Text fontSize={16} >
                        {strings?.[title]}
                    </Text>
                </View>
            </View>

        </SafeAreaView>
    );
};

export default Header;