import { View } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowRight } from '../../../../constants/imgs';
import Image from '../../../shared/customImg'
import Text from '../../../shared/TextLabel';
import { strings } from '../../../../translations/index';

const MenuCell = (props) => {
    const { data, children, paddingY = 3 } = props
    const { locale } = useSelector(state => state.locale)
    return (
        <View flexDirection={'row'} justifyContent='space-between' alignItems={'center'} paddingX={4} paddingY={paddingY} backgroundColor={'#fff'} borderRadius={8}>
            <View flexDirection={'row'} alignItems='center'>
                <Image
                    svg
                    fill='#20A2DF'
                    uri={data?.img || ''}
                    width={30}
                    height={30}
                />
                <Text fontFamily='medium' paddingX={2} >
                    {props.translated ? data?.title : strings?.[data?.title]}
                </Text>
            </View>
            <View>
                {
                    children ?
                        children
                        :
                        <Image
                            rotate={locale === 'ar'}
                            svg
                            fill='#EBF2F9'
                            uri={ArrowRight}
                            width={20}
                            height={20}
                        />
                }
            </View>

        </View>
    );
};

export default MenuCell;