import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from './Modal/Modal';
import { KeyboardAvoidingView, ScrollView, TextArea, View } from 'native-base';
import { Platform, TouchableOpacity } from 'react-native';
import Text from './TextLabel';
import { CloseBtn } from '../../constants/imgs';
import CustomImage from "./customImg";
import Input from './CustomInput';
import DateTimePicker from "./DatePicker";
import moment from 'moment';
import Rate from './Rate';
import ActionBtn from './ActionBtn';
import { UpdateRecord } from '../../helpers/helpers';
import { strings } from "../../translations/index";
const MedicalModal = (params) => {
    let { data, title, initial, id: id, dismiss, setState, state } = params;
    const [innerState, setInnerState] = useState(data || {});
    const { medicalMenu } = useSelector(state => state.helpers);

    const [selectDate, toggleSelectDate] = useState(false);
    const dispatch = useDispatch();
    const setDate = (event, date) => {
        const { type, nativeEvent: { timestamp } } = event;
        return timestamp
    };
    useEffect(() => {
        let index = medicalMenu?.findIndex(ele => ele?.id === id);
        let recordIndex = medicalMenu?.[index]?.data?.findIndex(ele => ele?.id === data?.id);
        let item = medicalMenu?.[index]?.data?.[recordIndex];
        if (item?.deleted) {
            dismiss()
        }
    }, [medicalMenu])
    return (
        <Modal fixed={true} size={'full'}>
            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'undefined'}
                position={'absolute'}
                borderTopRightRadius={8}
                borderTopLeftRadius={8}
                left={0}
                right={0}
                bottom={0}
                backgroundColor='#F5F7F8'
                w={'100%'}
            >
                <ScrollView
                    borderTopRightRadius={8}
                    borderTopLeftRadius={8}
                    bottom={0}
                    scrollEnabled={false}
                    background={'#FFFFFF'}
                    // 
                    contentContainerStyle={{
                        paddingBottom: Platform.OS === 'web' ? 50 : 0
                    }}
                >

                    <View width={'100%'} padding={4} >
                        <View flexDirection={'row'} justifyContent={'space-between'} paddingBottom={2} alignItems={'center'}>
                            <Text fontFamily='semiBold' fontSize={16}>
                                {
                                    title
                                }
                            </Text>
                            <TouchableOpacity onPress={() => dismiss()}>
                                <CustomImage
                                    uri={CloseBtn}
                                    width={15}
                                    height={15}
                                    svg
                                    fill='#000'
                                />
                            </TouchableOpacity>
                        </View>
                        <View>
                            {
                                initial?.map((rec, index) => {
                                    switch (rec.type) {
                                        case 'input':
                                            return (<View paddingY={2} key={rec?.key}>
                                                <View paddingBottom={1}>
                                                    <Text>{strings?.[rec.title]} {rec?.required && <Text style={{ color: '#FF0000' }}>*</Text>}</Text>
                                                </View>
                                                <Input value={String(innerState?.[rec?.key] || '')} onChange={(e) => setInnerState({ ...innerState, [rec.key]: e.nativeEvent.text })} placeholder={rec.placeholder} borderRadius={8} borderWidth={1} borderColor={'#E2E9F0'} />
                                            </View>)
                                        case 'date':
                                            return <DateTimePicker display='spinner' onChange={(event, date) => setInnerState({ ...innerState, [rec?.key]: setDate(event, date) })} mode='date' value={new Date(innerState?.[rec?.key] || 0)} />
                                        case 'inlineDate':
                                            return <View paddingY={2} key={rec?.key}>
                                                <View paddingBottom={1}>
                                                    <Text>{strings?.[rec.title]} {rec?.required && <Text style={{ color: '#FF0000' }}>*</Text>}</Text>
                                                </View>
                                                <TouchableOpacity onPress={() => toggleSelectDate(rec)}>
                                                    <View width={'100%'} paddingX={4} paddingY={3} borderRadius={8} borderColor={'#E2E9F0'} borderWidth={1}>
                                                        <Text fontSize={12} style={{ color: innerState?.[rec?.key] ? '#000' : '#D5D5D5' }}>{innerState?.[rec?.key] ? moment(innerState?.[rec?.key]).format('DD/MM/yyyy') : rec.placeholder}</Text>
                                                    </View>
                                                </TouchableOpacity>
                                            </View>
                                        case 'stars':
                                            return (<View key={rec?.key} paddingY={2}>
                                                <View paddingBottom={1}>
                                                    <Text>{strings?.[rec.title]} {rec?.required && <Text style={{ color: '#FF0000' }}>*</Text>}</Text>
                                                </View>
                                                <Rate value={innerState?.[rec.key]} onPress={(r) => setInnerState({ ...innerState, [rec.key]: r })} />
                                            </View>
                                            )

                                        case 'text':
                                            return (<View key={rec?.key} paddingY={2}>
                                                <View paddingBottom={1}>
                                                    <Text>{strings?.[rec.title]} {rec?.required && <Text style={{ color: '#FF0000' }}>*</Text>}</Text>
                                                </View>
                                                <TextArea
                                                    value={innerState?.[rec.key]}
                                                    focusOutlineColor="transparent"
                                                    placeholder={rec.placeholder}
                                                    placeholderTextColor={'#D5D5D5'}
                                                    backgroundColor={'#fff'}
                                                    borderRadius={10}
                                                    mt={2}
                                                    onChange={(e) => setInnerState({ ...innerState, [rec.key]: e.nativeEvent.text })}
                                                    borderColor='#E2E9F0'
                                                    isFullWidth />

                                            </View>
                                            )
                                        default:
                                            break;
                                    }
                                })
                            }
                        </View>
                        <View paddingY={Platform.OS === 'web' ? 8 : 0}>
                            {
                                data ?
                                    <View flexDirection={'row'} paddingY={8} justifyContent={'space-between'} alignItems={'center'}>
                                        <View width={'48%'}>
                                            <ActionBtn
                                                relative
                                                color='#FF1616'
                                                backgroundColor='#fff'
                                                borderColor={'#FF1616'}
                                                title={strings?.['Delete']}
                                                onPress={() => {
                                                    const response = dispatch(UpdateRecord({ innerState, id, data, deleteRecord: true }));
                                                    if (response?.updatedMedicalMenu) {
                                                        dismiss()
                                                    }
                                                }}
                                            />
                                        </View>
                                        <View width={'48%'}>
                                            <ActionBtn
                                                relative
                                                title={strings?.['Save']}
                                                onPress={() => {
                                                    const response = dispatch(UpdateRecord({ innerState, id, data }));
                                                    if (response?.updatedMedicalMenu) {
                                                        dismiss()
                                                    }
                                                }}
                                            />
                                        </View>
                                    </View>
                                    :

                                    <ActionBtn title={strings?.['Add']} onPress={() => {
                                        if (id === 'dob') {
                                            setState({ ...state, ...innerState })
                                            dismiss()
                                            return
                                        }

                                        const response = dispatch(UpdateRecord({ innerState, id }));
                                        if (response?.updatedMedicalMenu) {
                                            dismiss()
                                        }
                                    }} />
                            }

                        </View>

                    </View>
                    {
                        selectDate &&
                        <Modal>
                            <KeyboardAvoidingView
                                behavior={Platform.OS === 'ios' ? 'padding' : 'undefined'}
                                position={'absolute'}
                                borderTopRightRadius={8}
                                borderTopLeftRadius={8}
                                left={0}
                                right={0}
                                bottom={0}
                                backgroundColor='#F5F7F8'
                                w={'100%'}
                            >
                                <ScrollView
                                    borderTopRightRadius={8}
                                    borderTopLeftRadius={8}
                                    bottom={0}
                                    scrollEnabled={false}
                                    background={'#FFFFFF'}
                                    contentContainerStyle={{
                                        paddingBottom: Platform.OS === 'web' ? 50 : 0
                                    }}
                                >
                                    <View padding={4}>
                                        <DateTimePicker display='spinner' onChange={(event, date) => setInnerState({ ...innerState, [selectDate?.key]: setDate(event, date) })} mode='date' value={innerState?.[selectDate?.key] ? new Date(innerState?.[selectDate?.key]) : new Date()} />
                                        <ActionBtn title={strings?.['Set Date']} onPress={() => {
                                            if (!innerState?.[selectDate?.key]) {
                                                setInnerState({ ...innerState, [selectDate?.key]: Date.now() })
                                            }
                                            toggleSelectDate(false)
                                        }} />
                                    </View>
                                </ScrollView>
                            </KeyboardAvoidingView>
                        </Modal>
                    }
                </ScrollView>
            </KeyboardAvoidingView>

        </Modal>
    );
};

export default MedicalModal;