import { FlatList, HStack, Pressable, ScrollView, Skeleton, TextArea, useToast, View } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import Text from '../../../shared/TextLabel';
import { useDispatch, useSelector } from 'react-redux';
import OrderSummarySheet from '../../../shared/OrderSummarySheet';
import TimmingSheet from './TimmingSheet';
import { useNavigation } from '@react-navigation/native';
import { firestore } from '../../../../constants/firebase/config';
import { getAvailableTimings, GetTimingSlots } from '../../../../helpers/helpers';
import { SetOrderContact, SetOrderDate, SetOrderService } from '../../../../reducers/order';
import { Platform, useWindowDimensions } from 'react-native';
import Image from '../../../shared/customImg'
import moment from 'moment'
import { TimeSVG, ArrowDownTimings } from '../../../../constants/imgs';
import { SetAlertMessage, SetLoginRequired, SetRequiredLocation } from '../../../../reducers/settings';
import { strings } from '../../../../translations/index';
const DateRow = ({ data, update, selectedDate }) => {
    let selected = selectedDate?.id === data.id;
    return (
        <Pressable
            onPress={() => update(data)}
            style={{
                width: 65,
                height: 65,
                marginRight: 8,
                backgroundColor: selected ? '#20A2DF' : '#F5F7F8',
                borderRadius: 8,
                justifyContent: 'center',
                alignItems: 'center',

            }}>
            <Text style={{ fontSize: 10, color: selected ? '#fff' : '#3B3B3B' }}>{data?.weekDay}</Text>
            <Text fontFamily='bold' style={{ fontSize: 14, paddingTop: 4, color: selected ? '#fff' : '#3B3B3B' }}>{data?.date}</Text>

        </Pressable>
    )
}
const DateScreen = ({ route }) => {
    let { rescadual } = route?.params || {}
    const [state, setState] = useState(false);
    const { items, service: SelectedService } = useSelector((state) => state.order);

    const authUser = useSelector((state) => state.user);
    const { activeProviders, services, servingAreas } = useSelector(state => state.settings)
    const [waitForLocation, toggleWaitForLocation] = useState(false)
    const [readyToSubmitt, toggleReadyToSubmitt] = useState(false)

    const [timingSheet, toggleTimmingSheet] = useState(false);
    const Navigation = useNavigation()
    const [Dates, setDates] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);
    const dispatch = useDispatch()
    const toast = useToast();
    const CurrentUserRef = useRef();
    const ItemsRef = useRef();
    ItemsRef.current = items
    CurrentUserRef.current = authUser
    useEffect(() => {
        let variationsKeys = rescadual ? rescadual.variationsKeys : Object.keys(items);
        (async () => {
            let clusterProviders = servingAreas?.find(r => r.id === authUser?.defaultLocation?.cluster)?.providers?.filter(r => {
                return variationsKeys?.every(e => r?.variations?.[e])
            }).map(r => r?.id);
            let resss = await getAvailableTimings({ variationsKeys, providers: activeProviders.filter(r => clusterProviders ? clusterProviders?.includes(r.id) : r) })
            // let getDates = firebase.app().functions('europe-west1').httpsCallable('getAvailableTimings');
            // let result = await getDates({ variationsKeys: variationsKeys })
            // let data = JSON.parse(result.data);
            setDates(resss || [])
            setState({ ...state, selectedDate: resss[0], selectedTime: false });
        })()
    }, [])

    useEffect(() => {
        if (!rescadual && !Object.values(items || {}).length) {
            Navigation.goBack()
        }
    }, [items])
    useEffect(() => {
        if (state.selectedDate) {
            let results = GetTimingSlots({ ...state.selectedDate, buffer: Number(SelectedService?.buffer) || 60 });
            setTimeSlots(results)
            if (results?.morning?.length) {
                setState({ ...state, selectedTime: results?.morning?.[0] });
            } else if (results?.afternoon?.length) {
                setState({ ...state, selectedTime: results?.afternoon?.[0] });
            } else if (results?.evening?.length) {
                setState({ ...state, selectedTime: results?.evening?.[0] });
            } else {
                let currentIndex = Dates.findIndex((i) => i.id === state.selectedDate.id)
                if ((currentIndex > -1) && Dates?.[currentIndex + 1]) {
                    setState({ ...state, selectedDate: Dates?.[currentIndex + 1] })
                }
            }
        }
    }, [state.selectedDate])
    useEffect(() => {
        if (SelectedService) {
            let service = services?.find(r => r.id === SelectedService?.id)
            dispatch(SetOrderService(service));
        }
    }, [services, SelectedService])

    useEffect(() => {
        if (readyToSubmitt && authUser?.uid) {
            toggleReadyToSubmitt(false)
            submit()
        }

    }, [authUser, readyToSubmitt])

    useEffect(() => {
        if (authUser?.defaultLocation && !authUser?.defaultLocation?.anonimous && waitForLocation) {
            toggleReadyToSubmitt(false)
            if (authUser.role !== 'concierge') {
                let contact = {
                    name: authUser?.displayName || '-',
                    email: authUser?.email || '-',
                    phoneNumber: authUser?.phoneNumber || '-',
                    uid: authUser?.uid,
                }
                if ((Platform.OS === 'web' && Object.values(items || {}).length) || Platform.OS !== 'web') {
                    dispatch(SetOrderContact(contact));
                    Navigation.navigate('PaymentType');
                    return
                }
            }
            if (authUser.role === 'concierge') {
                Navigation.navigate('ContactInfo')
            }

        }
    }, [authUser?.defaultLocation, waitForLocation, items])

    const submit = () => {
        let user = CurrentUserRef.current
        toggleWaitForLocation(true)
        if (user?.defaultLocation && !user?.defaultLocation?.anonimous) {
            if (Platform.OS !== 'web' || authUser.role !== 'concierge') {
                let contact = {
                    name: authUser?.displayName || '-',
                    email: authUser?.email || '-',
                    phoneNumber: authUser?.phoneNumber || '-',
                    uid: authUser?.uid,
                }
                dispatch(SetOrderContact(contact));
                Navigation.navigate('PaymentType');
                return
            }
            Navigation.navigate('ContactInfo')
            return
        } else {
            if (user?.defaultLocation?.anonimous) {
                if (user?.locations?.length) {
                    return dispatch(SetRequiredLocation({
                        open: true,
                        dimmable: true,
                        action: null
                    }))
                }
                return Navigation.navigate('Address Details', { selectedRegon: user?.defaultLocation, popOne: true })

            } else {
                if (user?.locations?.length) {
                    return dispatch(SetRequiredLocation({
                        open: true,
                        dimmable: true,
                        action: null
                    }))

                }
            }
            return Navigation.navigate('AddNewLocation')
        }
    }

    return (
        <View flex={1}
            // minHeight='100vh'
            justifyContent='space-between'
        // style={{ minHeight: 'calc(100vh - 85px)' }}
        >
            <ScrollView
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ paddingHorizontal: 16 }} >
                <Text fontFamily='semiBold' paddingTop={8}>
                    {strings?.["Select preferred Date and Time"]}
                </Text>
                <View marginTop={4} backgroundColor={'#fff'} borderRadius={10}>
                    {
                        Dates.length ?
                            <FlatList
                                showsHorizontalScrollIndicator={false}
                                contentContainerStyle={{
                                    paddingHorizontal: 16,
                                    paddingVertical: 12
                                }}
                                horizontal
                                data={Dates}
                                renderItem={(i) => <DateRow key={i.item.id} selectedDate={state.selectedDate} data={i.item} update={(id) => { setState({ ...state, selectedDate: id, selectedTime: false }); }} />}
                                keyExtractor={item => item.id}
                            />
                            :
                            <HStack style={{
                                paddingHorizontal: 16,
                                paddingVertical: 12
                            }}>
                                {
                                    [1, 2, 3, 4, 5, 6, 7].map(r => <Skeleton key={r} style={{
                                        width: 65,
                                        height: 65,
                                        marginRight: 8,
                                        borderRadius: 8,
                                    }} />)
                                }
                            </HStack>
                    }
                </View>

                <Pressable
                    borderRadius={10}
                    height={50}
                    marginTop={4}
                    flexDirection='row'
                    justifyContent={'space-between'}
                    backgroundColor={'#fff'}

                    p={4}
                    alignItems='center'
                    onPress={() => {
                        if (state.selectedDate) {
                            toggleTimmingSheet(true)
                            return
                        }
                        toast.show({
                            title: strings?.['Incomplete data'],
                            status: "error",
                            placement: "top",
                            description: strings?.['Please select Date first']
                        })

                    }}
                >
                    <Text fontFamily='semiBold' style={{ color: state.selectedTime ? '#20A2DF' : '#fff' }}> {state.selectedTime ? state.selectedTime?.id : strings['Select time']} </Text>
                    <View>
                        <Image
                            svg
                            uri={ArrowDownTimings}
                            width={30}
                            height={30}
                        />
                    </View>
                </Pressable>
                {state.selectedTime ? <Text paddingTop={2} fontSize={10} letterSpacing={0.5} style={{ color: "#334155" }} > {strings.formatString(strings?.yourProfessional, state.selectedTime?.id)}</Text> : <></>
                }
                <Text fontFamily='semiBold' paddingTop={6} >
                    {strings?.["Do you have any specific instructions?"]}
                </Text>
                <TextArea
                    focusOutlineColor="transparent"
                    placeholder='Describe instructions here'
                    placeholderTextColor={'#D5D5D5'}
                    backgroundColor={'#fff'}
                    borderRadius={10}

                    // _focus={
                    //     { borderColor: '#fff', borderRadius: 10 }
                    // }
                    mt={2}
                    onChange={(e) => setState({ ...state, notes: e.target.value })
                    }
                    borderColor='white'
                    isFullWidth />

            </ScrollView>
            {
                timingSheet && <TimmingSheet
                    selected={state.selectedTime}
                    dismiss={() => toggleTimmingSheet(false)}
                    data={timeSlots}
                    setTimeSlot={(slot) => {
                        setState({ ...state, selectedTime: slot });
                        toggleTimmingSheet(false);
                    }} />
            }
            <OrderSummarySheet
                bottom={88}
                fixed={true}
                minimal={rescadual ? true : false}
                btnTitle={rescadual ? strings.Reschedule : 'Continue'}
                onPress={() => {
                    if (state.selectedTime && state.selectedDate) {
                        if (rescadual) {
                            dispatch(SetAlertMessage({
                                title: 'Reschedule Request',
                                msg: strings.formatString(strings.RescadualMsg, moment(state?.selectedDate?.dateTimestamp).format('yyyy-MM-DD'), moment(state?.selectedTime?.start).format('hh:mm A')),
                                confirmText: 'Reschedule',
                                onConfirm: () => {
                                    let sfDocRef = firestore().collection('orders').doc(rescadual.id);
                                    return firestore().runTransaction((transaction) => {
                                        // This code may get re-run multiple times if there are conflicts.
                                        return transaction.get(sfDocRef).then((sfDoc) => {
                                            if (!sfDoc.exists) {
                                                throw "Document does not exist!";
                                            }
                                            var { status, customer_id, provider, candidates } = sfDoc.data();
                                            let optimizedDate = moment(moment(state?.selectedDate?.dateTimestamp).format('yyyy-MM-DD') + ' ' + moment(state?.selectedTime.start).format('HH:mm')).valueOf();
                                            let post = {
                                                bookingDate: {
                                                    date: optimizedDate,
                                                    time: state?.selectedTime
                                                },
                                                rescadual: Date.now(),
                                                updatedBy: customer_id,
                                                status: status === 'new' ? 'new' : 'pending'
                                            }
                                            if (provider?.id) {
                                                post.candidates = [provider?.id]
                                                let ignoredCandidates = candidates?.filter(r => r !== provider?.id)
                                                post.ignoredCandidates = ignoredCandidates
                                            }
                                            transaction.update(sfDocRef, post);
                                        });
                                    }).then(() => {
                                        dispatch(SetAlertMessage(false))

                                        console.log("Transaction successfully committed!");
                                        Navigation.reset({
                                            index: 0,
                                            routes: [{ name: 'Home' }],
                                        })
                                        Navigation.navigate('OrderDetails', { order_id: rescadual.id })
                                    }).catch((error) => {
                                        dispatch(SetAlertMessage(false))
                                        console.log("Transaction failed: ", error);
                                    });

                                }
                            }))
                            return
                        } else {
                            let service = services?.find(r => r.id === Object.values(items || {})?.[0]?.service_id)
                            if (service) {

                                dispatch(SetOrderService(service));
                                dispatch(SetOrderDate(state));
                                if (authUser?.uid) {
                                    return submit()
                                }

                                dispatch(SetLoginRequired({
                                    open: true,
                                    callBack: () => {
                                        toggleReadyToSubmitt(true)
                                    }
                                }))

                                // if (Platform.OS !== 'web' || authUser.role !== 'concierge') {
                                //     let contact = {
                                //         name: authUser?.displayName || '-',
                                //         email: authUser?.email || '-',
                                //         phoneNumber: authUser?.phoneNumber || '-',
                                //         uid: authUser?.uid,
                                //     }
                                //     dispatch(SetOrderContact(contact));
                                //     Navigation.navigate('PaymentType');
                                //     return
                                // }
                                // Navigation.navigate('ContactInfo')
                                return
                            } else {
                                toast.show({
                                    title: 'Error',
                                    status: "error",
                                    placement: "top",
                                    description: 'Please start over or contact us'
                                })
                            }

                        }

                    }
                    toast.show({
                        title: strings?.['Incomplete data'],
                        status: "error",
                        placement: "top",
                        description: strings?.["Please select date and time to proceed"]
                    })
                }
                } />
        </View>
    );
};

export default DateScreen;