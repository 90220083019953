import { View } from 'native-base';
import React from 'react';
import Text from './TextLabel';
import { strings } from '../../translations/index';
const NewTag = () => {
    return (
        <View
            position={'absolute'}
            top={0}
            backgroundColor={'#ef4444'}
            zIndex={99}
            paddingX={2}
            borderBottomRightRadius={8}
            borderTopLeftRadius={8}
            left={0}>
            <Text
                fontFamily='medium'
                style={{ color: '#fff' }}
                fontSize={9}>
                {strings?.New}
            </Text>
        </View>
    );
};

export default NewTag;