import { Image, ScrollView, Toast, View } from 'native-base';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../shared/CustomInput';
import CustomImage from '../../../shared/customImg';
import { PhoneSVG, ProfileSVG, MailSVG } from '../../../../constants/imgs';
import ActionBtn from '../../../shared/ActionBtn';
import { KeyboardAvoidingView, Platform } from 'react-native';
import { storage } from '../../../../constants/firebase/config';
import { useNavigation } from '@react-navigation/native';
import { UpdateUser } from '../../../../helpers/helpers';


const ContactInfo = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [state, setState] = useState(user)
    const [loading, setLoading] = useState(false)
    const navigation = useNavigation()
    const [fields] = useState([
        {
            key: 'displayName',
            placeholder: 'name',
            icon: ProfileSVG,
            type: 'default'
        },
        {
            key: 'phoneNumber',
            placeholder: 'phoneNumber',
            icon: PhoneSVG,
            type: 'phone-pad'

        },
        {
            key: 'email',
            placeholder: 'email',
            icon: MailSVG,
            type: 'email-address'

        },
    ])
    return (
        <KeyboardAvoidingView flex={1} behavior={Platform.OS === "ios" ? "padding" : "height"}>
            <ScrollView flex={1} paddingTop={4}>
                {/* <View flexDirection={'row'} justifyContent='center' alignItems={'center'} padding='10%'>
                    <TouchableOpacity
                    // onPress={handlePicker} 
                    >
                        <ImagePicker
                            images={[].map((image, i) => ({ src: image, value: i }))}
                            onPick={onPick}
                        />
                        <Image
                            source={{ uri: state?.avatar }}
                            backgroundColor={'#cacaca'}
                            width={100}
                            style={{ aspectRatio: 1 }}
                            borderRadius='full'
                        />
                    </TouchableOpacity>
                </View> */}

                <View >
                    {
                        fields.map(rec => {
                            return (
                                <View key={rec.key} paddingY={2} paddingX={4}>
                                    <Input
                                        InputLeftElement={<View paddingLeft={4}>
                                            <CustomImage
                                                svg
                                                uri={rec.icon}
                                                width={25}
                                                height={25}
                                            />
                                        </View>}
                                        keyboardType={rec.type}
                                        value={state?.[rec.key]}
                                        onChangeText={(text) => setState({ ...state, [rec.key]: text })}
                                        placeholder={rec.placeholder}
                                        key={rec.key}
                                    />
                                </View>

                            )
                        })
                    }
                </View>
            </ScrollView>
            <View position={'fixed'} bottom={8} width={'100%'}>
                <ActionBtn loading={loading} disabled={loading} title={'Update info'} onPress={async () => {
                    setLoading(true)
                    let snap = ["displayName", 'email', 'phoneNumber', 'avatar'].reduce((val, current) => {
                        if (state[current]) {
                            val[current] = state[current]
                        }
                        return val
                    }, {});
                    if (state.base64) {
                        try {
                            let progress = storage().ref().child(`/users/${user?.uid}/avatar.jpg`);
                            await progress.putString(state.base64, 'base64', { 'contentType': 'image/jpeg' })
                            snap.avatar = await progress.getDownloadURL()
                        } catch (error) {
                            setLoading(false)
                        }
                    }
                    const { error } = await dispatch(UpdateUser(snap))
                    console.log("error,,,,", error);
                    if (error) {
                        setLoading(false)
                        return Toast.show({
                            placement: 'top',
                            title: 'Error',
                            status: 'error',
                            description: error.message
                        })

                    }
                    // await firestore().collection('customers').doc(user?.uid).update(snap)
                    setLoading(false)
                    navigation.goBack()
                }} />
            </View>

        </KeyboardAvoidingView>

    );
};

export default ContactInfo;