import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./routes";
import { extendTheme, NativeBaseProvider, Spinner, View } from 'native-base';
// import Alert from "./component/shared/Alert";
import { strings } from './translations/index';

import AlertContainer from "./component/shared/AlertContainer";
import CustomerLogin from "./component/screens/login/CustomerLogin";
import { SetLoginRequired } from "./reducers/settings";
// import Amplify, { Analytics } from 'aws-amplify';
// import awsconfig from './aws-exports';
// import { analytics } from "./constants/firebase/config";

import LottieSplashScreen from "react-native-lottie-splash-screen";
import { I18nManager, Platform, useWindowDimensions, LogBox } from "react-native";
import AsyncStorageStatic from '@react-native-async-storage/async-storage';
import { LogLevel, OneSignal } from 'react-native-onesignal';
import { InitializeSentry, WrapApp } from "./helpers/index";

InitializeSentry()
OneSignal.initialize("9860e0b9-ae7a-4770-af87-d681f9b8828a");
OneSignal.Notifications.addEventListener('foregroundWillDisplay', (notificationReceivedEvent) => {
  console.log("OneSignal: notification will show in foreground:", notificationReceivedEvent);
  let notification = notificationReceivedEvent.getNotification();
  console.log("notification: ", notification);
  const data = notification.additionalData
  console.log("additionalData: ", data);
  // Complete with null means don't show a notification.
  notificationReceivedEvent.complete(notification);
});

const newColorTheme = {
  primary: {
    50: '#20A2DF',
    100: '#20A2DF',
    200: '#20A2DF',
    300: '##20A2DF',
    400: '#20A2DF',
    500: '#20A2DF',
    600: '#20A2DF',
    700: '#20A2DF90',
    800: '#20A2DF90',
    900: '#20A2DF90',
  },
};
const theme = extendTheme({
  colors: newColorTheme,
  useSystemColorMode: false,
  initialColorMode: 'light',
});
const LoginContainer = ({ }) => {
  const dispatch = useDispatch()
  const { loginRequired } = useSelector(state => state.settings)
  return loginRequired ? <CustomerLogin {...loginRequired}
    dismiss={() => {
      dispatch(SetLoginRequired(false))
    }}
  /> : <View />
}

const LoadingContainer = ({ }) => {
  const { screenLoading } = useSelector(state => state.settings)
  return screenLoading ? <View
    position={'absolute'}
    backgroundColor='#00000050'
    zIndex={9999}
    height={'100%'}
    width='100%'
    justifyContent='center'
    alignItems={'center'}
    flex={1}>
    <Spinner size={'lg'} />
  </View> : <View />

}
// const ToastContainer = ({ }) => {
//   const { displayToast } = useSelector(state => state.alertsReducer);
//   return displayToast ?
//     <Toast item={displayToast} />
//     : <View />
// }

const WebRoute = () => {
  const { brand } = useSelector(state => state.settings);
  let logo = brand?.picture?.[0];
  return (
    <View style={{ height: '100vh', overflow: 'hidden' }}>

      <View style={{
        height: '100vh', width: '30%', marginHorizontal: '35%', zIndex: 1,
        shadowColor: 'lightgrey',
        shadowOpacity: 1,
        shadowRadius: 8,
        shadowOffset: {
          width: 0,
          height: 0
        }
      }
      }>
        <LoadingContainer />
        <Routes />
        <AlertContainer />
        <LoginContainer />
        {/* <ToastContainer /> */}
      </View>
      <div
        className='emptySpace'
        style={{
          backgroundImage: `url("${logo}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }} >
        {/* <div style={{
          height: '100%',
          width: '100%',
          // backgroundColor: 'rgb(254 254 254 / 95%)',
        }} /> */}
      </div>
    </View>
  )
}

function App() {

  const { width } = useWindowDimensions()

  useEffect(() => {
    // LogBox.ignoreAllLogs();
    async function fetchData() {
      let lang = await AsyncStorageStatic.getItem('language')
      I18nManager.allowRTL(lang === 'ar' ? true : false);
      I18nManager.forceRTL(lang === 'ar' ? true : false);

      strings.setLanguage(lang || 'en')
    }
    fetchData();
  }, []); // Or 

  useEffect(() => {

    if (Platform.OS !== 'web') {
      setTimeout(() => {
        LottieSplashScreen.hide()
      }, 1500);
    }
  }, [])
  if (Platform.OS == 'web' && width > 900) {

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <NativeBaseProvider theme={theme}  >
            <WebRoute />
          </NativeBaseProvider>
        </PersistGate>
      </Provider>
    )
  }
  return (
    <Provider store={store}>

      <PersistGate loading={null} persistor={persistor}>
        <NativeBaseProvider theme={theme} >
          <LoadingContainer />
          <Routes />
          <AlertContainer />
          <LoginContainer />
          {/* <ToastContainer /> */}

        </NativeBaseProvider>
      </PersistGate>
    </Provider>
  );
}

export default WrapApp(App);