import { useNavigation } from '@react-navigation/native';
import { ScrollView, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PharmacyCardList from '../../../shared/PharmacyCardList';
import OrderSummarySheet from '../../../shared/OrderSummarySheet';
import Image from '../../../shared/customImg';
import { DeliveryIcon } from '../../../../constants/imgs';
import Text from '../../../shared/TextLabel';
import { SetOrderContact, SetOrderService } from '../../../../reducers/order';


const Index = ({ route }) => {

    const { items } = useSelector(state => state.order);
    const userData = useSelector(state => state.user);

    const { medicines } = useSelector(state => state.helpers);
    const dispatch = useDispatch()
    const [state, setState] = useState({})
    useEffect(() => {
        if (Platform.OS === 'web') {
            window.scrollTo(0, 0)
        }
        let prds = Object.keys(items).map(sku => {
            let item = medicines?.prds?.find(rec => rec.sku === Number(sku))
            return item
        })
        setState({ ...state, prds })
    }, [])

    const navigation = useNavigation()

    return (
        <View flex={1} minHeight={Platform.OS === 'web' ? '120vh' : '80%'} justifyContent='space-between'>
            <View
                backgroundColor='#FFFFFF'>
                <View style={{
                    paddingHorizontal: 16,
                    paddingVertical: 12,
                    backgroundColor: '#334155',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <Image
                        svg
                        width={25}
                        height={25}
                        uri={DeliveryIcon}
                    />
                    <View style={{ marginHorizontal: 15 }}>
                        <Text style={{
                            color: '#fff',
                            textAlign: 'left',
                            fontSize: 12,
                        }}>Delivery time</Text>

                        <Text fontFamily='semiBold' style={{
                            color: '#fff',
                            textAlign: 'left',
                            fontSize: 14,
                        }}>Order will be delivered in 60 minutes</Text>

                    </View>
                </View>

            </View>
            <View flex={1}>
                <ScrollView
                    alwaysBounceVertical={false}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{
                        border: 'none',
                        paddingTop: 16,
                        paddingBottom: 150,
                        // flex:1
                    }}>
                    <View style={{
                        padding: 16,
                        borderTopRightRadius: 16,
                        borderTopLeftRadius: 16,
                        flex: 1,
                        top: -12,
                        backgroundColor: '#F5F7F8',
                    }}>
                        {
                            state?.prds?.map((rec, i) => <View key={i} paddingBottom={4}>
                                <PharmacyCardList data={{ ...rec, service_id: 'pharmacy' }} navigation={navigation} />
                            </View>)
                        }
                    </View>
                </ScrollView>
            </View>
            <OrderSummarySheet disabled={Object.values(items)?.length < 1} onPress={() => {
                dispatch(SetOrderService({
                    id: 'pharmacy',
                    name: {
                        en: 'Pharmacy',
                        ar: 'ادوية'
                    }
                }))
                dispatch(SetOrderContact({
                    name: userData?.displayName || '',
                    email: userData?.email || '',
                    phoneNumber: userData?.phoneNumber || ''
                }))
                navigation.navigate('PaymentType')
                // if (user?.uid) {
                //     return navigation.navigate('Booking')
                // }
                // toggleLogin(true)
            }} />

        </View>

    );
};

export default Index;