import { View } from 'native-base';
import React from 'react';
import Text from './TextLabel';
import Image from './customImg'
import { AddSVG, DashedLine } from '../../constants/imgs';
import { TouchableOpacity } from 'react-native';
import moment from 'moment';
import Rate from './Rate';
import ActionBtn from './ActionBtn';
import { InitialMedicalMenu } from '../../helpers/helpers';
import { strings } from "../../translations/index";
const DynamicCard = ({ data, title, id, onPress, edit, navigate, }) => {
    let { icon } = InitialMedicalMenu?.find(r => r.id === id) || {}

    let SlicedData = data?.slice(0, 2) || [];
    return (
        <View key={id}>
            {data?.length ?

                <View background={'#fff'} margin={1} borderRadius={8}>
                    <View flexDirection={'row'} padding={4} paddingBottom={3} borderBottomWidth={1} borderBottomColor={'#BFBFBF50'} justifyContent={'space-between'} alignItems={'center'}>
                        <View flexDirection={'row'} alignItems={'center'}>
                            {
                                icon ? <Image
                                    svg
                                    width={26}
                                    height={26}
                                    uri={icon}
                                />
                                    :
                                    <View />
                            }
                            <Text paddingX={2} fontFamily='medium' fontSize={14}>
                                {strings?.[title]}
                            </Text>

                        </View>

                        <TouchableOpacity onPress={() => navigate()}>
                            <Text fontFamily='medium' fontSize={14} style={{ color: '#32A2DC' }}>
                                {strings?.['See all']}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <View paddingY={1} paddingX={4}>
                        {
                            SlicedData?.map((rec, index) => {
                                return (<View key={rec.id} >
                                    <TouchableOpacity style={{ paddingVertical: 12 }} onPress={() => edit(rec)}>
                                        {
                                            id === 'allergies' ?
                                                <>
                                                    <Text fontFamily='semiBold'>
                                                        {rec?.name}
                                                    </Text>
                                                    {
                                                        rec?.description ? <View paddingY={2}>
                                                            <Text fontSize={12} style={{ color: '#64748B' }}>
                                                                {rec?.description}
                                                            </Text>
                                                        </View>
                                                            :
                                                            <View padding={1} />
                                                    }
                                                    <View flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <View flexDirection={'row'} alignItems={'center'}>
                                                            <Text fontFamily='medium' fontSize={12} style={{ color: '#00A5E7' }}>
                                                                {strings?.["Severity:"]}
                                                            </Text>
                                                            <View paddingX={2}>
                                                                <Rate value={rec?.severity} disabled size={12} />

                                                            </View>
                                                        </View>
                                                        <View>
                                                            <Text style={{ color: '#00A5E7' }} fontSize={12} fontFamily='medium'>
                                                                {moment(rec.created).format('MMM DD,yyyy')}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </>
                                                :
                                                id === 'medications' ?
                                                    <>

                                                        <View flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                            <Text fontFamily='semiBold'>
                                                                {rec?.name}
                                                            </Text>
                                                            {/* <Badge title={rec?.posology} color='#81D146' /> */}
                                                        </View>
                                                        {
                                                            rec?.description ? <View paddingY={3}>
                                                                <Text fontSize={12} style={{ color: '#64748B' }}>
                                                                    {rec?.description}
                                                                </Text>
                                                            </View>
                                                                :
                                                                <View padding={1} />
                                                        }

                                                        <View flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                                            <Text fontSize={12} fontFamily='medium' style={{ color: '#32A2DC' }}>
                                                                {strings?.["Start Date:"]} {moment(rec?.date).format('MMM DD, yyyy')}
                                                            </Text>
                                                            <View>
                                                                <Text fontSize={12} fontFamily='semiBold' style={{ color: '#81D146' }}>
                                                                    {rec?.posology}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    </>
                                                    :
                                                    id === 'diseases' ?
                                                        <>
                                                            <Text fontFamily='semiBold'>
                                                                {rec?.name}
                                                            </Text>
                                                            {
                                                                rec?.description ? <View paddingY={3}>
                                                                    <Text fontSize={12} style={{ color: '#64748B' }}>
                                                                        {rec?.description}
                                                                    </Text>
                                                                </View>
                                                                    :
                                                                    <View />
                                                            }
                                                            <View  >
                                                                <Text fontSize={12} fontFamily='medium' style={{ color: '#32A2DC' }}>
                                                                    {strings?.["Diagnosis date:"]} {moment(rec?.diagnosis_date).format('MMM DD, yyyy')}
                                                                </Text>
                                                            </View>

                                                        </>
                                                        :
                                                        id === 'imcs' ?
                                                            <>
                                                                <Text fontFamily='semiBold'>
                                                                    {`${strings?.["BMI"]} ${(rec?.weight / ((rec?.height * rec?.height / 10000)))?.toFixed(2)}`}
                                                                </Text>
                                                                <View flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} paddingTop={2}>
                                                                    <Text style={{ color: '#64748B' }} fontSize={12}>
                                                                        {`${strings?.["Height:"]} ${rec?.height} | ${strings?.["Weight:"]} ${rec?.weight}`}
                                                                    </Text>
                                                                    <Text fontSize={12} fontFamily='medium' style={{ color: '#00A5E7' }}>
                                                                        {moment(rec?.date).format('MMM DD, yyyy')}
                                                                    </Text>
                                                                </View>
                                                            </>
                                                            :
                                                            <>
                                                            </>

                                        }
                                    </TouchableOpacity>
                                    {
                                        index !== SlicedData.length - 1 ?
                                            <View>
                                                <Image
                                                    svg
                                                    width={'100%'}
                                                    height={1}
                                                    uri={DashedLine}
                                                />
                                            </View>
                                            :
                                            <View />
                                    }
                                </View>)
                            })
                        }

                    </View>
                    <View paddingY={3} paddingX={4}>
                        <ActionBtn height={42} backgroundColor='#32A2DC10' color='#32A2DC' relative title={strings?.['Add new']} onPress={onPress} />
                    </View>

                </View>
                :
                <TouchableOpacity onPress={onPress}>
                    <View flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} background={'#fff'} margin={1} padding={4} borderRadius={8}>
                        <View flexDirection={'row'} alignItems={'center'}>
                            {
                                icon ? <Image
                                    svg
                                    width={26}
                                    height={26}
                                    uri={icon}
                                />
                                    :
                                    <View />
                            }

                            <Text paddingX={2} fontFamily='medium' style={{ color: '#052249' }} fontSize={14}>
                                {strings?.[title]}
                            </Text>
                        </View>

                        <TouchableOpacity onPress={onPress}>
                            <Image
                                svg
                                width={12}
                                height={12}
                                uri={AddSVG}
                            />
                        </TouchableOpacity>


                    </View>
                </TouchableOpacity>

            }

        </View>
    );
};

export default DynamicCard;