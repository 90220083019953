import { SvgXml } from 'react-native-svg'
import { Platform } from "react-native";
import React from 'react';
import { Image, View } from 'native-base';

const Index = ({ uri, width, png, height, svg, style, resizeMode = 'contain', rotate, fill = '#fff', step = 1 }) => {
    if (Platform.OS === 'web' || !svg) {
        return (
            <Image
                alt='image'
                accessibilityLabel={'image'}
                source={svg ? { uri: uri({ fill, step }) } : png ? uri : { uri: uri, cache: 'force-cache' }}
                resizeMode={resizeMode}
                style={{ ...style, width: width, height: height, transform: [{ rotate: rotate ? '180deg' : '0deg' }] }}
            />)
    }
    return (
        <SvgXml
            alt='image'
            accessibilityLabel={'image'}
            width={width}
            color='red'
            transform={[{ rotate: rotate ? '180deg' : '0deg' }]}
            height={height}
            style={{...style}}
            xml={uri({ fill, step })} />
    );
};

export default Index;