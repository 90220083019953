import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);
// const stripePromise = loadStripe('pk_test_51KazKyE4EQQhM6genQSxeh41yUKTiOHt8Hxb1R1FJWWLPj4PU3iQrp2DgiARtJwEIFtqSjgJa31Ap83JWi5rtn3500Lo35b2o4');

import Nav from './navigator'
const Index = ({ route }) => {
    return (
        <Elements stripe={stripePromise}>
            <Nav route={route} />
        </Elements>
    );
};

export default Index;