import React, { useEffect, useState } from 'react';
import { Image, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, View, ActivityIndicator } from 'react-native';
import { colorTextBlue, mainColor, redColor } from '../../../constants/color';
import ActionBtn from '../../shared/ActionBtn';
import Text from '../../shared/TextLabel'
import { TextInput } from 'react-native-paper';

import { auth } from '../../../constants/firebase/config'
import { ConstructAddress, makeid, updateOrderForLocationChange, UpdateUser } from '../../../helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, StackActions } from '@react-navigation/native';
import { Toast } from 'native-base';
import { SetUserDefaultLocation } from '../../../reducers/user';
import moment from 'moment';
import { REACT_APP_GOOGLE_API_GEOS, REACT_APP_GOOGLE_API_ANDROID } from '../../../helpers/envVariables/index';
import { strings } from '../../../translations/index';

const LocationDetails = ({ route }) => {
    const navigation = useNavigation()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state.user);
    const { items } = useSelector(state => state.order);
    const { servingAreas, brand } = useSelector(state => state.settings);
    const [menu] = useState([
        [{ id: "name", title: strings.yourName, type: ["Apartment", 'Villa', 'Hotel'], required: true }],
        [{ id: "email", title: strings.yourEmail, type: ["Apartment", 'Villa', 'Hotel'], required: true }],
        [{ id: "area", title: strings.area, type: ["Apartment", 'Villa','Hotel'], required: true }],
        [{ id: "building", title: strings.buildingName, type: ["Apartment"], required: true }],
        [{ id: 'flat', title: strings.flat, type: ["Apartment"], required: true }],
        [{ id: 'villa', title: strings.villa, type: ['Villa'], required: true }],
        [{ id: 'room', title: strings?.["Room Number"], type: ["Hotel"], required: true }],
        [{ id: "street", title: strings.street, type: ["Apartment", 'Villa'] }],
        [{ id: "additional_directions", title: strings.instruction, type: ["Apartment", 'Villa', 'Hotel'] }],
        [{ id: "label", title: strings.nickName, type: ["Apartment", 'Villa', 'Hotel'], required: true }],
    ])

    const [field, setFields] = useState(menu)

    const [selectedRegon, setSelectedRegon] = useState({})
    const [userAddress, update] = useState({ type: brand?.customLocations ? 'Apartment' : 'Hotel' });
    useEffect(() => {
        let data = route?.params?.data
        let { selectedRegon } = route.params || {}
        if (data) {
            selectedRegon = {
                details: {
                    formatted_address: data?.formatted_address,
                    geometry: data?.geometry,
                    ...data
                },
                latitude: data?.geometry?.location?.lat,
                longitude: data?.geometry?.location?.lng
            }
            let userAddress = JSON.parse(JSON.stringify(data))

            delete userAddress.formatted_address
            delete userAddress.geometry
            update(userAddress)
        }
        userAddress.area = selectedRegon?.address?.area
        userAddress.street = selectedRegon?.address?.street
        userAddress.emirate = selectedRegon?.address?.emirate
        userAddress.place_id = selectedRegon?.place_id
        userAddress.cluster = selectedRegon?.cluster
        update(userAddress)

        setSelectedRegon(selectedRegon)
        let fields = field
        fields = fields.map(r => {
            if (user.displayName && r[0].id === 'name') {
                return false
            }
            if (user.email && r[0].id === 'email') {
                return false
            }
            return r
        }).filter(r => !!r)
        setFields(fields)

    }, [user])

    useEffect(() => {
        let filteredFields = menu?.filter(r => r?.[0].type?.includes(userAddress?.type))
        filteredFields = filteredFields.map(r => {
            if (user.displayName && r[0].id === 'name') {
                return false
            }
            if (user.email && r[0].id === 'email') {
                return false
            }
            return r
        }).filter(r => !!r)

        setFields(filteredFields)
        let unsupportedFields = menu?.filter(r => !filteredFields.includes(r))
        let updatedUserAddress = JSON.parse(JSON.stringify(userAddress))
        unsupportedFields.forEach(r => {
            if (!['name', 'email'].includes(r?.[0].id)) {
                delete updatedUserAddress?.[r?.[0].id]
            }
        })
        update(updatedUserAddress)
    }, [userAddress?.type])

    return (
        <KeyboardAvoidingView keyboardVerticalOffset={100} behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1 }}>
            {loading && <View style={{
                position: 'absolute',
                zIndex: 10000,
                alignSelf: 'center',
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <ActivityIndicator size="large" color={colorTextBlue} />
            </View>}
            <ScrollView showsHorizontalScrollIndicator={false} style={{ minHeight: window.innerHeight }} contentContainerStyle={{ paddingBottom: 200 }} >
                <View style={{ paddingHorizontal: 16, height: 150, paddingVertical: 12, }}>
                    <Image
                        // width={165}
                        alt='location'
                        height={'100%'}
                        style={{
                            // width: 165,
                            height: '100%',
                            borderRadius: 8
                        }}
                        resizeMode="cover"
                        source={{ uri: `https://maps.googleapis.com/maps/api/staticmap?center=${selectedRegon?.latitude},${selectedRegon?.longitude}&zoom=16&size=600x300&markers=color:red%7C${selectedRegon?.latitude},${selectedRegon?.longitude}&sensor=false&key=${Platform.OS === 'web' ? process.env.REACT_APP_GOOGLE_API : Platform.OS === 'ios' ? REACT_APP_GOOGLE_API_GEOS : REACT_APP_GOOGLE_API_ANDROID}` }}
                    />

                </View>
                <View style={{ paddingHorizontal: 16 }}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        {
                            (brand?.customLocations ? ["Apartment", 'Villa'] : ['Hotel']).map(r => <TouchableOpacity
                                onPress={() => update({ ...userAddress, type: r })}
                                key={r} style={{
                                    borderRadius: 8,
                                    paddingHorizontal: 16,
                                    paddingVertical: 8,
                                    borderColor: userAddress.type === r ? '#20A2DF' : '#fff',
                                    borderWidth: 1,
                                    marginRight: 6,
                                    marginTop: 14,
                                    backgroundColor: userAddress.type === r ? '#20A2DF' : '#fff',
                                }}>
                                <Text fontSize={12} style={{ color: userAddress.type === r ? '#fff' : '#20A2DF' }}>{r}</Text>
                            </TouchableOpacity>)
                        }
                    </View>
                    <View style={{ paddingTop: 16 }}>
                        {field.map((rec, index) => {
                            return (
                                <View key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                    {
                                        rec.map(item => {
                                            return (
                                                <View key={item.id} style={{ width: `${rec.length > 1 ? 48 : 100}%`, height: 55, overflow: 'hidden', borderRadius: 8, marginBottom: 16 }}>
                                                    {/* <Text fontSize={12}>{item.title}  <Text style={{ color: 'red' }}>{item?.required ? '*' : ''}</Text></Text> */}
                                                    <TextInput
                                                        onChangeText={(text) => update({ ...userAddress, [item.id]: text })}
                                                        value={userAddress[item.id]}
                                                        theme={{
                                                            dark: true,
                                                            roundness: 8,
                                                            colors: { primary: '#334155', placeholder: 'lightgray', background: "#fff" }
                                                        }}
                                                        style={{ outline: 'none', fontSize: 14, height: 57, overflow: 'hidden', borderRadius: 8, width: `${rec.length > 1 ? 48 : 100}%`, backgroundColor: '#fff', }}
                                                        label={`${item.title} ${!item?.required ? '(Optional)' : ''}`}
                                                        outlineColor={'#334155'}
                                                        // error={item?.required && !userAddress[item.id]}
                                                        underlineColor='transparent'
                                                        underlineColorAndroid='transparent'
                                                    />
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                            )
                        })}
                    </View>
                </View>
            </ScrollView>
            <ActionBtn
                disabled={
                    field.reduce((value, current) => {
                        current.map(r => {
                            if (r.required && !userAddress[r.id]) {
                                return value = true
                            }
                            return value
                        })
                        return value
                    }, false)
                }
                title={strings.saveAddress}
                onPress={async () => {
                    if (auth().currentUser) {
                        setLoading(true)
                        let locations = user?.locations || []
                        let data = route?.params?.data
                        let post = {
                            id: data ? userAddress.id : makeid(),
                            ...userAddress,
                            formatted_address: ConstructAddress({ ...userAddress, additional_directions: false }),
                            geometry: selectedRegon?.details?.geometry,
                        };
                        delete post.email;
                        delete post.name;
                        delete post.phoneNumber;
                        if (data) {
                            locations = locations.filter(r => r.id !== data.id)
                        }
                        locations.push(post)

                        const { error } = await dispatch(UpdateUser({
                            locations,
                            displayName: userAddress?.name || user?.displayName || '',
                            email: userAddress?.email || user?.email || '',
                        }))
                        console.log("error,,,,", error);
                        if (error) {
                            setLoading(false)
                            return Toast.show({
                                placement: 'top',
                                title: 'Error',
                                status: 'error',
                                description: error.message
                            })
                        }

                        navigation.pop(route?.params?.popOne ? 1 : 2)
                        dispatch(updateOrderForLocationChange({ items, cluster: servingAreas?.find(r => r.id === userAddress?.cluster), defaultLocation: { ...post, created: moment().valueOf() } }));

                        // const popAction = StackActions.pop(route?.params?.popOne ? 1 : 2);
                        // navigation.dispatch(popAction)
                        // navigation.pop(route?.params?.popOne ? 1 : 2)

                    }
                }} />
        </KeyboardAvoidingView>

    );


};
export default LocationDetails;