import { Button, Flex, Image, Pressable, View } from 'native-base';
import React from 'react';
import { Wallet } from '../../constants/imgs';
import Text from './TextLabel';
import Counter from './Counter';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateOrderItems } from '../../reducers/order';
import CustomImage from '../shared/customImg'

import { Localize, shadow } from '../../helpers/helpers';
import { SetAlertMessage } from '../../reducers/settings';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { strings } from '../../translations/index';

const CardList = ({ data, }) => {
    const { items } = useSelector(state => state.order);
    const dispatch = useDispatch();
    const { variations, services } = useSelector(state => state.settings);
    const navigation = useNavigation();
    let picture = data?.picture?.[0];

    return (
        <Flex
            flexDirection={'row'}
            minHeight={113}
            justifyContent='space-between'
            background={'#fff'}
            borderRadius={8}
            // padding={4}
            // shadow={shadow({ color: '#00000012', radius: 10, offsetWidth: 0, offsetHeight: 0 })}

            // shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
            alignItems='center'>
            <TouchableOpacity
                onPress={() => {
                    navigation.navigate('service_page', { id: data?.id })
                }}
                style={{
                    flex: 1,
                    maxWidth: '90%',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                <Image alt={data?.id} source={{ uri: picture }} style={{ resizeMode: 'cover', borderTopLeftRadius: 8, borderBottomLeftRadius: 8, flex: 1 }} />
                <View flex={2.5} padding={4}
                >
                    <View >
                        <Text fontFamily='semiBold'
                            fontSize={13}
                            numberOfLines={1}
                        >
                            {Localize(data?.name)}
                        </Text>
                        <Text
                            numberOfLines={2}
                            paddingTop={1}
                            fontSize={11}
                            letterSpacing={0.3}
                        >
                            {Localize(data?.details)}
                        </Text>
                    </View>
                    <View paddingTop={3} flexDirection='row' alignItems={'center'} >
                        <CustomImage
                            svg
                            uri={Wallet}
                            width={15}
                            fill={'#BFBFBF'}
                            height={15}
                        />
                        <Text paddingX={1} fontSize={13} fontFamily='semiBold' >
                            {/* {data?.pricing?.total} {strings?.AED}  */}
                            {((data?.pricing?.total * 1) || 0)?.toFixed(2)} {strings?.['AED']}  <Text fontFamily='medium' style={{ fontSize: 11, color: '#BFBFBF', textDecorationLine: 'line-through' }}>{data?.pricing?.actualPrice && data?.pricing?.actualPrice !== data?.pricing?.total ? data?.pricing?.actualPrice + ' ' + strings?.['AED'] : ''}</Text>
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
            <View pr={4}>
                <Counter
                    count={items?.[data?.id]?.count}
                    minCount={data?.min_count}
                    maxCount={data?.max_count}
                    data={data}
                    updateCount={
                        (count) => {
                            let orderList = JSON.parse(JSON.stringify(items))
                            if (count > 0) {
                                let serviceID = Object.values(orderList)?.[0]?.service_id
                                if (serviceID && serviceID !== data.service_id) {
                                    dispatch(SetAlertMessage({
                                        title: "Caution",
                                        confirmText: 'Confirm',
                                        msg: "By adding this item to cart ,Other Selected services will be removed from cart",
                                        onCancel: () => { dispatch(SetAlertMessage(false)) },
                                        onConfirm: () => {
                                            let updatedList = Object.keys(orderList).reduce((val, currentKey) => {
                                                let currentVal = orderList?.[currentKey]
                                                if (currentVal.service_id === data.service_id) {
                                                    val[currentKey] = currentVal
                                                }
                                                return val
                                            }, {});
                                            dispatch(UpdateOrderItems({ ...updatedList, [data?.id]: { count: data?.min_count || 1, price: data?.pricing, name: data?.name, service_id: data.service_id } }));
                                            dispatch(SetAlertMessage(false));

                                        }
                                    }))
                                } else {
                                    dispatch(UpdateOrderItems({ ...orderList, [data?.id]: { count: count, price: data?.pricing, name: data?.name, service_id: data.service_id } }))
                                }

                            } else {
                                delete orderList?.[data?.id]
                                dispatch(UpdateOrderItems(orderList))
                            }
                        }
                    } />
            </View>
        </Flex>

    );
};

export default CardList;