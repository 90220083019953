import { View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Dimensions, Platform } from 'react-native';

const Modal = (props) => {
    const [update, setUpdate] = useState(window.innerHeight)
    useEffect(() => {
        const resizer = function (e) {
            setUpdate(window.innerHeight)
        }
        if (Platform.OS === 'web') {
            window.document.body.style.position = 'fixed'
            window.onclick = function (e) {
                if (e.target.id === 'background') {
                    props.dismiss()
                }
            }

            window.addEventListener('resize', resizer)
        }
        return () => {
            if (Platform.OS === 'web') {
                window.removeEventListener('resize', resizer)
                window.document.body.style.position = 'unset'
            }
        }
    }, [])
    return (
        <View nativeID='background'
            height={update}
            position={props.fixed ? 'fixed' : 'sticky'}
            w={Dimensions.get('window').width > 900 ? '30%' : '100%'}
            marginLeft={Dimensions.get('window').width > 900 ? '35%' : 0}
            top={0}
            left={0}
            right={0}
            bottom={0} zIndex={props?.zIndex || 99}
            backgroundColor='#00000040'
            style={{...props.style}}
            >
            {props.children}
        </View>
    );
};

export default Modal;