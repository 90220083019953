import { Pressable, Switch, View } from 'native-base';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashedLine } from '../../../../constants/imgs';
import Text from '../../../shared/TextLabel';
import CustomImage from "../../../shared/customImg";
import { calcTotalCost, Localize, shadow } from '../../../../helpers/helpers';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import WalletDetails from './WalletDetails';
import { strings } from '../../../../translations/index';
import { Platform } from 'react-native';
import { analytics } from '../../../../constants/firebase/config';
import { ToggleUserWallet } from '../../../../reducers/user';
import { setOrderDiscount } from '../../../../reducers/order';
import PopoverIcon from '../../../shared/Popover';
import { appsFlyerLogEvent } from '../../../../helpers/index';

const ReceiptComp = () => {
    const activeOrder = useSelector(state => state.order)

    const { wallet } = useSelector(state => state.user)
    const { brandLoyalty } = useSelector(state => state.settings)
    const dispatch = useDispatch()
    const [viewDetails, setViewDetails] = useState(false)
    const [useWallet, setUseWallet] = useState(wallet?.useWallet)
    const [state, setState] = useState(false)
    useEffect(() => {
        let items = activeOrder?.items && Object.keys(activeOrder.items)?.map(key => {
            let item = activeOrder.items[key];
            return ({ name: Localize(item.name), id: key, count: item.count, price: item?.price?.total, description: item?.description || '' })
        })
        setState({ data: items, receipt: dispatch(calcTotalCost(activeOrder?.items, { ...wallet, ...brandLoyalty }, activeOrder?.discount, false)) })
    }, [activeOrder?.discount, wallet])


    useEffect(() => {
        if (state?.receipt?.type === 'wallet') {
            return setUseWallet(true)
        }
        return setUseWallet(false)
    }, [state?.receipt?.type])
    useEffect(() => {
        async function fetchData() {
            let selectedItems = Object.values(activeOrder.items).map(r => r?.name?.en)
            appsFlyerLogEvent('view_cart', {
                af_service: activeOrder?.service?.name?.en,
                af_variations: selectedItems,
                af_value: state?.receipt?.total,
                a_fplatform: Platform.OS
            })
            await analytics().logEvent('view_cart', {
                service: activeOrder?.service?.name?.en,
                variations: selectedItems,
                value: state?.receipt?.total,
                platform: Platform.OS
            })
        }
        fetchData();
    }, [state, activeOrder]);
    return (
        <View  >
            <View backgroundColor={'#fff'} padding={4} borderRadius={10} shadow={shadow({ color: '#00000010', radius: 8, offsetWidth: 1, offsetHeight: 1 })}
            >
                <Text fontFamily='semiBold' paddingBottom={2} fontSize={16}>
                    {strings.Details}
                </Text>
                {
                    state?.data?.map(rec => {
                        return (<View key={rec.id}>
                            <View flexDirection={'row'} justifyContent='space-between'>
                                <Text
                                    w={'70%'}
                                    style={{
                                        fontSize: 12,
                                    }} >
                                    <Text fontFamily='bold' style={{
                                        fontSize: 12,
                                    }}>
                                        X{rec.count}
                                    </Text> {rec.name}
                                </Text>

                                <Text
                                    fontFamily='semiBold'
                                    style={{
                                        fontSize: 12,
                                    }} > {rec.price} {strings?.AED}</Text>
                            </View>
                            <Text w={'70%'} fontWeight={400} style={{ fontSize: 10 }} >
                                {rec.description}
                            </Text>
                        </View>)
                    })
                }
                <View>
                    <View w={'100%'} marginBottom={2} >
                        <CustomImage
                            svg
                            width={'100%'}
                            height={10}
                            uri={DashedLine}
                        />
                    </View>
                    {(wallet?.points > 0 && wallet?.exp > moment().valueOf()) ? <View>
                        <View flexDirection={'row'} paddingBottom={1} alignItems={'center'} justifyContent='space-between'>
                            <View>
                                <Text fontFamily='semiBold' fontSize={14}>
                                    {strings?.['Use wallet credit']}  <Text fontFamily='bold' style={{ color: '#32A2DC' }} fontSize={12}>
                                        {(wallet?.points)?.toFixed(2)} {strings.AED}
                                    </Text>
                                </Text>
                                <Pressable onPress={() => setViewDetails(true)}>
                                    <Text fontSize={12} style={{ textDecorationLine: 'underline' }}>
                                        {strings?.['View Details']}
                                    </Text>
                                </Pressable>
                            </View>
                            <Switch
                                onToggle={(e) => {
                                    let newState = !useWallet
                                    console.log("eeee", newState);
                                    if (newState === true) {
                                        dispatch(setOrderDiscount(false))
                                    }
                                    dispatch(ToggleUserWallet(newState))
                                    setUseWallet(newState)
                                }}
                                size='sm'
                                isChecked={useWallet}
                                // onTrackColor={'#FFB926'}
                                onThumbColor='#ffffff'
                                offThumbColor={'#fff'}
                            />

                        </View>
                        <View w={'100%'} marginBottom={2} >
                            <CustomImage
                                svg
                                width={'100%'}
                                height={10}
                                uri={DashedLine}
                            />
                        </View>
                    </View> : <></>
                    }

                    {
                        state?.receipt?.discount && <View flexDirection={'row'} paddingBottom={1} justifyContent='space-between'>
                            <Text fontFamily='semiBold' fontSize={12} style={{ color: '#32A2DC' }}>
                                {state?.receipt?.type === 'code' ? (strings?.DiscountCode) : strings.Discount}
                                {
                                    state?.receipt?.type === 'code' ?
                                        <Text fontSize={12} fontFamily='semiBold' style={{ color: '#32A2DC' }}> ({state?.receipt?.code})</Text>
                                        :
                                        <View />
                                }
                            </Text>
                            <Text fontFamily='semiBold' fontSize={12} style={{ color: '#32A2DC' }} >
                                -{state?.receipt?.discount} {strings.AED}
                            </Text>
                        </View>
                    }
                    {
                        state?.receipt?.serviceFees?.title ? <View flexDirection={'row'} paddingBottom={1} justifyContent='space-between' alignItems={'center'}>
                            <View flexDirection={'row'} alignItems='center'>
                                <Text fontSize={12}>
                                    {Localize(state?.receipt?.serviceFees?.title)}
                                </Text>
                                <PopoverIcon data={state?.receipt?.serviceFees} />

                            </View>

                            <Text fontFamily='semiBold' fontSize={12} style={{ color: '#32A2DC' }} >
                                {state?.receipt?.serviceFees?.value} {strings.AED}
                            </Text>
                        </View>
                            :
                            <View />
                    }

                    <View flexDirection={'row'} justifyContent='space-between'>
                        <Text
                            fontFamily='semiBold'
                            style={{
                                fontSize: 12,
                            }}>
                            {strings.Total}
                        </Text>
                        <Text
                            fontFamily='semiBold'
                            style={{
                                fontSize: 12,
                            }}>
                            {state?.receipt?.total} {strings.AED}
                        </Text>
                    </View>


                </View>
            </View>
            {viewDetails ? <WalletDetails wallet={brandLoyalty} dismiss={() => setViewDetails(false)} /> : <></>
            }
        </View>
    );
};

export default ReceiptComp;