import { View } from 'native-base';
import React from 'react';
import Text from './TextLabel';

const PowerdBy = () => {
    return (
        <View display='flex' justifyContent='center' paddingBottom={10} paddingX={8}>
            <Text textAlign='center'>
                Powered by <Text fontWeight='600' >Metadoc</Text>

            </Text>
        </View>
    );
};

export default PowerdBy;