import { View } from 'native-base';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { EmptyDot, FilledDot } from '../../constants/imgs';
import CustomImage from "./customImg";
const Rate = ({ onPress, value, disabled = false, size = 30 }) => {
    return (
        <View flexDirection={'row'}>
            {
                [1, 2, 3, 4, 5].map(r => <TouchableOpacity disabled={disabled} style={{ paddingRight: 8 }} key={r} onPress={() => onPress(r)}>
                    <CustomImage
                        svg
                        width={size}
                        height={size}
                        fill={value >= r ? '#20A2DF' : '#E2E9F0'}
                        uri={value >= r ? FilledDot : EmptyDot}
                    />

                </TouchableOpacity>
                )
            }
        </View>
    );
};

export default Rate;